import Session from "@/models/Session";
import User from "@/models/User";
import router from "@/router";
import { Privilege, Role } from "@winnove/vue-wlib/enums";
import axios, { HeadersDefaults } from "axios";
import { JwtPayload, jwtDecode } from "jwt-decode";
import WinnoveHelper from "./WinnoveHelper";

// Axios headers interface
interface CommonHeaderProperties extends HeadersDefaults {
  "x-auth-token": string;
}

// Jwt payload Interface
interface Payload extends JwtPayload {
  p_payload: User;
}

export class AuthHelper {
  // Handle token in axios request and local storage
  static setAuthToken(p_token: string): void {
    axios.defaults.headers = {
      "x-auth-token": p_token,
    } as CommonHeaderProperties;
    const session: Session = WinnoveHelper.getSession();
    session.token = p_token;
    WinnoveHelper.setSession(session);
  }

  static getAuthToken(): string {
    const session: Session = WinnoveHelper.getSession();
    return session.token;
  }

  static refreshToken(): void {
    this.setAuthToken(this.getAuthToken());
  }

  static clearAuthToken(): void {
    axios.defaults.headers = {
      "x-auth-token": "",
    } as CommonHeaderProperties;
    const session: Session = WinnoveHelper.getSession();
    session.token = "";
    WinnoveHelper.setSession(session);
  }

  static isLoggedIn(): boolean {
    const authToken: string = this.getAuthToken();
    return authToken != "" && !this.isTokenExpired(authToken);
  }

  static isAdminLoggedIn(): boolean {
    return this.getLoggedUser().privilege === Privilege.ADMIN;
  }

  static isProdLoggedIn(): boolean {
    return this.getLoggedUser().role === Role.PRODUCTION;
  }

  static isAccountManagerLoggedIn(): boolean {
    return this.getLoggedUser().role === Role.ACCOUNT_MANAGER;
  }

  static getLoggedUser(): User {
    if (this.isLoggedIn()) {
      return Object.assign(
        new User(),
        jwtDecode<Payload>(this.getAuthToken()).p_payload
      );
    }
    return new User();
  }

  static logout(): void {
    this.clearAuthToken();
    localStorage.clear();
    // Reload to empty store and redirect router automatically to /login
    router.go(0);
  }

  static getTokenExpirationDate(p_encodedToken: string): Date | null {
    const token: JwtPayload = jwtDecode<JwtPayload>(p_encodedToken);
    if (!token.exp) {
      return null;
    }

    const date: Date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
  }

  static isTokenExpired(p_token: string): boolean {
    const expirationDate: Date | null = this.getTokenExpirationDate(p_token);
    if (expirationDate == null) {
      return true;
    } else {
      return expirationDate < new Date();
    }
  }
}
declare global {
  interface Window {
    Cypress?: any;
    AuthHelper?: any;
    apiURL?: string;
  }
}

if (window.Cypress) {
  window.AuthHelper = AuthHelper;
  window.apiURL = import.meta.env.VITE_SERVER_URL;
}
