// deviceUtils.ts
import { ref } from "vue";

const isMobile = ref(false); // Commencez par initialiser à false

// Fonction pour mettre à jour la valeur de isMobile en fonction de la taille de l'écran
function updateIsMobile() {
  isMobile.value =
    window.matchMedia("(max-width: 960px)").matches ||
    (window.matchMedia("(max-width: 900px)").matches &&
      window.matchMedia("(orientation: landscape)").matches);
}

// Appelez la fonction initiale pour définir correctement la valeur au démarrage
updateIsMobile();

// Écoutez les changements de taille de fenêtre et mettez à jour isMobile en conséquence
window.addEventListener("resize", updateIsMobile);

export { isMobile };
