import axios from "axios";
import { createPinia } from "pinia";
import { createORM } from "pinia-orm";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

axios.defaults.baseURL = import.meta.env.VITE_SERVER_URL;

const pinia = createPinia();
createORM();
pinia.use(piniaPluginPersistedstate);

export default pinia;
