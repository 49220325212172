<template>
  <w-container
    key="ContainerDashboard"
    fluid
    class="background_dashboard pa-0"
    style="min-height: 100%"
  >
    <div
      class="d-flex h-100"
      v-if="m_loaded"
    >
      <AccueilStatsProd v-if="p_route == ROUTE_PRODUCTIONS" />
      <ProductionTable
        v-if="p_route == ROUTE_PRODUCTIONS_DRAW"
        ref="productionDrawingTable"
        :p_headers="m_drawHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'ProductionDrawingTableOptions'"
        :p_route="ROUTE_PRODUCTIONS_DRAW"
        :p_groupByOrtho="false"
        :p_select="false"
      />

      <ProductionTable
        v-if="p_route == ROUTE_PRODUCTIONS_I3D"
        ref="productionI3DTable"
        :p_headers="m_i3dHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'ProductionI3DTableOptions'"
        :p_route="ROUTE_PRODUCTIONS_I3D"
        :p_groupByOrtho="false"
        :p_select="true"
      />

      <ProductionTable
        v-if="p_route == ROUTE_PRODUCTIONS_TOMAKE"
        ref="productionToMakeTable"
        :p_headers="m_makeHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'ProductionToMakeTableOptions'"
        :p_route="ROUTE_PRODUCTIONS_TOMAKE"
        :p_groupByOrtho="true"
        :p_select="false"
      />

      <ProductionTable
        v-if="p_route == ROUTE_PRODUCTIONS_SHIP"
        ref="productionToShipTable"
        :p_headers="m_basicHeaders"
        :p_privilege="m_privilege"
        :p_cookie="'ProductionToShipTableOptions'"
        :p_route="ROUTE_PRODUCTIONS_SHIP"
        :p_groupByOrtho="true"
        :p_select="true"
      />
    </div>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import {
  ROUTE_PRODUCTIONS,
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_I3D,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
} from "@/router";
import { Privilege, ProcessStep, Role } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, ref } from "vue";
import ProductionTable from "./tables/ProductionTable.vue";

export default defineComponent({
  name: "DashboardProduction",
  components: {
    ProductionTable,
  },
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const m_privilege = ref(Privilege.FREEMIUM);
    const m_role = ref(Role.DEFAULT);

    const m_loaded = ref(false);
    const m_basicHeaders = [
      {
        title: "COMS",
        sortable: false,
      },
      {
        title: "DATE DE LIVRAISON",
        sortable: false,
        value: "project.installationDate",
      },
      {
        title: "ÉTAT",
        sortable: false,
        value: "state",
      },
      {
        title: "REF UNIQUE",
        sortable: false,
        value: "ref",
      },
      {
        title: "ACTIONS",
        sortable: false,
      },
    ];
    const m_drawHeaders = m_basicHeaders.map((header) => ({ ...header }));
    m_drawHeaders.splice(4, 0, {
      title: "MÂCHOIRE",
      sortable: false,
    });

    m_drawHeaders.splice(3, 0, {
      title: "DATE DE CREATION",
      sortable: false,
    });

    const m_i3dHeaders = m_basicHeaders.map((header) => ({ ...header }));
    m_i3dHeaders.splice(4, 0, {
      title: "I3D",
      sortable: false,
    });

    const m_makeHeaders = m_basicHeaders.map((header) => ({ ...header }));

    onMounted(() => {
      m_privilege.value = AuthHelper.getLoggedUser().privilege;
      m_role.value = AuthHelper.getLoggedUser().role;
      if (m_role.value !== Role.PRODUCTION) {
        m_makeHeaders.splice(0, 0, {
          title: "ATTRIBUE A",
          sortable: false,
        });
        m_drawHeaders.splice(0, 0, {
          title: "ATTRIBUE A",
          sortable: false,
        });
        m_i3dHeaders.splice(0, 0, {
          title: "ATTRIBUE A",
          sortable: false,
        });
      }
      m_loaded.value = true;
    });

    return {
      Privilege,
      ProcessStep,
      m_privilege,
      m_loaded,
      m_basicHeaders,
      m_drawHeaders,
      m_i3dHeaders,
      m_makeHeaders,
      ROUTE_PRODUCTIONS,
      ROUTE_PRODUCTIONS_DRAW,
      ROUTE_PRODUCTIONS_I3D,
      ROUTE_PRODUCTIONS_TOMAKE,
      ROUTE_PRODUCTIONS_SHIP,
    };
  },
});
</script>

<style lang="scss" scoped>
.background_dashboard {
  background-color: #f6f9fd;
}
</style>
