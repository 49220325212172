<template>
  <div class="chip-container">
    <w-hover v-slot="{ isHovering, props }">
      <div
        class="chip-wrapper"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <w-chip
          v-bind="props"
          data-cy="order-state-chip"
          variant="flat"
          :class="
            'text-center' +
            (p_miniature ? ' mini-chip' : '') +
            (p_disable ? ' disabled-chip' : '')
          "
          :color="
            ProjectHelper.getOrderChipColor(p_privilege, p_state, p_isSelected)
          "
        >
          <div
            :style="`color: ${ProjectHelper.getOrderChipTextColor(
              p_privilege,
              p_state,
              p_isSelected
            )}`"
          >
            {{ displayedText }}
          </div>
        </w-chip>
        <w-card
          v-if="
            (isHovering && m_stateOrder && m_stateOrder.length > 0) ||
            (isHoveringCard && m_stateOrder && m_stateOrder.length > 0)
          "
          elevation="2"
          @mouseenter="onMouseEnterCard"
          @mouseleave="onMouseLeaveCard"
          color="white"
          width="450px"
          class="hover-card scrollable-card ma-0 pa-0"
          :style="`top: ${cardPosition === 'bottom' ? '30px' : 'auto'}; bottom: ${cardPosition === 'top' ? '30px' : 'auto'};`"
        >
          <w-card-text class="pa-0">
            <v-timeline
              class="left-side"
              size="small"
              align="start"
              side="end"
              line-color="wBlue"
            >
              <v-timeline
                class="left-side mx-0 px-0"
                size="small"
                align="start"
                side="end"
                line-color="wBlue"
              >
                <v-timeline-item
                  v-for="(state, index) in m_stateOrder"
                  :key="index"
                  :icon="getIconStatus(state.status)"
                  icon-color="wBlue"
                  class="disabled"
                  fill-dot
                  :class="{ 'faded-item': index !== m_stateOrder.length - 1 }"
                >
                  <v-row
                    no-gutters
                    class="width-100 d-flex align-items-center justify-space-between"
                  >
                    <v-col class="width-100 flex-grow-1">
                      <p class="text-primary font-weight-bold mb-0">
                        {{
                          ProjectHelper.getOrderStateToDisplay(
                            p_privilege,
                            state.status
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col
                      class="text-right flex-grow-0"
                      style="white-space: nowrap"
                    >
                      <p class="text-caption text-wBlue font-weight-bold mb-0">
                        {{ DateHelper.formatDate(state.timestamp) }}
                        {{ DateHelper.formatDateAndGetHours(state.timestamp) }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-timeline>
          </w-card-text>
        </w-card>
      </div>
    </w-hover>
  </div>
</template>

<script lang="ts">
import DateHelper from "@/helpers/DateHelper";
import ProjectHelper from "@/helpers/ProjectHelper";
import { OrderState, Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, ref } from "vue";

//icon mdi
import StatOrder from "@/models/StatOrder";
import {
  mdiAccountCheck,
  mdiAccountEdit,
  mdiArchive,
  mdiCancel,
  mdiCart,
  mdiCheck,
  mdiClipboardCheck,
  mdiClipboardTextClock,
  mdiClockOutline,
  mdiCommentCheck,
  mdiCommentEdit,
  mdiHandOkay,
  mdiInvoiceTextCheck,
  mdiMagnify,
  mdiMinus,
  mdiPackageCheck,
  mdiPackageVariant,
  mdiPackageVariantClosed,
  mdiPencil,
  mdiPrinter3dNozzle,
  mdiProgressAlert,
  mdiProgressCheck,
  mdiProgressPencil,
  mdiTruckFast,
  mdiWrench,
} from "@mdi/js";
import { useRepo } from "pinia-orm";

export default defineComponent({
  name: "OrderStateChip",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_state: {
      type: Number as () => OrderState,
      required: true,
    },
    p_isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_miniature: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_order: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const isHoveringCard = ref(false);
    // Default card position
    const cardPosition = ref("bottom");

    const m_stateOrder = ref<StatOrder | null>(null);
    const displayedText = computed(() => {
      let text = ProjectHelper.getOrderStateToDisplay(
        props.p_privilege,
        props.p_state
      );

      if (props.p_order !== null) {
        // use statOrder repo to get the history of the order by order id
        const history = useRepo(StatOrder)
          .query()
          .where("orderId", props.p_order.id)
          .withAllRecursive()
          .orderBy("timestamp", "desc")
          .get();

        m_stateOrder.value = history;
      }

      if (props.p_miniature && text.length > 10) {
        text = text.substring(0, 8) + "...";
      }
      return text;
    });

    const onMouseEnter = (event: MouseEvent) => {
      const chipRect = (event.target as HTMLElement).getBoundingClientRect();
      const spaceBelow = window.innerHeight - chipRect.bottom;

      // Calculate the space below the chip
      //if it's less than 350px, display the card above the chip
      cardPosition.value = spaceBelow < 350 ? "top" : "bottom";
      isHoveringCard.value = true;
    };

    const onMouseLeave = () => {
      isHoveringCard.value = false;
    };

    function getIconStatus(state: OrderState) {
      switch (state) {
        case OrderState.TO_MAKE:
          return mdiPrinter3dNozzle;
        case OrderState.SHIPPED:
          return mdiTruckFast;
        case OrderState.TO_BE_CHECKED:
          return mdiMagnify;
        case OrderState.DELIVERED:
          return mdiPackageCheck;
        case OrderState.CANCELED:
          return mdiCancel;
        case OrderState.ARCHIVED:
          return mdiArchive;
        case OrderState.KEY_TO_BE_MADE:
          return mdiHandOkay;
        case OrderState.TO_BE_COMPLETED:
          return mdiProgressPencil;
        case OrderState.TO_BE_PACKAGED:
          return mdiPackageVariantClosed;
        case OrderState.TO_SHIP:
          return mdiPackageVariant;
        case OrderState.MADE_WITH_ROBOBEND:
          return mdiCheck;
        case OrderState.TO_DRAW:
          return mdiPencil;
        case OrderState.ORDERED:
          return mdiInvoiceTextCheck;
        case OrderState.READY_TO_BE_ORDERED:
          return mdiCart;
        case OrderState.BEING_VALIDATED:
          return mdiProgressCheck;
        case OrderState.MAKING:
          return mdiPrinter3dNozzle;
        case OrderState.TO_CONFIRM:
          return mdiClockOutline;
        case OrderState.VALIDATED_BY_DR:
          return mdiAccountCheck;
        case OrderState.REQUEST_UPDATE:
          return mdiCommentEdit;
        case OrderState.UPDATE_DONE:
          return mdiCommentCheck;
        case OrderState.MODIFIED_BY_DR:
          return mdiAccountEdit;
        case OrderState.BLOCKED:
          return mdiProgressAlert;

        default:
          //  default icon for unknown state
          return mdiMinus;
      }
    }

    const onMouseEnterCard = () => {
      isHoveringCard.value = true;
    };

    const onMouseLeaveCard = () => {
      isHoveringCard.value = false;
    };

    return {
      cardPosition,
      getIconStatus,
      DateHelper,
      m_stateOrder,
      history,
      OrderState,
      mdiTruckFast,
      mdiCheck,
      mdiCancel,
      mdiArchive,
      mdiClipboardCheck,
      mdiPackageVariantClosed,
      mdiPencil,
      mdiWrench,
      mdiClockOutline,
      mdiAccountCheck,
      mdiClipboardTextClock,
      mdiMinus,
      displayedText,
      ProjectHelper,
      isHoveringCard,
      onMouseEnter,
      onMouseLeave,
      onMouseEnterCard,
      onMouseLeaveCard,
    };
  },
});
</script>

<style lang="scss">
//reduce row gap
.v-timeline--vertical.v-timeline {
  row-gap: 15px !important;
}

// Set full width for row of body of this component
.v-timeline-item__body {
  width: 100% !important;
}

.scrollable-card {
  max-height: 350px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(var(--v-theme-wGrey));
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(var(--v-theme-wLavander));
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(var(--v-theme-wBlue));
  }
}

.v-chip {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

// Display pointer cursor when chip is hovered
.v-chip:hover {
  cursor: pointer;
}

.mini-chip {
  height: 15px !important;
  font-size: 10px !important;
  margin-left: 6px !important;
  max-width: 80px !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.disabled-chip {
  opacity: 0.3;
}

.chip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

// Display the hover card above the chip

.hover-card {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  font-size: 12px;
  font-weight: normal;
  white-space: nowrap;
  word-wrap: break-word;
  padding: 16px;
}

.chip-wrapper {
  position: relative;
}
</style>
