<template>
  <div
    class="my-2 mx-1 message-container d-flex"
    :class="isMyMessage ? 'flex-row-reverse' : 'flex-row'"
  >
    <avatar-with-tooltip
      :p_user="p_comment.user"
      :p_color="colorMessage"
      p_size="32"
      p_tooltip="Envoyé par"
    />
    <w-chip
      variant="flat"
      :color="colorMessage"
      size="small"
      class="mx-1 my-auto message-chip"
    >
      <conversation-image-link
        v-if="
          p_comment.type === CommentType.REQUEST_UPDATE && p_comment.drawnImage
        "
        :p_comment="p_comment"
      />
      <p>{{ p_comment.content }}</p>
      <span class="time-sub">
        <span
          v-if="CommentType.REQUEST_UPDATE == p_comment.type"
          data-cy="modification-message"
        >
          Modification demandée -
        </span>
        {{ timeMessage }}
      </span>
    </w-chip>
    <w-menu
      v-model="m_menu"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
      class="message-menu"
      min-width="auto"
      v-if="isMyMessage && m_privilege === Privilege.ADMIN"
    >
      <template v-slot:activator="{ props }">
        <w-icon
          :icon="mdiDotsHorizontal"
          v-bind="props"
          class="mx-1 my-auto menu-icon"
          :class="m_menu ? 'show-icon' : ''"
        />
      </template>
      <w-list class="bg-white pa-0">
        <w-list-item @click="$emit('deleteComment', p_comment)">
          Supprimer
        </w-list-item>
      </w-list>
    </w-menu>
  </div>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import DateHelper from "@/helpers/DateHelper";
import Comment from "@/models/Comment";
import { mdiDotsHorizontal } from "@mdi/js";
import { CommentType, Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ConversationMessage",
  props: {
    p_comment: {
      type: Comment,
      required: true,
    },
  },
  setup(props) {
    const m_menu = ref(false);

    const isMyMessage = computed(() => {
      return props.p_comment && props.p_comment.user
        ? props.p_comment.user.id === AuthHelper.getLoggedUser().id
        : false;
    });

    const timeMessage = computed(() => {
      const date = new Date(props.p_comment.creationDate!);
      return (
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      );
    });

    const m_privilege = computed(() => {
      return AuthHelper.getLoggedUser().privilege;
    });

    const colorMessage = computed(() => {
      return isMyMessage.value ? "primary" : "wLightPurple";
    });

    return {
      DateHelper,
      isMyMessage,
      timeMessage,
      colorMessage,
      CommentType,
      mdiDotsHorizontal,
      m_menu,
      m_privilege,
      Privilege,
    };
  },
});
</script>
<style lang="scss">
.message-chip {
  height: auto !important;
  border-radius: 10px !important;
}

.message-chip .v-chip__content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  p {
    max-width: 100%;
    height: auto;
    white-space: pre-wrap;
    font-size: 0.9rem;
    align-self: flex-start;
  }
}

.time-sub {
  font-size: 0.65rem;
  margin-top: auto;
  align-self: flex-end;
}

.message-container > .v-avatar {
  font-size: 14px;
  font-weight: bold;
}

.message-menu .v-overlay__content {
  transform: translateX(-40px);
}

.menu-icon {
  opacity: 0;
  &.show-icon {
    opacity: 1 !important;
  }
}

.message-container:hover .menu-icon {
  opacity: 1;
  transition: opacity 0.5s;
}
</style>
