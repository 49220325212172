<template>
  <BaseDialog
    ref="exitDialog"
    data-cy="exit-dialog"
    max-width="800"
    p_title="Il nous manque quelques informations..."
  >
    <w-card-text
      v-if="p_project.hasToCompletePrescription()"
      class="font-weight-bold pa-2"
    >
      Les fichiers et / ou la prescription n'ont pas été complétés. Toute
      commande non complète 48h après sa date de création sera annulée.
      Voulez-vous vraiment quitter l'éditeur ?
    </w-card-text>
    <w-card-text
      v-else-if="p_project.canOrder() || p_project.hasToDrawWire(p_privilege)"
      class="font-weight-bold pa-2"
    >
      Nous avons bien reçu la prescription, mais vous n'avez pas validé la
      commande. Toute commande non complète 48h après sa date de création sera
      annulée. Voulez-vous vraiment quitter l'éditeur ?
    </w-card-text>
    <w-card-text
      v-else-if="p_project.hasToConfirmWire()"
      class="font-weight-bold pa-2"
    >
      Vous n'avez pas validé le dessin. Voulez-vous vraiment quitter l'éditeur ?
    </w-card-text>

    <!-- TODO : message pour le statut en validation -->
    <w-card-actions class="justify-end">
      <w-btn
        color="red darken-4"
        variant="text"
        @click="$emit('confirm-exit')"
      >
        Quitter
      </w-btn>
      <w-btn
        variant="text"
        @click="exitDialog?.close()"
      >
        Compléter la commande
      </w-btn>
    </w-card-actions>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import Project from "@/models/Project";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ExitDialog",
  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_privilege: {
      type: Number,
      required: true,
    },
  },
  setup(props, ctx) {
    const exitDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function show(): void {
      exitDialog.value?.show();
    }

    function close(): void {
      exitDialog.value?.close();
    }

    return {
      exitDialog,
      show,
      close,
    };
  },
  components: { BaseDialog },
});
</script>
