<template>
  <w-navigation-drawer
    v-model="m_visible"
    class="background_project_info_drawer"
  >
    <div
      v-if="p_project"
      class=""
      style="width: inherit; height: inherit"
    >
      <div
        class="d-flex flex-column"
        style="width: 100%; height: 100%"
      >
        <div class="px-6 py-3">
          <!-- Référence projet -->
          <div
            v-if="p_project.reference"
            class="align-end"
          >
            <span class="ma-0 summary-label"> RÉFÉRENCE PROJET </span>
            <span class="ma-0 ml-2 summary-value">
              {{ p_project.reference }}
            </span>
          </div>
          <!-- Patient -->
          <div class="align-end">
            <span class="ma-0 summary-label"> PATIENT </span>
            <span class="ma-0 ml-2 summary-value">
              {{ p_project.patient.lastName }}
              {{ p_project.patient.firstName }}
            </span>
          </div>
          <!-- Praticien -->
          <div
            v-if="p_privilege === Privilege.ADMIN"
            class="align-end"
          >
            <span class="ma-0 summary-label"> PRATICIEN </span>
            <span class="ma-0 ml-2 summary-value">
              Dr. {{ p_project.patient.user.lastName }}
            </span>
          </div>
        </div>

        <div class="project-info-tabs-container">
          <w-tabs
            id="project-drawer-tabs"
            class="project-info-tabs"
            v-model="m_tab"
            color="transparent"
            align-tabs="center"
            hide-slider
            grow
            height="60"
          >
            <w-tab
              vertical
              :disabled="!hasMaxilla"
              :value="'tab-maxilla'"
              style="height: 100%"
              :draggable="false"
              :ripple="false"
            >
              <img
                class="ma-0"
                :draggable="false"
                :src="
                  m_tab == 'tab-maxilla'
                    ? '/img/icon/active/maxilla.svg'
                    : '/img/icon/default/maxilla.svg'
                "
                alt="Tab maxillaire"
              />
              <p class="text-caption ma-0 pl-2 font-weight-bold">MAXILLAIRE</p>
            </w-tab>

            <w-tab
              vertical
              :disabled="!hasMandible"
              :value="'tab-mandible'"
              style="height: 100%"
              :draggable="false"
              :ripple="false"
            >
              <img
                class="ma-0"
                :draggable="false"
                :src="
                  m_tab == 'tab-mandible'
                    ? '/img/icon/active/mandible.svg'
                    : '/img/icon/default/mandible.svg'
                "
                alt="Tab mandibulaire"
              />
              <p class="text-caption ma-0 pl-2 font-weight-bold">MANDIBULE</p>
            </w-tab>
          </w-tabs>
        </div>

        <w-window
          v-model="m_tab"
          class="flex-grow-1 overflow-y-auto bg-white"
          style="height: 100%"
        >
          <w-window-item
            v-if="hasMaxilla"
            value="tab-maxilla"
            reverse-transition
            style="height: 100%"
          >
            <WireSummary
              :p_privilege="p_privilege"
              :p_order="p_project.orderMaxilla()"
              :p_project="p_project"
              :p_isEditing="false"
              :p_isInThisTab="m_tab == 'tab-maxilla'"
              @order_updated="$emit('refresh')"
              @open-project="load()"
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
          </w-window-item>
          <w-window-item
            v-if="hasMandible"
            value="tab-mandible"
            reverse-transition
            style="height: 100%"
          >
            <WireSummary
              :p_privilege="p_privilege"
              :p_order="p_project.orderMandible()"
              :p_project="p_project"
              :p_isEditing="false"
              :p_isInThisTab="m_tab == 'tab-mandible'"
              @order_updated="$emit('refresh')"
              @open-project="load()"
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
          </w-window-item>
        </w-window>

        <w-divider class="mx-6"></w-divider>

        <div class="px-6 py-4 bg-white">
          <!-- [Dashboard] Ouvrir -->
          <w-btn
            @click="load()"
            @click.middle="load(true)"
            block
            :icon-right="mdiEye"
          >
            Visualiser
          </w-btn>
        </div>
      </div>
    </div>
  </w-navigation-drawer>
</template>

<script lang="ts">
import WireSummary from "@/components/shared/WireSummary.vue";
import Project from "@/models/Project";
import router, { ROUTE_EDITOR } from "@/router";
import { mdiEye } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "ProjectInfoDrawer",
  components: {
    WireSummary,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_project: {
      type: Object as () => Project | null,
      required: false,
    },
  },
  setup(props, context) {
    const m_visible = ref(false);
    const m_tab = ref("");

    const hasMaxilla = computed(() => {
      return !!props.p_project?.wireMaxilla();
    });

    const hasMandible = computed(() => {
      return !!props.p_project?.wireMandible();
    });

    function load(newtab: boolean = false): void {
      if (newtab) {
        window.open(
          router.resolve({
            name: ROUTE_EDITOR,
            params: { reference: props.p_project!.reference },
          }).href,
          "_blank"
        );
      } else
        router.push({
          name: ROUTE_EDITOR,
          params: { reference: props.p_project!.reference },
        });
    }

    function show(): void {
      m_visible.value = true;
    }

    function hide(): void {
      m_visible.value = false;
    }

    watch([hasMaxilla, hasMandible], () => {
      if (m_tab.value == "tab-maxilla" && !hasMaxilla.value) {
        m_tab.value = "tab-mandible";
      } else if (m_tab.value == "tab-mandible" && !hasMandible.value) {
        m_tab.value = "tab-maxilla";
      } else if (hasMaxilla.value) {
        m_tab.value = "tab-maxilla";
      } else if (hasMandible.value) {
        m_tab.value = "tab-mandible";
      }
    });

    function isTabAllowed(tab: string): boolean {
      if (tab == "tab-maxilla") return hasMaxilla.value;
      else if (tab == "tab-mandible") return hasMandible.value;
      return false;
    }

    function getDefaultTab(): string {
      if (hasMaxilla.value) return "tab-maxilla";
      else if (hasMandible.value) return "tab-mandible";
      return "";
    }

    // for some reason, m_tab can be changed twice when selecting another project
    // thus we need to watch it to prevent it from being set to an invalid value
    watch(m_tab, () => {
      if (!isTabAllowed(m_tab.value)) {
        m_tab.value = getDefaultTab();
      }
    });

    return {
      m_visible,
      Privilege,
      m_tab,
      hasMaxilla,
      hasMandible,
      load,
      show,
      hide,
      mdiEye,
    };
  },
});
</script>

<style lang="scss" scoped>
.background_project_info_drawer {
  background-color: #f6f9fd;
  box-shadow: 0px 3px 40px #ddeaf8;
  width: 340px !important;
}
</style>
