import BaseModel from "./BaseModel";

export default class Address extends BaseModel {
  static entity = "addresses";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      userId: this.number(null),
      country: this.string(""),
      address: this.string(""),
      address2: this.string(""),
      city: this.string(""),
      postalCode: this.string(""),
      name: this.string(""),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      //
      //user: this.belongsTo(User, 'userId'),
      //
      //wires: this.hasMany(Wire, 'addresseId')
    };
  }

  declare id: number | null;
  declare userId: number | null;
  declare country: string;
  declare address: string;
  declare address2: string;
  declare city: string;
  declare postalCode: string;
  declare name: string;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  //
  //declare user: User | null;
  //
  //declare wires: Wire[];

  public isValid() {
    return !!this.address && !!this.city && !!this.postalCode && !!this.country;
  }
}
