<template>
  <BaseDialog
    ref="helpDialog"
    :p_title="`Aide et contact`"
    :p_subtitle="'Une question ? Nous sommes là pour vous aider !'"
    p_noPadding
  >
    <w-card-text>
      <div class="row items-center align-center px-3">
        <div class="col">
          <div class="row">
            <w-icon
              color="primary"
              class="mr-2"
              :icon="mdiPhone"
            ></w-icon>
            <a href="tel:+33244848644">02 44 84 86 44</a>
          </div>
          <div class="row mt-2">
            <w-icon
              color="primary"
              class="mr-2"
              :icon="mdiEmail"
            ></w-icon>
            <a href="mailto:contact@winnovemed.com">contact@winnovemed.com</a>
          </div>
        </div>
      </div>
    </w-card-text>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import { ColorsConstants } from "@/shared/constants";
import { mdiEmail, mdiPhone } from "@mdi/js";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "HelpDialog",
  props: {},
  setup(props, context) {
    const helpDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function show(): void {
      helpDialog.value?.show();
    }

    function close(): void {
      helpDialog.value?.close();
    }

    return {
      ColorsConstants,
      helpDialog,
      show,
      close,
      mdiPhone,
      mdiEmail,
    };
  },
  components: { BaseDialog },
});
</script>
