export const ConfigConstants = {
  ADDRESS_SIZE_MAX_KEY: "ADDRESS_SIZE_MAX",
  // Delay for the first available installation date, in open days.
  PRODUCTION_DELAY_MIN_KEY: "PRODUCTION_DELAY_MIN",
  // Delay for the last available installation date, in months (must be < 12).
  PRODUCTION_DELAY_MAX_KEY: "PRODUCTION_DELAY_MAX",
  PRODUCTION_CAPACITY_KEY: "PRODUCTION_CAPACITY",
  CLOSED_DAYS_KEY: "CLOSED_DAYS",
};

export const ColorsConstants = {
  OVERLAY_COLOR: "#7FC7FF",
  OVERLAY_OPACITY: ".56",
};
