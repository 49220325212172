<template>
  <w-text-field
    v-model.trim="m_address"
    :label="p_label"
    :maxlength="m_addressSize"
    :counter="m_addressSize"
  ></w-text-field>
</template>

<script lang="ts">
import Config from "@/models/Config";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "AddressTextField",
  props: {
    p_address: {
      // prop sync
      type: String,
      required: true,
    },
    p_label: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const m_address = computed({
      get: () => props.p_address,
      set: (value: string) => context.emit("update:p_address", value),
    });
    const m_addressSize = Config.getAddressSizeMax();

    return {
      m_address,
      m_addressSize,
    };
  },
});
</script>
