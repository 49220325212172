<template>
  <w-row>
    <w-col>
      <w-select
        data-cy="teeth-select-right"
        v-model="m_rightRange"
        :items="m_rightRangeValues"
        class="p-0 m-0 custom-selector"
        hide-details
        :menu-props="{ 'offset-y': true }"
      ></w-select>
    </w-col>
    <w-col>
      <w-select
        data-cy="teeth-select-left"
        v-model="m_leftRange"
        :items="m_leftRangeValues"
        class="p-0 m-0 custom-selector"
        hide-details
        :menu-props="{ 'offset-y': true }"
      ></w-select>
    </w-col>
  </w-row>
</template>

<script lang="ts">
import {
  Arcade,
  TeethStringMandiLeft,
  TeethStringMandiRight,
  TeethStringMaxiLeft,
  TeethStringMaxiRight,
} from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "TeethSelect",
  props: {
    p_teeth: {
      // prop sync
      type: String,
      required: true,
    },
    p_arcade: {
      type: Number as () => Arcade,
      required: true,
    },
  },
  setup(props, context) {
    const m_wireTeethRange = computed({
      get: () => props.p_teeth,
      set: (p_value: string) => {
        context.emit("update:p_teeth", p_value);
      },
    });
    const m_rightRangeValues = computed(() => {
      if (props.p_arcade === Arcade.MANDIBLE) {
        return TeethStringMandiRight;
      } else {
        return TeethStringMaxiRight;
      }
    });
    const m_leftRangeValues = computed(() => {
      if (props.p_arcade === Arcade.MANDIBLE) {
        return TeethStringMandiLeft;
      } else {
        return TeethStringMaxiLeft;
      }
    });
    const m_rightRange = computed({
      get: () => {
        const teethRanges = m_wireTeethRange.value.split("-");
        return teethRanges[0];
      },
      set: (p_value: string) => {
        m_wireTeethRange.value = p_value + "-" + m_leftRange.value;
      },
    });
    const m_leftRange = computed({
      get: () => {
        const teethRanges = m_wireTeethRange.value.split("-");
        return teethRanges[1];
      },
      set: (p_value: string) => {
        m_wireTeethRange.value = m_rightRange.value + "-" + p_value;
      },
    });

    return {
      m_wireTeethRange,
      m_rightRangeValues,
      m_leftRangeValues,
      m_rightRange,
      m_leftRange,
    };
  },
});
</script>
