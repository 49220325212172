import { AuthHelper } from "@/helpers/AuthHelper";
import DateHelper from "@/helpers/DateHelper";
import {
  Arcade,
  Carrier,
  CommentType,
  OrderState,
  OrderType,
  PrintStatus,
  Privilege,
  ProcessStep,
} from "@winnove/vue-wlib/enums";
import { BooleanCast } from "pinia-orm/casts";
import BaseModel from "./BaseModel";
import Comment from "./Comment";
import ProductionProcess from "./ProductionProcess";
import Project from "./Project";
import Task from "./Task";
import Wire from "./Wire";

export default class Order extends BaseModel {
  static entity = "orders";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      projectId: this.number(null),
      wireId: this.number(null),
      trackingNumber: this.string(""),
      carrier: this.number(Carrier.NONE),
      status: this.number(OrderState.TO_BE_COMPLETED),
      jaw: this.number(Arcade.MANDIBLE),
      orderType: this.number(OrderType.WINNOVE),
      pdf: this.boolean(false),
      keyBuilt: this.boolean(false),
      printed3D: this.boolean(false),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      generateLabel: this.number(PrintStatus.DO_NOT_PRINT),
      //
      project: this.belongsTo(Project, "projectId"),
      //
      wire: this.belongsTo(Wire, "wireId"),
      //
      comments: this.hasMany(Comment, "orderId"),
      validationNeed: this.boolean(false),
      //
      task: this.hasOne(Task, "orderId"),
      //
      productionProcess: this.hasOne(ProductionProcess, "orderId"),
      //
      hasAIGeneratedWires: this.boolean(false),
    };
  }

  static casts() {
    return {
      pdf: BooleanCast,
      keyBuilt: BooleanCast,
      printed3D: BooleanCast,
      validationNeed: BooleanCast,
    };
  }

  declare id: number | null;
  declare projectId: number | null;
  declare wireId: number | null;
  declare trackingNumber: string;
  declare carrier: number;
  declare status: number;
  declare jaw: number;
  declare orderType: number;
  declare pdf: boolean;
  declare keyBuilt: boolean;
  declare printed3D: boolean;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  declare generateLabel: number;
  //
  declare project: Project | null;
  //
  declare wire: Wire | null;
  //
  declare comments: Comment[];
  declare validationNeed: boolean;
  //
  declare task: Task | null;

  declare productionProcess: ProductionProcess | null;

  declare hasAIGeneratedWires: boolean;

  public serialize(): {} {
    const object: Order = Object.assign(new Order(), this);
    object.project = null;
    object.wire = null;
    object.comments = [];
    object.task = null;
    object.productionProcess = null;

    return object;
  }

  public setStatus(p_status: OrderState) {
    this.status = p_status;
  }

  public setValidationNeed(p_validationNeed: boolean) {
    this.validationNeed = p_validationNeed;
  }

  public hasInternalCommentNotRead(): boolean {
    return this._hasCommentNotRead(CommentType.INTERNAL);
  }

  public hasAttestationCommentNotRead(): boolean {
    return this._hasCommentNotRead(CommentType.ATTESTATION);
  }

  public hasClientCommentNotRead(): boolean {
    return this._hasCommentNotRead(CommentType.CLIENT);
  }

  public hasModificationCommentNotRead(): boolean {
    return this._hasCommentNotRead(CommentType.REQUEST_UPDATE);
  }

  public hasClientComments(): boolean {
    return (
      this.hasComments(CommentType.CLIENT) ||
      this.hasComments(CommentType.REQUEST_UPDATE)
    );
  }

  public hasAnyComments(): boolean {
    return (
      this.hasComments(CommentType.CLIENT) ||
      this.hasComments(CommentType.REQUEST_UPDATE) ||
      this.hasComments(CommentType.INTERNAL) ||
      this.hasComments(CommentType.ATTESTATION)
    );
  }

  public hasComments(p_type: CommentType): boolean {
    for (const element of this.comments) {
      if (
        (element.type == p_type && element.content != "") ||
        element.drawnImage
      )
        return true;
    }
    return false;
  }

  private _hasCommentNotRead(p_type: CommentType): boolean {
    // Check for existing comment
    for (const element of this.comments) {
      if (
        element.type == p_type &&
        (element.content != "" || element.drawnImage) &&
        !element.hasBeenReadByCurrentUser()
      )
        return true;
    }
    return false;
  }

  public getLastComment(): Comment {
    return this.comments.reduce(
      (latest, current) =>
        new Date(current.creationDate!).getTime() >
        new Date(latest.creationDate!).getTime()
          ? current
          : latest,
      this.comments[0] || null
    );
  }

  public getInternalComment(): Comment {
    return this._getComment(CommentType.INTERNAL);
  }

  public getAttestationComment(): Comment {
    return this._getComment(CommentType.ATTESTATION);
  }

  private _getComment(p_type: CommentType): Comment {
    // Check for existing comment
    for (let i = this.comments.length - 1; i >= 0; i--) {
      // reverse order to get the last one
      if (this.comments[i].type == p_type) return this.comments[i];
    }
    // if none existing, create a new one
    const ret_com = new Comment();
    ret_com.orderId = this.id;
    ret_com.type = p_type;
    ret_com.userId = AuthHelper.getLoggedUser().id;
    this.comments.push(ret_com);
    return ret_com;
  }

  public getFormatedCreationDate(): string {
    return DateHelper.formatDate(this.creationDate);
  }

  public getReference(): string {
    const ref = this.project!.reference;
    if (this.jaw == Arcade.MAXILLA) {
      return "U" + ref;
    } else {
      return "L" + ref;
    }
  }

  public canSendToProd(): boolean {
    return (
      this.status === OrderState.TO_CONFIRM ||
      this.status === OrderState.VALIDATED_BY_DR ||
      this.status === OrderState.MODIFIED_BY_DR ||
      this.status === OrderState.UPDATE_DONE
    );
  }

  public canRequestValidation(): boolean {
    return this.status === OrderState.TO_CONFIRM;
  }

  public hasBeenUpdated(): boolean {
    return this.status === OrderState.UPDATE_DONE;
  }

  public canValidate(): boolean {
    return this.status === OrderState.BEING_VALIDATED;
  }

  public isRequestingUpdate(): boolean {
    return this.status === OrderState.REQUEST_UPDATE;
  }

  public wasValidatedByDr(): boolean {
    return (
      this.status === OrderState.VALIDATED_BY_DR ||
      this.status === OrderState.MODIFIED_BY_DR
    );
  }

  public canDrawWire(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
        return false;

      case Privilege.PREMIUM:
        switch (this.status) {
          case OrderState.TO_CONFIRM:
          case OrderState.TO_DRAW:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.TO_BE_COMPLETED:
            return true;
          default:
            return false;
        }

      default:
        switch (this.status) {
          case OrderState.TO_CONFIRM:
          case OrderState.ORDERED:
          case OrderState.REQUEST_UPDATE:
          case OrderState.UPDATE_DONE:
          case OrderState.MODIFIED_BY_DR:
            return true;
          default:
            return false;
        }
    }
  }

  public setHasGenerateWireWithAI(p_wiresGenerate: boolean) {
    this.hasAIGeneratedWires = p_wiresGenerate;
  }

  public canGenerateWireWithAI(p_privilege: Privilege): boolean {
    if (
      p_privilege !== Privilege.ADMIN &&
      p_privilege !== Privilege.IN_OFFICE
    ) {
      return false;
    }
    if (this.status !== OrderState.ORDERED) {
      return false;
    }
    if (this.hasAIGeneratedWires) {
      return true;
    }
    return false;
  }

  public canPlaceOrder(p_privilege: Privilege): boolean {
    if (this.status === OrderState.READY_TO_BE_ORDERED) return true;
    return false;
  }

  public canChangePrescription(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.PREMIUM:
        switch (this.status) {
          // case OrderState.TO_BE_COMPLETED:
          case OrderState.TO_DRAW:
          case OrderState.ORDERED:
            return true;
          default:
            return false;
        }
      case Privilege.FREEMIUM:
      case Privilege.IN_OFFICE:
      case Privilege.ADMIN:
      default:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.ORDERED:
            return true;
          default:
            return false;
        }
    }
  }

  public canOrder() {
    return this.status === OrderState.READY_TO_BE_ORDERED;
  }

  public cancellable(p_privilege: Privilege) {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.ORDERED:
            return true;
          default:
            return false;
        }
      case Privilege.IN_OFFICE:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.ORDERED:
          case OrderState.TO_CONFIRM:
          case OrderState.TO_MAKE:
          case OrderState.TO_DRAW:
          case OrderState.MADE_WITH_ROBOBEND:
          case OrderState.MAKING:
            return true;
          default:
            return false;
        }
      default:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.ORDERED:
          case OrderState.TO_CONFIRM:
          case OrderState.TO_MAKE:
          case OrderState.TO_DRAW:
            return true;
          default:
            return false;
        }
    }
  }

  public canDownloadProductionFiles(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
      case Privilege.IN_OFFICE:
      case Privilege.PREMIUM:
        return false;
      default:
        switch (this.status) {
          case OrderState.TO_CONFIRM:
          case OrderState.TO_MAKE:
          case OrderState.TO_BE_CHECKED:
          case OrderState.KEY_TO_BE_MADE:
          case OrderState.TO_SHIP:
          case OrderState.SHIPPED:
          case OrderState.DELIVERED:
          case OrderState.CANCELED:
          case OrderState.ARCHIVED:
            if (this.wire!.nbPoints) return true;
        }
        return false;
    }
  }

  public canDownloadPdf(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.IN_OFFICE:
        return false;
      case Privilege.FREEMIUM:
      case Privilege.PREMIUM:
      default:
        switch (this.status) {
          case OrderState.KEY_TO_BE_MADE:
          case OrderState.TO_SHIP:
          case OrderState.SHIPPED:
          case OrderState.DELIVERED:
          case OrderState.CANCELED:
          case OrderState.ARCHIVED:
            if (this.wire!.nbPoints) return true;
        }
        return false;
    }
  }

  public canDownloadSTLI3D(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
      case Privilege.PREMIUM:
        return false;
      case Privilege.IN_OFFICE:
      default:
        switch (this.status) {
          case OrderState.TO_MAKE:
          case OrderState.TO_CONFIRM:
          case OrderState.TO_BE_CHECKED:
          case OrderState.TO_BE_PACKAGED:
          case OrderState.SHIPPED:
          case OrderState.CANCELED:
          case OrderState.ARCHIVED:
          case OrderState.KEY_TO_BE_MADE:
          case OrderState.DELIVERED:
          case OrderState.TO_SHIP:
          case OrderState.MADE_WITH_ROBOBEND:
          case OrderState.VALIDATED_BY_DR:
          case OrderState.BEING_VALIDATED:
          case OrderState.REQUEST_UPDATE:
          case OrderState.UPDATE_DONE:
          case OrderState.MODIFIED_BY_DR:
          case OrderState.MAKING:
            if (this.wire!.nbPoints) return true;
        }
        return false;
    }
  }

  public hasToDrawWire(p_privilege: Privilege): boolean {
    switch (this.status) {
      case OrderState.TO_DRAW:
        return true;
      case OrderState.TO_BE_COMPLETED:
        if (p_privilege === Privilege.PREMIUM) return true;
        break;
      case OrderState.ORDERED:
        if (p_privilege !== Privilege.PREMIUM) return true;
    }
    return false;
  }

  public canShowTrackingLink(): boolean {
    switch (this.status) {
      case OrderState.SHIPPED:
      case OrderState.DELIVERED:
        return true;
    }
    return false;
  }

  public hasToCompletePrescription(): boolean {
    return this.status === OrderState.TO_BE_COMPLETED;
  }

  public anActionIsNeededFromUser(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.BEING_VALIDATED:
            return true;
          default:
            return false;
        }
      case Privilege.PREMIUM:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.TO_DRAW:
          case OrderState.READY_TO_BE_ORDERED:
          case OrderState.BEING_VALIDATED:
            return true;
          default:
            return false;
        }

      default:
        return false;
    }
  }

  public toBeCompleted(): boolean {
    return this.status === OrderState.TO_BE_COMPLETED;
  }

  public hasNotBeenOrdered(p_privilege: Privilege): boolean {
    switch (p_privilege) {
      case Privilege.FREEMIUM:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.READY_TO_BE_ORDERED:
            return true;
          default:
            return false;
        }
      case Privilege.PREMIUM:
        switch (this.status) {
          case OrderState.TO_BE_COMPLETED:
          case OrderState.TO_DRAW:
          case OrderState.READY_TO_BE_ORDERED:
            return true;
          default:
            return false;
        }

      default:
        if (this.status === OrderState.TO_BE_COMPLETED) return true;
        return false;
    }
  }

  public keyHasToBeMade(): boolean {
    return this.status == OrderState.KEY_TO_BE_MADE;
  }

  public toBeShipped(): boolean {
    return this.status === OrderState.TO_SHIP;
  }

  public isShipped(): boolean {
    return this.status === OrderState.SHIPPED;
  }

  public isDelivered(): boolean {
    return this.status === OrderState.DELIVERED;
  }

  public isCancelled(): boolean {
    return this.status === OrderState.CANCELED;
  }

  public markAsShipped(p_trackingNumber: string): void {
    this.trackingNumber = p_trackingNumber;
    this.carrier = Carrier.UPS;
    this.status = OrderState.SHIPPED;
  }
  public markAsDelivered(): void {
    this.status = OrderState.DELIVERED;
  }
  public markKeyAsBuilt(): void {
    this.keyBuilt = true;
    this.status = OrderState.TO_SHIP;
  }
  public sendToProd(): void {
    this.status = OrderState.TO_MAKE;
  }
  public requestValidation(): void {
    this.status = OrderState.BEING_VALIDATED;
  }
  public submitValidation(): void {
    this.sendToProd();
  }
  public cancel(): void {
    this.status = OrderState.CANCELED;
  }
  public requestModifications(): void {
    this.status = OrderState.REQUEST_UPDATE;
  }

  public belongsToCurrentUser(): boolean {
    if (AuthHelper.getLoggedUser().id == this.project?.patient?.userId)
      return true;
    else return false;
  }

  public clientCanEditComment(): boolean {
    switch (this.status) {
      case OrderState.TO_MAKE:
      case OrderState.TO_CONFIRM:
      case OrderState.TO_BE_COMPLETED:
      case OrderState.TO_DRAW:
      case OrderState.ORDERED:
      case OrderState.READY_TO_BE_ORDERED:
      case OrderState.MADE_WITH_ROBOBEND:
        return true;
      default:
        return false;
    }
  }

  public isSentToRobot(): boolean {
    switch (this.status) {
      case OrderState.MADE_WITH_ROBOBEND:
      case OrderState.TO_MAKE:
      case OrderState.MAKING:
        return true;
      default:
        return false;
    }
  }

  public getAvailableProcessStep(): ProcessStep[] {
    const tasks: ProcessStep[] = [];
    switch (this.status) {
      // check if we need to draw the order
      case OrderState.ORDERED:
      case OrderState.REQUEST_UPDATE:
      case OrderState.TO_CONFIRM:
        tasks.push(ProcessStep.TO_DRAW);
        break;
      // check if we need to make the order
      case OrderState.TO_MAKE:
      case OrderState.MODIFIED_BY_DR:
      case OrderState.UPDATE_DONE:
      case OrderState.VALIDATED_BY_DR:
      case OrderState.MAKING:
      case OrderState.TO_BE_CHECKED:
      case OrderState.KEY_TO_BE_MADE:
        tasks.push(ProcessStep.TO_MAKE);
        break;

      // check if we need to ship the order
      case OrderState.TO_SHIP:
        tasks.push(ProcessStep.TO_SHIP);
        break;

      default:
    }

    // we need to add the 3D printing task if the order is not printed in 3D
    // and if the task TO_MAKE or TO_SHIP is present
    if (
      !this.printed3D &&
      (tasks.includes(ProcessStep.TO_MAKE) ||
        tasks.includes(ProcessStep.TO_SHIP))
    ) {
      tasks.push(ProcessStep.I3D);
    }
    return tasks;
  }
}
