<template>
  <w-tooltip location="bottom">
    <template v-slot:activator="{ props }">
      <w-chip
        data-cy="statsChip"
        variant="flat"
        class="mx-2"
        :color="p_color"
        size="small"
        v-bind="props"
      >
        <w-icon
          class="pr-2"
          :color="p_textColor"
          :icon="p_icon"
          size="x-large"
        >
        </w-icon>
        <w-progress-circular
          v-if="p_loading"
          indeterminate
          :color="p_textColor"
          size="20"
        ></w-progress-circular>
        <span
          v-else
          :style="{ color: p_textColor }"
        >
          <span class="text-h6 font-weight-bold">{{ p_number }}</span
          ><span :style="{ color: p_textColor }"
            >&nbsp;pour le <strong>{{ p_date }}</strong></span
          >
        </span>
      </w-chip>
    </template>
    <span
      >Commandes à livrer avant le
      <w-progress-circular
        v-if="p_loading"
        indeterminate
        size="20"
      ></w-progress-circular>
      <span v-else>{{ p_date }}</span></span
    >
  </w-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProdProjectTableStatsChip",
  components: {},
  props: {
    p_date: {
      type: String,
      required: true,
    },
    p_number: {
      type: Number,
      required: false,
    },
    p_color: {
      type: String,
      required: true,
    },
    p_icon: {
      type: String,
      required: true,
    },
    p_textColor: {
      type: String,
      required: false,
      default: "black",
    },
    p_loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    return {};
  },
});
</script>
