<template>
  <div class="flex-column">
    <w-snackbar
      v-for="(snackbar, index) in displayedSnackbars"
      :key="snackbar.message"
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      variant="elevated"
      data-cy="snackbar"
      multi-line
      :style="`bottom: ${index * 76 + 20}px;`"
    >
      <strong data-cy="error-snackbar-msg">
        {{ snackbar.message }}
      </strong>

      <template v-slot:actions>
        <w-btn
          variant="text"
          @click="snackbar.visible = false"
          data-cy="error-snackbar-close-btn"
          :color="null"
        >
          <strong>FERMER</strong>
        </w-btn>
      </template>
    </w-snackbar>
  </div>
</template>

<script lang="ts">
import { Constants } from "@/Constants";
import Logger from "@/shared/logger";
import { computed, defineComponent, onMounted, reactive } from "vue";

interface SnackbarElement {
  message: string;
  color: string;
  timeout: number;
  expirationTimestamp: number;
  visible: boolean;
}

export default defineComponent({
  name: "Snackbar",
  setup() {
    const snackbars = reactive<SnackbarElement[]>([]);
    const displayedSnackbars = computed(() =>
      snackbars
        .filter((snackbar) => snackbar.visible)
        .filter((snackbar) => snackbar.expirationTimestamp > Date.now())
        .slice(0, 3)
    );
    const logger = Logger.getInstance();

    function _addLog(p_message: string, p_timeout: number, p_color: string) {
      const expirationTimestamp = Date.now() + p_timeout;
      let newSnackbar: SnackbarElement = {
        message: p_message,
        color: p_color,
        timeout: p_timeout,
        expirationTimestamp: expirationTimestamp,
        visible: true,
      };

      snackbars.forEach((snackbar) => {
        // remove the snackbars that are not visible or expired or have the same message
        if (
          !snackbar.visible ||
          snackbar.expirationTimestamp < Date.now() ||
          snackbar.message === p_message
        ) {
          snackbars.splice(snackbars.indexOf(snackbar), 1);
        }
      });

      // add the new snackbar
      snackbars.push(newSnackbar);
    }

    function error(p_message: string, p_timeout = 0) {
      _addLog(
        p_message,
        p_timeout || Constants.SNACKBAR_ERROR_DURATION,
        "error"
      );
    }

    function warning(p_message: string, p_timeout = 0) {
      _addLog(
        p_message,
        p_timeout || Constants.SNACKBAR_WARNING_DURATION,
        "warning"
      );
    }

    function info(p_message: string, p_timeout = 0) {
      _addLog(p_message, p_timeout || Constants.SNACKBAR_INFO_DURATION, "info");
    }

    function success(p_message: string, p_timeout = 0) {
      _addLog(
        p_message,
        p_timeout || Constants.SNACKBAR_SUCCESS_DURATION,
        "success"
      );
    }

    onMounted(() => {
      logger.subscribe("error", error);
      logger.subscribe("warning", warning);
      logger.subscribe("info", info);
      logger.subscribe("success", success);
    });

    return {
      displayedSnackbars,
      error,
      warning,
      info,
      success,
    };
  },
});
</script>
