<template>
  <EditableDataTableMenu
    :p_data="'Dr. ' + m_patient.user.lastName"
    @cancel="cancel"
    @save="save"
    :p_disabled="p_disabled"
  >
    <w-autocomplete
      v-model="m_patient.userId"
      :items="p_userList"
      :item-title="(item) => item.lastName + ' ' + item.firstName"
      :item-value="(item) => item.id"
      :rules="[(v) => (v && v > -1) || 'Valeur requise']"
      hide-details
      no-data-text="Aucun résultat"
      label="Sélectionner le praticien"
      autofocus
    ></w-autocomplete>
  </EditableDataTableMenu>
</template>

<script lang="ts">
import Patient from "@/models/Patient";
import Logger from "@/shared/logger";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "EditableDoctor",
  inheritAttrs: false,
  components: {},
  props: {
    p_patient: {
      type: Object as () => Patient,
      required: true,
    },
    p_userList: {
      type: Array as () => any[],
      required: true,
    },
    p_hover: {
      type: Boolean,
      default: false,
    },
    notempty: {
      type: Boolean,
      default: false,
    },
    p_disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_patient = computed({
      get: () => props.p_patient,
      set: (p_patient: Patient) => {
        context.emit("update:p_patient", p_patient);
      },
    });

    const m_dataSave = ref(new Patient());

    onMounted(() => {
      m_dataSave.value = m_patient.value;
    });

    function cancel(): void {
      m_patient.value = m_dataSave.value as Patient;
    }

    function save(): void {
      if (!m_patient.value.userId) {
        Logger.getInstance().error("Entrée non valide! ");
        cancel();
        return;
      }

      m_dataSave.value = m_patient.value;
      context.emit("save");
    }

    return {
      m_patient,
      m_dataSave,
      open,
      cancel,
      save,
    };
  },
});
</script>
