import Wire from "@/models/Wire";
import WireHistory from "@/models/WireHistory";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";
import DateHelper from "./DateHelper";

export default class WireHelper {
  // update wire
  static async updateWire(
    p_wire: Wire,
    p_algo: number,
    p_wire_history?: WireHistory
  ): Promise<void> {
    await axios
      .put("wires/" + p_wire.id, {
        wire: p_wire.serialize(),
        algo: p_algo,
        wire_history: p_wire_history?.serialize(),
      })
      .then(async (p_response: AxiosResponse) => {
        useRepo(Wire).where("id", p_wire.id).update(p_wire);

        if (p_response.data.wire_history) {
          p_response.data.wire_history.timestamp = DateHelper.formatDateInUTC(
            new Date(p_response.data.wire_history.timestamp)
          ).toISOString();
          p_response.data.wire_history.algoVersion = p_algo;

          useRepo(WireHistory).save(p_response.data.wire_history);
        }
      });
  }

  static buildFakeBasePoints(p_wirePoints: string): string {
    const parsedPoints = JSON.parse(p_wirePoints);
    const fakeBasePoints = [];
    for (const element of parsedPoints) {
      fakeBasePoints.push({
        p: { x: element.x, y: element.y, z: element.z },
        n: { x: 0, y: 0, z: 0 },
      });
    }
    return JSON.stringify(fakeBasePoints);
  }
}
