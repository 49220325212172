<template>
  <editable-data-table-menu
    :p_data="p_data ? p_data.substring(0, 10) + '...' : '-'"
    @cancel="cancel"
    @save="save"
  >
    <w-textarea
      v-model="m_data"
      :rules="notempty ? [(v) => v && !!v.trim()] : []"
      autofocus
      auto-grow
    ></w-textarea>
  </editable-data-table-menu>
</template>

<script lang="ts">
import Logger from "@/shared/logger";
import { computed, defineComponent, ref } from "vue";
import EditableDataTableMenu from "./EditableDataTableMenu.vue";

export default defineComponent({
  name: "EditableDataTableTextArea",
  components: { EditableDataTableMenu },
  inheritAttrs: false,
  props: {
    p_data: {
      // prop sync
      type: String,
      default: "",
    },
    notempty: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_data = computed({
      get: () => props.p_data,
      set: (value) => context.emit("update:p_data", value),
    });

    const m_dataSave = ref(props.p_data);

    function cancel(): void {
      m_data.value = m_dataSave.value;
    }

    function save(): void {
      // check if the value is not empty
      if (props.notempty && m_data.value.trim() === "") {
        m_data.value = m_dataSave.value;
        Logger.getInstance().error("Ce champ ne peut pas être vide!");
        return;
      }

      m_dataSave.value = m_data.value;
      context.emit("save");
    }

    return {
      m_data,
      m_dataSave,
      save,
      cancel,
    };
  },
});
</script>
