<template>
  <w-badge
    :color="badgeColor"
    :bordered="badgeColor !== 'transparent'"
    overlap
    dot
  >
    <w-icon
      v-bind="activatorProps"
      :disabled="!hasComments"
      :icon="mdiMessageTextOutline"
    />
  </w-badge>
</template>

<script lang="ts">
import Order from "@/models/Order";
import { mdiMessageTextOutline } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "CommentBadgeIcon",
  props: {
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    activatorProps: {
      type: Object,
      required: false,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const hasComments = computed(() => {
      if (props.p_privilege === Privilege.ADMIN) {
        return props.p_order.hasAnyComments();
      }
      return props.p_order.hasClientComments();
    });

    const badgeColor = computed(() => {
      let color = "transparent";
      switch (true) {
        case props.p_order.hasModificationCommentNotRead():
          color = "orange";
          break;
        case props.p_order.hasAttestationCommentNotRead() &&
          props.p_privilege === Privilege.ADMIN:
          color = "red";
          break;
        case props.p_order.hasInternalCommentNotRead() &&
          props.p_privilege === Privilege.ADMIN:
          color = "green";
          break;
        case props.p_order.hasClientCommentNotRead():
          color = "blue";
          break;
      }
      return color;
    });

    return { mdiMessageTextOutline, badgeColor, hasComments };
  },
});
</script>
