<template>
  <w-container
    key="ContainerDashboard"
    fluid
    class="background_dashboard pa-0"
    style="min-height: 100%"
  >
    <div
      class="d-flex h-100"
      v-if="m_loaded"
    >
      <ProjectTable
        ref="projectTable"
        :p_privilege="m_privilege"
        :p_projectSelectedId="m_projectSelected ? m_projectSelected.id : -1"
        @select-project="selectProject"
        @unselect-project="unselectProject"
      />
      <ProjectInfoDrawer
        ref="projectInfoDrawer"
        :p_privilege="m_privilege"
        :p_project="m_projectSelectedDrawer"
        @refresh="refreshProjectTable()"
        @requestUpdateFromPinia="$refs.projectTable.requestUpdateFromPinia()"
      />
    </div>
  </w-container>
</template>

<script lang="ts">
import ProjectInfoDrawer from "@/components/dashboard/ProjectInfoDrawer.vue";
import ProjectTable from "@/components/dashboard/tables/ProjectTable.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import Project from "@/models/Project";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    ProjectTable,
    ProjectInfoDrawer,
  },
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const projectInfoDrawer = ref<InstanceType<
      typeof ProjectInfoDrawer
    > | null>(null);
    const projectTable = ref<InstanceType<typeof ProjectTable> | null>(null);

    const m_privilege = ref(Privilege.FREEMIUM);
    const m_projectSelected = ref<Project | null>(null);
    const m_projectSelectedDrawer = ref<Project | null>(null);

    const m_loaded = ref(false);

    onMounted(() => {
      m_privilege.value = AuthHelper.getLoggedUser().privilege;

      m_loaded.value = true;
    });

    function unselectProject(): void {
      m_projectSelected.value = null;
      projectInfoDrawer.value?.hide();
    }

    function selectProject(p_project: Project): void {
      if (p_project === m_projectSelected.value) {
        // If it's already selected
        unselectProject();
      } else {
        m_projectSelected.value = p_project;
        m_projectSelectedDrawer.value = p_project;
        projectInfoDrawer.value?.show();
      }
    }

    function refreshProjectTable(): void {
      projectTable.value?.refreshProjectList();
    }

    watch(() => props.p_route, _onRouteChanged);

    function _onRouteChanged(): void {
      projectInfoDrawer.value?.hide();
      m_projectSelected.value = null;
    }

    return {
      Privilege,
      m_privilege,
      m_projectSelected,
      m_projectSelectedDrawer,
      m_loaded,
      selectProject,
      unselectProject,
      refreshProjectTable,
      projectInfoDrawer,
      projectTable,
    };
  },
});
</script>
