import Billing from "@/models/Billing";
import Order from "@/models/Order";
import Wire from "@/models/Wire";
import { Arcade, OrderState, ProcessStep } from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";
import { Constants } from "../Constants";

export default class OrderHelper {
  //unblock order
  static async unblockOrder(p_order: Order): Promise<void> {
    await axios.put("orders/" + p_order.id + "/unblock").then(() => {
      const orderRepo = useRepo(Order);
      orderRepo.where("id", p_order.id).update(p_order);
    });
  }

  // udpdate order
  static async updateOrder(p_order: Order): Promise<void> {
    await axios
      .put("orders/" + p_order.id, { order: p_order.serialize() })
      .then(() => {
        const orderRepo = useRepo(Order);
        orderRepo.where("id", p_order.id).update(p_order); // not sur if this works as expected, was previously Order.update({ where: p_order.$id!, data: p_order });
      });
  }

  static async createOrder(p_projectID: number, p_jaw: Arcade) {
    const _order = new Order();
    const _wire = new Wire();
    const _billing = new Billing();
    _order.projectId = p_projectID;
    _order.jaw = p_jaw;
    await axios
      .post("orders", { order: _order.serialize() })
      .then((p_response: AxiosResponse) => {
        const order = p_response.data.order;
        const wire = p_response.data.wire;
        const billing = p_response.data.billing;

        Object.assign(_order, order);
        Object.assign(_wire, wire);
        Object.assign(_billing, billing);

        const orderRepo = useRepo(Order);
        const wireRepo = useRepo(Wire);
        const billingRepo = useRepo(Billing);

        orderRepo.save(_order);
        wireRepo.save(_wire);
        billingRepo.save(_billing);
      });
  }

  static trackOrder(p_trackingNumber: string): void {
    window.open(
      Constants.TRACKING_API_URL.replace(
        "${p_trackingNumber}",
        p_trackingNumber
      ),
      "_blank"
    );
  }

  static async getProductionCharge(): Promise<number[]> {
    const productionCharge: Array<number> = new Array<number>(53).fill(0);

    await axios
      .get("projects/production_charge")
      .then((p_response: AxiosResponse) => {
        p_response.data.charge.forEach((p_element: any) => {
          productionCharge[p_element.week - 1] = p_element.charge;
        });
      });

    return productionCharge;
  }

  static matchProcessStep(
    p_order: Order,
    p_productionStep: ProcessStep
  ): boolean {
    const orderI3D: OrderState[] = [
      OrderState.TO_MAKE,
      OrderState.TO_BE_CHECKED,
      OrderState.KEY_TO_BE_MADE,
      OrderState.MAKING,
    ];
    const orderDraw: OrderState[] = [
      OrderState.TO_CONFIRM,
      OrderState.ORDERED,
      OrderState.VALIDATED_BY_DR,
      OrderState.REQUEST_UPDATE,
      OrderState.UPDATE_DONE,
    ];
    const orderMake: OrderState[] = [OrderState.TO_MAKE, OrderState.MAKING];
    const orderShip: OrderState[] = [
      OrderState.TO_SHIP,
      OrderState.TO_BE_PACKAGED,
    ];

    if (
      (p_productionStep === ProcessStep.I3D &&
        orderI3D.includes(p_order.status)) ||
      (p_productionStep === ProcessStep.TO_DRAW &&
        orderDraw.includes(p_order.status)) ||
      (p_productionStep === ProcessStep.TO_MAKE &&
        orderMake.includes(p_order.status)) ||
      (p_productionStep === ProcessStep.TO_SHIP &&
        orderShip.includes(p_order.status))
    ) {
      return true;
    }

    return false;
  }
}
