<template>
  <div
    class="px-6 py-2 overflow-y-auto summary-container d-flex flex-column"
    :style="{ height: p_fixedHeight ? '600px' : '100%' }"
  >
    <!-- Order completion alert -->
    <w-row
      v-if="p_order.hasNotBeenOrdered(p_privilege)"
      class="mt-1 mb-4"
    >
      <w-col>
        <w-alert
          type="info"
          variant="tonal"
        >
          <w-col class="grow pa-0">
            Veuillez Compléter la commande.
            <span
              v-if="
                p_privilege == Privilege.FREEMIUM ||
                p_privilege == Privilege.PREMIUM
              "
              ><br /><br />
              Toute commande non complète 48h après sa date de création sera
              <strong>automatiquement annulée</strong>.
            </span>
          </w-col>
          <w-col
            v-if="!p_isEditing"
            class="shrink pa-0 mt-2"
          >
            <w-btn
              @click="$emit('open-project')"
              block
              :append-icon="mdiArrowRight"
              >Compléter</w-btn
            >
          </w-col>
        </w-alert>
      </w-col>
    </w-row>

    <!-- Order detail -->
    <w-container
      v-if="!p_order.toBeCompleted()"
      class="pa-0 h-100 d-flex flex-column container-wire-summary flex-grow-1"
    >
      <w-row>
        <w-col>
          <w-expansion-panels
            v-model="m_panel"
            v-if="m_wireValidationStatus === WireValitationAction.NONE"
            id="order-detail-panels"
          >
            <w-expansion-panel class="bg-white">
              <w-expansion-panel-title>
                <!-- Matériau -->
                <WireSummaryOrderDetail
                  :p_orderCancelled="p_order.isCancelled()"
                  :p_checked="true"
                  p_label="Matériau"
                  :p_value="
                    MaterialString[p_order.wire.material] +
                    ' ' +
                    DimensionString[p_order.wire.dimension]
                  "
                />

                <!-- Dents -->
                <WireSummaryOrderDetail
                  :p_orderCancelled="p_order.isCancelled()"
                  :p_checked="true"
                  p_label="Dents"
                  :p_value="p_order.wire.teethRange"
                />
              </w-expansion-panel-title>
              <w-expansion-panel-text
                class="d-none d-flex flex-column"
                v-if="p_privilege !== Privilege.IN_OFFICE"
              >
                <WireSummaryOrderDetail
                  v-if="
                    m_wireValidationStatus === WireValitationAction.NONE &&
                    p_privilege !== Privilege.IN_OFFICE
                  "
                  :p_orderCancelled="p_order.isCancelled()"
                  :p_checked="!!p_order.wire.keyNeeded"
                  p_label="Clé de positionnement"
                  :p_value="p_order.wire.keyNeeded ? 'Oui' : 'Non'"
                />

                <!-- Demande de validation -->
                <WireSummaryOrderDetail
                  v-if="
                    m_wireValidationStatus === WireValitationAction.NONE &&
                    p_privilege !== Privilege.IN_OFFICE
                  "
                  :p_orderCancelled="p_order.isCancelled()"
                  :p_checked="p_order.validationNeed"
                  p_label="Demande de validation du dessin"
                  :p_value="p_order.validationNeed ? 'Oui' : 'Non'"
                />
              </w-expansion-panel-text>
            </w-expansion-panel>
          </w-expansion-panels>
        </w-col>
      </w-row>

      <!-- Send to prod -->
      <w-row
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_order.canSendToProd() &&
          (!p_order.wasValidatedByDr() || p_isEditing)
        "
      >
        <w-col>
          <w-btn
            class="mt-4"
            v-if="p_privilege == Privilege.ADMIN"
            @click="sendToProd()"
            :disabled="
              p_order.validationNeed &&
              !p_order.hasBeenUpdated() &&
              !p_order.wasValidatedByDr()
            "
            block
          >
            Envoyer en production.
          </w-btn>
          <w-btn
            v-if="p_privilege == Privilege.IN_OFFICE"
            @click="sendToProd()"
            block
            class="mt-4"
          >
            Envoyer au robot.
          </w-btn>
        </w-col>
      </w-row>

      <!-- Request validation -->
      <w-row
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_privilege == Privilege.ADMIN &&
          p_order.canRequestValidation() &&
          !p_order.hasBeenUpdated()
        "
      >
        <w-col>
          <w-btn
            class="mt-4"
            @click="requestValidation()"
            block
          >
            Demande de validation.
          </w-btn>
        </w-col>
      </w-row>

      <!-- Mark as made -->
      <w-row
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_privilege == Privilege.ADMIN &&
          p_order.keyHasToBeMade()
        "
      >
        <w-col>
          <w-btn
            class="mt-4"
            @click="setKeyAsMade()"
            block
          >
            Marquer la clé fabriquée.
          </w-btn>
        </w-col>
      </w-row>

      <!-- Mark as shipped -->
      <w-row
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_privilege == Privilege.ADMIN &&
          p_order.toBeShipped()
        "
      >
        <w-col>
          <w-text-field
            class="mt-4"
            v-model="m_trackingNumber"
            label="Numéro de suivi"
            :rules="[(v) => (v && !!v.trim()) || 'Numéro de suivi requis']"
            required
          ></w-text-field>
          <w-btn
            class="mt-4"
            @click="markAsShipped(m_trackingNumber)"
            block
            :disabled="!m_trackingNumber"
          >
            Valider le numéro de suivi.
          </w-btn>
        </w-col>
      </w-row>

      <!-- Mark as delivered -->
      <w-row
        v-if="
          m_wireValidationStatus === WireValitationAction.NONE &&
          p_privilege == Privilege.ADMIN &&
          p_order.isShipped()
        "
      >
        <w-col>
          <w-btn
            class="mt-4"
            @click="markAsDelivered()"
            block
          >
            La commande a été livrée.
          </w-btn>
        </w-col>
      </w-row>

      <w-row v-if="m_wireValidationStatus === WireValitationAction.NONE">
        <w-col>
          <!-- Download -->
          <!-- close-on-click -->
          <w-menu
            class="mt-4"
            offset-y
            transition="slide-y-transition"
            color="primary"
            v-model="m_downloadMenu"
            v-if="p_privilege !== Privilege.FREEMIUM"
          >
            <!-- Dropdown button -->
            <template v-slot:activator="{ props }">
              <w-btn
                variant="tonal"
                class="d-none d-md-flex justify-start mt-4"
                block
                v-bind="props"
                :iconRight="mdiChevronDown"
              >
                <span>Télécharger...</span>
              </w-btn>
            </template>

            <!-- Download options -->
            <w-list class="d-flex flex-column pa-0 bg-white">
              <STLDownloadButton
                v-if="p_project.orderMaxilla()?.canDownloadSTLI3D(p_privilege)"
                :p_arch="p_project.archMaxilla()"
                :p_privilege="p_privilege"
                :p_for3DPrinting="true"
              />
              <STLDownloadButton
                v-if="p_project.orderMandible()?.canDownloadSTLI3D(p_privilege)"
                :p_arch="p_project.archMandible()"
                :p_privilege="p_privilege"
                :p_for3DPrinting="true"
              />
              <STLDownloadButton
                v-if="hasMaxi()"
                :p_arch="p_project.archMaxilla()"
                :p_privilege="p_privilege"
                :p_for3DPrinting="false"
              />
              <STLDownloadButton
                v-if="hasMandi()"
                :p_arch="p_project.archMandible()"
                :p_privilege="p_privilege"
                :p_for3DPrinting="false"
              />
              <PDFDownloadButton
                :p_wire="p_order.wire"
                :p_privilege="p_privilege"
                @download="$refs.pdfDialog.download()"
              />
              <GCodeDownloadButton
                v-if="p_privilege === Privilege.ADMIN"
                :p_privilege="p_privilege"
                :p_wire="p_order.wire"
              />
            </w-list>
          </w-menu>

          <PDFDialog
            ref="pdfDialog"
            :p_wire="p_order.wire"
            :p_privilege="p_privilege"
            @refresh-wire="$emit('requestUpdateFromPinia')"
          ></PDFDialog>

          <div
            class="mt-4"
            v-if="p_privilege == Privilege.ADMIN"
          >
            <!-- User general comment about design preferences -->
            <w-alert
              v-if="p_project.patient.user.preferences"
              type="info"
              color="wBlue"
              class="mt-2 mb-0 text-caption"
              density="compact"
              variant="tonal"
            >
              {{ p_project.patient.user.preferences }}
            </w-alert>
            <CommentArea
              v-model:p_comment="m_internalComment"
              p_label="Commentaires Interne"
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
            <CommentArea
              v-model:p_comment="m_attestationComment"
              p_label="Commentaire Attestation"
              @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            />
          </div>
        </w-col>
      </w-row>

      <w-row
        class="flex-grow-1 d-flex flex-column"
        style="min-height: 280px"
        v-if="m_wireValidationStatus === WireValitationAction.NONE"
      >
        <w-col class="h-100">
          <!-- Conversation -->
          <Conversation
            :p_order="p_order"
            @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
            class="pt-2"
          />
        </w-col>
      </w-row>

      <!-- Actions for this wire -->
      <w-row
        v-if="
          (p_isEditing &&
            p_privilege === Privilege.IN_OFFICE &&
            p_order.cancellable(p_privilege)) ||
          (m_wireValidationStatus === WireValitationAction.NONE &&
            p_isEditing &&
            p_order.belongsToCurrentUser() &&
            p_order.canValidate())
        "
      >
        <w-col>
          <w-menu
            class="mt-4"
            v-model="m_doctorMenu"
            offset-y
            close-on-click
            transition="slide-y-transition"
          >
            <!-- Dropdown button -->
            <template v-slot:activator="{ props }">
              <w-btn
                data-cy="doctor-decision-button"
                block
                depressed
                v-bind="props"
                :variant="
                  p_isEditing &&
                  p_privilege === Privilege.IN_OFFICE &&
                  p_order.cancellable(p_privilege)
                    ? 'outlined'
                    : 'elevated'
                "
                class="justify-start"
                :iconRight="mdiChevronDown"
              >
                Décision du praticien pour ce fil...
              </w-btn>
            </template>

            <!-- Wire actions options -->
            <w-card class="d-flex flex-column pa-0 bg-white">
              <!-- Submit validation -->
              <w-btn
                v-if="
                  p_isEditing &&
                  p_order.belongsToCurrentUser() &&
                  p_order.canValidate()
                "
                data-cy="submit-immediate-validation-button"
                @click="submitValidation()"
                style="justify-content: start"
                variant="text"
                :prepend-icon="mdiCheckCircle"
                block
              >
                Valider le design du fil.
              </w-btn>

              <!-- Request update -->
              <w-btn
                v-if="
                  p_isEditing &&
                  p_order.belongsToCurrentUser() &&
                  p_order.canValidate()
                "
                data-cy="request-update-button"
                style="width: 100%; justify-content: start"
                variant="text"
                :prepend-icon="mdiCommentEdit"
                @click="
                  m_wireValidationStatus =
                    WireValitationAction.REQUEST_UPDATE_ASK_IMAGE
                "
                block
              >
                Demander une modification.
              </w-btn>

              <!-- Edit wire -->
              <w-btn
                v-if="
                  p_privilege === Privilege.PREMIUM &&
                  p_isEditing &&
                  p_order.belongsToCurrentUser() &&
                  p_order.canValidate()
                "
                @click="validationEditWire()"
                data-cy="edit-wire-button"
                class="d-none d-md-flex"
                style="justify-content: start"
                variant="text"
                :prepend-icon="mdiCircleEditOutline"
                block
              >
                Modifier le fil.
              </w-btn>

              <!-- Edit wire (IN OFFICE after design was sent to the robot) -->
              <w-btn
                v-if="
                  p_privilege === Privilege.IN_OFFICE &&
                  p_isEditing &&
                  p_order.belongsToCurrentUser() &&
                  p_order.isSentToRobot()
                "
                @click="setOrderStatus(OrderState.TO_CONFIRM)"
                data-cy="edit-wire-button"
                class="d-none d-md-flex"
                style="justify-content: start"
                variant="text"
                :prepend-icon="mdiCircleEditOutline"
                block
              >
                Modifier le fil.
              </w-btn>

              <!-- Cancel the order -->
              <!-- Cancel button -->
              <w-btn
                data-cy="cancel-wire-button"
                color="error"
                style="justify-content: start"
                variant="text"
                :prepend-icon="mdiCloseCircle"
                block
                @click="cancelDialog?.show()"
              >
                <span v-if="p_order.jaw === Arcade.MANDIBLE"
                  >Annuler la Mandibule.</span
                >
                <span v-else>Annuler le Maxillaire.</span>
              </w-btn>
            </w-card>
          </w-menu>

          <!-- Cancel dialog -->
          <BaseDialog
            ref="cancelDialog"
            max-width="600px"
            :p_title="'Annuler le fil ' + m_wireTypeString + ' ?'"
            :p_subtitle="
              'Voulez-vous vraiment annuler le fil ' + m_wireTypeString + ' ?'
            "
          >
            <!-- Cancel dialog content -->
            <w-card-actions class="justify-end">
              <w-btn
                variant="tonal"
                @click="cancelDialog?.close()"
              >
                Non, je veux garder le fil
              </w-btn>
              <w-btn
                color="error"
                variant="tonal"
                @click="cancelOrder()"
              >
                Oui, je confirme
              </w-btn>
            </w-card-actions>
          </BaseDialog>
        </w-col>
      </w-row>
      <!-- wire edit request -->
      <!-- Ask image -->
      <w-row
        v-if="
          m_wireValidationStatus ===
          WireValitationAction.REQUEST_UPDATE_ASK_IMAGE
        "
      >
        <w-col>
          <w-card
            class="pa-0 pt-0 ma-0 mt-0 reduce-br"
            elevation="0"
          >
            <!-- show wire config -->
            <w-card-title class="text-h6 pa-2">
              Dessiner sur l'image ?
              <p class="text-caption ma-0">
                En cliquant sur "oui", la vue 3D actuelle sera figée et vous
                pourrez dessiner sur l'image pour indiquer les modifications à
                apporter au fil.
              </p>
            </w-card-title>

            <w-card-actions class="justify-space-around">
              <!-- Skip drawing -->
              <w-btn
                data-cy="skip-drawing-button"
                size="small"
                variant="tonal"
                color="white"
                @click="
                  m_wireValidationStatus = WireValitationAction.REQUEST_UPDATE
                "
              >
                non
              </w-btn>
              <!-- Yes, draw on image -->
              <w-btn
                data-cy="draw-on-image-button"
                size="small"
                color="white"
                @click="drawOnImage()"
              >
                Oui
              </w-btn>
            </w-card-actions>
          </w-card>
        </w-col>
      </w-row>

      <!-- Check image -->
      <w-row
        v-if="
          m_wireValidationStatus ===
          WireValitationAction.REQUEST_UPDATE_CHECK_IMAGE
        "
      >
        <w-col>
          <w-card
            class="pa-0 pt-0 ma-0 mt-0 reduce-br"
            elevation="0"
          >
            <!-- show wire config -->
            <w-card-title class="text-h6 pa-2">
              Dessinez sur l'image
              <p class="text-caption ma-0">
                Vous pouvez désormais dessiner sur l'image. Passez à l'étape
                suivante pour ajouter un commentaire et envoyer la demande.
              </p>
            </w-card-title>

            <w-card-actions class="justify-space-around">
              <!-- go back to drawing choice -->
              <w-btn
                data-cy="go-back-to-drawing-choice-button"
                size="small"
                variant="tonal"
                color="white"
                @click="goBackToAskImage()"
              >
                Retour
              </w-btn>
              <!-- continue after drawing -->
              <w-btn
                data-cy="continue-after-drawing-button"
                size="small"
                color="white"
                @click="
                  m_wireValidationStatus =
                    WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
                "
              >
                Continuer
              </w-btn>
            </w-card-actions>
          </w-card>
        </w-col>
      </w-row>

      <w-row>
        <w-col>
          <WireEditRequestCard
            v-if="
              m_wireValidationStatus === WireValitationAction.REQUEST_UPDATE ||
              m_wireValidationStatus ===
                WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
            "
            :p_privilege="p_privilege"
            @cancel="changeDecision()"
            @submit="checkModificationRequest"
            @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col>
          <WireEditCard
            v-if="m_wireValidationStatus === WireValitationAction.WIRE_EDIT"
            :p_order="p_order"
            :p_privilege="p_privilege"
            @cancel="changeDecision()"
            @submit="sendWireModifications()"
          />
        </w-col>
      </w-row>
    </w-container>
  </div>
</template>

<script lang="ts">
import GCodeDownloadButton from "@/components/shared/GCodeDownloadButton.vue";
import PDFDownloadButton from "@/components/shared/PDFDownloadButton.vue";
import STLDownloadButton from "@/components/shared/STLDownloadButton.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import CommentHelper from "@/helpers/CommentHelper";
import OrderHelper from "@/helpers/OrderHelper";
import Comment from "@/models/Comment";
import Order from "@/models/Order";
import Project from "@/models/Project";
import { ColorsConstants } from "@/shared/constants";
import Logger from "@/shared/logger";
import {
  mdiArrowRight,
  mdiCheckCircle,
  mdiChevronDown,
  mdiCircleEditOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCommentEdit,
} from "@mdi/js";
import {
  Arcade,
  ArcadeString,
  CommentType,
  DimensionString,
  MaterialString,
  OrderState,
  Privilege,
} from "@winnove/vue-wlib/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Conversation from "../conversation/Conversation.vue";
import BaseDialog from "./BaseDialog.vue";
import CommentArea from "./CommentArea.vue";
import PDFDialog from "./PDFDialog.vue";
import WireEditCard from "./WireEditCard.vue";
import WireEditRequestCard from "./WireEditRequestCard.vue";
import WireSummaryOrderDetail from "./WireSummaryOrderDetail.vue";

enum WireValitationAction {
  NONE,
  REQUEST_UPDATE_ASK_IMAGE,
  REQUEST_UPDATE_CHECK_IMAGE,
  REQUEST_UPDATE_WITH_IMAGE,
  REQUEST_UPDATE,
  WIRE_EDIT,
}

export default defineComponent({
  name: "WireSummary",
  components: {
    GCodeDownloadButton,
    PDFDownloadButton,
    STLDownloadButton,
    WireEditRequestCard,
    WireEditCard,
    WireSummaryOrderDetail,
    PDFDialog,
    Conversation,
    CommentArea,
  },
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_isEditing: {
      type: Boolean,
      required: true,
    },
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_isInThisTab: {
      type: Boolean,
      required: false,
      default: true,
    },
    p_fixedHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_trackingNumber = ref("");
    const m_attestationComment = ref(props.p_order.getAttestationComment());
    const m_modifCommentReady = ref(false);
    const m_wireValidationStatus = ref(WireValitationAction.NONE);

    const m_wireTypeString = computed(() => {
      if (props.p_order.jaw === Arcade.MANDIBLE) {
        return "Mandibulaire";
      } else if (props.p_order.jaw === Arcade.MAXILLA) {
        return "Maxillaire";
      } else {
        return "";
      }
    });

    const m_internalComment = ref(props.p_order.getInternalComment());
    const m_downloadMenu = ref(false);
    const m_doctorMenu = ref(false);
    const m_panel = ref(-1);

    const cancelDialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function hasMaxi(): boolean {
      return props.p_project.archMaxilla()?.existsInDatabase() ?? false;
    }

    function hasMandi(): boolean {
      return props.p_project.archMandible()?.existsInDatabase() ?? false;
    }

    async function _updateOrder(): Promise<void> {
      await OrderHelper.updateOrder(props.p_order);
      context.emit("order_updated", props.p_order);
    }

    function sendToProd(): void {
      props.p_order.sendToProd();
      _updateOrder();
    }

    function requestValidation(): void {
      props.p_order.requestValidation();
      _updateOrder();
    }

    async function submitValidation() {
      props.p_order.submitValidation();
      await _updateOrder();

      m_attestationComment.value.content +=
        "Design validé le " +
        new Date().toLocaleDateString() +
        " à " +
        new Date().toLocaleTimeString() +
        " par Dr. " +
        AuthHelper.getLoggedUser().lastName.toUpperCase() +
        ".\n";
      if (m_attestationComment.value.existsInDatabase())
        CommentHelper.updateComment(m_attestationComment.value as Comment);
      else
        await CommentHelper.addComment(m_attestationComment.value as Comment);

      logger.success("Design validé.");
    }

    function cancelOrder(): void {
      props.p_order.cancel();
      cancelDialog.value?.close();
      _updateOrder();
      logger.success("Commande annulée.");
    }

    function setKeyAsMade(): void {
      props.p_order.markKeyAsBuilt();
      _updateOrder();
    }

    function markAsShipped(p_trackingNumber: string): void {
      props.p_order.markAsShipped(p_trackingNumber);
      _updateOrder();
    }

    function markAsDelivered(): void {
      props.p_order.markAsDelivered();
      _updateOrder();
    }

    function setOrderStatus(p_status: OrderState): void {
      props.p_order.setStatus(p_status);
      _updateOrder();
    }

    async function checkModificationRequest(
      commentContent: string
    ): Promise<void> {
      let createdComment;
      if (
        m_wireValidationStatus.value !==
          WireValitationAction.REQUEST_UPDATE_WITH_IMAGE &&
        commentContent.length === 0
      ) {
        // comment is optionnal when an image is attached
        logger.warning(
          "Veuillez saisir un commentaire avant de cliquer sur envoyer"
        );
        return;
      } else {
        // update comment
        const comment = new Comment();
        comment.type = CommentType.REQUEST_UPDATE;
        comment.orderId = props.p_order.id;
        comment.userId = AuthHelper.getLoggedUser().id;
        comment.content = commentContent;
        createdComment = await CommentHelper.addComment(comment);
      }

      // update image if necessary
      if (
        m_wireValidationStatus.value ==
        WireValitationAction.REQUEST_UPDATE_WITH_IMAGE
      ) {
        context.emit("sendModificationImage", createdComment.id);
        // the order will be updated if the modification image is correctly sent
        // (the Editor will call the function validateModificationRequest)
      } else {
        // update order
        validateModificationRequest();
      }
    }

    function validateModificationRequest(): void {
      context.emit("hideImage");
      props.p_order.requestModifications();
      m_wireValidationStatus.value = WireValitationAction.NONE;
      _updateOrder();
      logger.success("Demande de modification envoyée.");
    }

    watch(
      () => props.p_order,
      () => {
        m_attestationComment.value = props.p_order.getAttestationComment();
        m_internalComment.value = props.p_order.getInternalComment();
        checkReadAttestationInternalComment();
      }
    );

    watch(
      () => props.p_isInThisTab,
      () => {
        checkReadAttestationInternalComment();
      }
    );

    onMounted(() => {
      checkReadAttestationInternalComment();
    });

    async function checkReadAttestationInternalComment() {
      if (props.p_isInThisTab) {
        let commentsIdToRead = [];
        if (!m_attestationComment.value.hasBeenReadByCurrentUser())
          commentsIdToRead.push(m_attestationComment.value);
        if (!m_internalComment.value.hasBeenReadByCurrentUser())
          commentsIdToRead.push(m_internalComment.value);
        if (commentsIdToRead.length > 0) {
          await CommentHelper.readComments(
            props.p_order.id!,
            commentsIdToRead as number[]
          );
          context.emit("requestUpdateFromPinia");
        }
      }
    }

    function validationEditWire(): void {
      m_wireValidationStatus.value = WireValitationAction.WIRE_EDIT;
      context.emit("editWire");
    }

    function changeDecision(): void {
      context.emit("hideImage");
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("goToSummary");
    }

    function sendWireModifications(): void {
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("saveManualWireEdit");
    }

    function drawOnImage(): void {
      m_wireValidationStatus.value =
        WireValitationAction.REQUEST_UPDATE_CHECK_IMAGE;
      context.emit("drawOnImage");
    }

    function sendModificationImage(): void {
      m_wireValidationStatus.value = WireValitationAction.NONE;
      context.emit("hideImage");
      context.emit("sendModificationImage");
    }

    function goBackToAskImage(): void {
      context.emit("clearImage");
      context.emit("hideImage");
      m_wireValidationStatus.value =
        WireValitationAction.REQUEST_UPDATE_ASK_IMAGE;
    }

    function isUserProvidingFeedback(): boolean {
      return m_wireValidationStatus.value !== WireValitationAction.NONE;
    }

    return {
      Privilege,
      Arcade,
      ArcadeString,
      DimensionString,
      MaterialString,
      OrderState,
      m_trackingNumber,
      m_attestationComment,
      m_wireTypeString,
      m_wireValidationStatus,
      m_panel,
      WireValitationAction,
      ColorsConstants,
      m_modifCommentReady,
      m_internalComment,
      m_downloadMenu,
      m_doctorMenu,
      cancelDialog,
      hasMaxi,
      hasMandi,
      sendToProd,
      requestValidation,
      submitValidation,
      cancelOrder,
      setKeyAsMade,
      markAsShipped,
      markAsDelivered,
      checkModificationRequest,
      validationEditWire,
      changeDecision,
      sendWireModifications,
      drawOnImage,
      sendModificationImage,
      goBackToAskImage,
      validateModificationRequest,
      isUserProvidingFeedback,
      setOrderStatus,
      mdiArrowRight,
      mdiCheckCircle,
      mdiChevronDown,
      mdiCircleEditOutline,
      mdiCloseCircle,
      mdiCommentEdit,
      mdiClose,
    };
  },
});
</script>

<style lang="scss">
.title_wire_summary {
  border-bottom: 2px solid #d8dfea;
  padding-bottom: 6px;
}

.summary-container .text-subtitle-2 {
  line-height: 1.1;
}

.text-caption {
  word-break: normal !important;
}

#order-detail-panels .v-expansion-panel-title,
#order-detail-panels .v-expansion-panel-text__wrapper {
  padding: 12px !important;
}

.container-wire-summary > .v-row {
  margin: 0 !important;
  flex-grow: 0;
  flex-basis: 0;
  & > .v-col {
    padding: 0 !important;
  }
}
</style>
