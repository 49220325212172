<template>
  <w-card class="h-100 w-100 bg-white">
    <w-card-title>
      <w-select
        v-model="m_date"
        data-cy="select-stat-week"
        :items="m_date_options"
        outlined
        :label="`Semaine ${m_currentDate}`"
        color="white"
        class="custom-select ma-2"
        @update:modelValue="refreshBarData"
        hide-details
      />
    </w-card-title>
    <w-row
      class="custom-height w-100 ma-0 d-flex flex-column justify-center align-center"
    >
      <w-bar
        class="ma-4"
        :data="m_taskPerCategoryData"
        :options="m_options"
        :p_loading="m_loading"
        :p_empty="false"
      />
    </w-row>
  </w-card>
</template>

<script lang="ts">
import DateHelper from "@/helpers/DateHelper";
import router, {
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_I3D,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
} from "@/router";
import { PROD_STAT_BAR_1 } from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

enum WEEKS_OPTIONS {
  S0 = "Semaine courante",
  S1 = "S+1",
  S2 = "S+2",
}

export default defineComponent({
  name: "TasksCategoryPerWeekBar",
  components: {},
  props: {
    p_refreshInterval: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const m_date_options = ref([
      WEEKS_OPTIONS.S0,
      WEEKS_OPTIONS.S1,
      WEEKS_OPTIONS.S2,
    ]);
    const m_date = ref(WEEKS_OPTIONS.S0);
    const m_currentDate = ref();
    const m_taskPerCategoryData = ref();
    const m_interval = ref();

    let m_loading = ref(true);

    const m_options = ref({
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
        },
      },
      plugins: {
        datalabels: {
          clamp: true,
          anchor: "end",
          align: "bottom",
          formatter: formatterNotZero,
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
      onClick: (event: Event, elements: any) => {
        switch (elements[0].index) {
          case 0:
            router.push({ name: ROUTE_PRODUCTIONS_DRAW });
            break;
          case 1:
            router.push({ name: ROUTE_PRODUCTIONS_I3D });
            break;
          case 2:
            router.push({ name: ROUTE_PRODUCTIONS_TOMAKE });
            break;
          case 3:
            router.push({ name: ROUTE_PRODUCTIONS_SHIP });
            break;
        }
      },
    });

    function formatterNotZero(value: number) {
      return value == 0 ? "" : value;
    }

    onMounted(() => {
      refreshBarData();
      m_interval.value = setInterval(refreshBarData, props.p_refreshInterval);
    });

    onUnmounted(() => {
      clearInterval(m_interval.value);
    });

    async function refreshBarData(): Promise<void> {
      m_loading.value = true;
      let date = new Date();
      if (m_date.value == WEEKS_OPTIONS.S1) date.setDate(date.getDate() + 7);
      if (m_date.value == WEEKS_OPTIONS.S2) date.setDate(date.getDate() + 14);

      const weekNumber = DateHelper.getWeekNumber(DateTime.fromJSDate(date));
      const year = date.getFullYear();

      m_currentDate.value = weekNumber;
      await axios
        .get(`stat_production_process/${weekNumber}/${year}/tasksPerWeek`, {
          // query URL without using browser cache
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then((p_response: AxiosResponse) => {
          const response = p_response.data.response.workloadPerWeek;
          m_taskPerCategoryData.value = constructBarChart(response);

          m_loading.value = false;
        });
    }

    function constructBarChart(workloadPerWeek: any[]): any {
      let tasksDone = [];
      let tasksToDo = [];
      for (let process of workloadPerWeek) {
        tasksToDo.push(process.nbTasks);
        tasksDone.push(process.nbTasksDone);
      }
      return {
        labels: ["Dessin", "Impression 3D", "Fabrication Fil", "Expéditions"],
        datasets: [
          {
            label: "Tâches faites",
            datalabels: {
              color: "white",
            },
            backgroundColor: PROD_STAT_BAR_1,
            data: tasksDone,
          },
          {
            label: "Tâches à faire",
            datalabels: {
              color: "PROD_STAT_BAR_1",
            },
            backgroundColor: "rgba(200, 200, 200, 0.25)",
            data: tasksToDo,
          },
        ],
      };
    }

    return {
      m_date_options,
      m_date,
      m_currentDate,
      m_loading,
      refreshBarData,
      m_options,
      m_taskPerCategoryData,
    };
  },
});
</script>

<style lang="scss">
.go-to-top {
  position: absolute;
  right: 25px;
  top: 15px;
}
</style>
