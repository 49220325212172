<template>
  <tr
    class="ProdProjectRow"
    :class="p_isCritical ? 'critical' : p_isFeatured ? 'featured' : 'normal'"
  >
    <!-- select -->
    <td>
      <div class="d-flex flex-row">
        <w-checkbox
          v-model="m_isSelected"
          hide-details
          density="compact"
        ></w-checkbox>
        <w-tooltip
          v-if="!p_isTaskList && p_order.task"
          right
        >
          <template v-slot:activator="{ props }">
            <w-avatar
              color="#d4d3ed"
              size="32"
              v-bind="props"
              >{{ p_order.task.user.getInitials() }}</w-avatar
            >
          </template>
          <span
            >Attribué à {{ p_order.task.user.firstName }}
            {{ p_order.task.user.lastName }}.</span
          >
        </w-tooltip>
      </div>
    </td>
    <!-- Comments. -->
    <CommentsDialog
      v-if="p_order"
      :p_privilege="p_privilege"
      :p_order="p_order"
      @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
    ></CommentsDialog>

    <!-- Installation date. -->
    <td>
      <p class="ma-0">
        {{ p_order.project.getFormatedInstallationDate() }}
      </p>
    </td>

    <!-- State. -->
    <td>
      <OrderStateChip
        :p_privilege="p_privilege"
        :p_state="p_order.status"
        :p_isSelected="false"
      />
      <a
        v-if="p_order.canShowTrackingLink()"
        @click="trackOrder(p_order)"
      >
        <span :class="'span_not_selected'">{{
          getTrackingLinkText(p_order)
        }}</span>
      </a>
    </td>

    <!-- Reference. -->
    <td>
      <p
        v-if="p_order"
        class="ma-0"
      >
        {{ p_order.getReference() }}
      </p>
    </td>

    <!-- Matériau. -->
    <td align-self="center">
      <p class="ma-0">
        {{ MaterialString[p_order.wire.material] }}
        {{ DimensionString[p_order.wire.dimension] }}
      </p>
    </td>

    <!-- Arcade image. -->
    <td>
      <span class="d-flex">
        <img
          :src="
            p_order.jaw === Arcade.MAXILLA
              ? '/img/icon/default/maxilla.svg'
              : '/img/icon/default/mandible.svg'
          "
          alt="Arcade"
        />
      </span>
    </td>

    <!-- Key. -->
    <td>
      <div v-if="p_order && !p_order.toBeCompleted()">
        <span v-if="p_order.wire && p_order.wire.keyNeeded">oui</span>
        <span v-else>non</span>
      </div>
    </td>

    <!-- I3D. -->
    <td>
      <div v-if="p_order && !p_order.toBeCompleted()">
        <w-checkbox
          v-model="p_order.printed3D"
          @input="updateOrder(p_order)"
          v-ripple
          :color="p_isFeatured ? 'black' : '#7b8595'"
          hide-details
          density="compact"
        ></w-checkbox>
      </div>
    </td>

    <!-- Teeth range. -->
    <td>
      <span v-if="p_order && p_order.wire && !p_order.toBeCompleted()">
        {{ p_order.wire.teethRange }}
      </span>
    </td>

    <!-- Creation date. -->
    <td align-self="center">
      <p class="ma-0">
        {{ p_order.project.getFormatedCreationDate() }}
      </p>
    </td>

    <!-- actions -->
    <td align-self="right">
      <div class="d-flex flex-row justify-end align-center pr-6">
        <!-- Mark key as made -->
        <w-tooltip
          v-if="p_order.keyHasToBeMade()"
          left
        >
          <template v-slot:activator="{ props }">
            <w-btn
              variant="text"
              size="small"
              icon
              @click="markKeyAsMade()"
              v-bind="props"
            >
              <w-icon
                :color="p_isFeatured ? 'black' : '#7b8595'"
                :icon="mdiKeyVariant"
              >
              </w-icon>
            </w-btn>
          </template>
          <span>Marquer la clé fabriquée.</span>
        </w-tooltip>

        <!-- Ship order -->
        <w-tooltip
          v-if="hasToBeShipped()"
          left
        >
          <template v-slot:activator="{ props }">
            <w-btn
              variant="text"
              size="small"
              icon
              @click="markAsShipped()"
              v-bind="props"
            >
              <w-icon
                :color="p_isFeatured ? 'black' : '#7b8595'"
                :icon="mdiPackageVariantClosed"
              >
              </w-icon>
            </w-btn>
          </template>
          <span>Ajouter un numéro de suivi</span>
        </w-tooltip>

        <!-- Download 3D printable file -->
        <w-tooltip
          v-else
          left
        >
          <template v-slot:activator="{ props }">
            <w-btn
              variant="text"
              size="small"
              icon
              @click="download3Dfile()"
              v-bind="props"
            >
              <w-icon
                :color="p_isFeatured ? 'black' : '#7b8595'"
                :icon="mdiPrinter3dNozzleOutline"
              >
              </w-icon>
            </w-btn>
          </template>
          <span>Impression 3D</span>
        </w-tooltip>

        <!-- open project -->
        <w-tooltip left>
          <template v-slot:activator="{ props }">
            <w-btn
              variant="text"
              size="small"
              icon
              @click="openOrder()"
              @click.middle="openOrder(true)"
              v-bind="props"
            >
              <w-icon
                :color="p_isFeatured ? 'black' : '#7b8595'"
                :icon="mdiArrowCollapseRight"
              >
              </w-icon>
            </w-btn>
          </template>
          <span>Ouvrir</span>
        </w-tooltip>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import OrderHelper from "@/helpers/OrderHelper";
import Order from "@/models/Order";
import router, { ROUTE_EDITOR } from "@/router";
import Logger from "@/shared/logger";
import {
  mdiArrowCollapseRight,
  mdiKeyVariant,
  mdiMessageTextOutline,
  mdiPackageVariantClosed,
  mdiPrinter3dNozzleOutline,
} from "@mdi/js";
import {
  Arcade,
  DimensionString,
  MaterialString,
  OrderState,
  Privilege,
} from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import saveAs from "file-saver";
import { WritableComputedRef, computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ProdProjectRow",
  components: {},

  props: {
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_isFeatured: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_isCritical: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_selectedOrders: {
      type: Array as () => Order[],
      required: true,
    },
    p_isTaskList: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const logger = Logger.getInstance();
    const m_isSelected: WritableComputedRef<boolean> = computed({
      get: () =>
        props.p_selectedOrders.some(
          (p_order) => p_order.id === props.p_order.id
        ),
      set: (value) => {
        if (value) {
          emit("selectItem", props.p_order.id, true);
        } else {
          emit("selectItem", props.p_order.id, false);
        }
      },
    });

    const m_menu = ref(false);

    function trackOrder(p_order: Order | null) {
      if (!p_order) return;
      return OrderHelper.trackOrder(p_order.trackingNumber);
    }

    function updateOrder(p_order: Order | null) {
      if (!p_order) return;
      emit("updateOrder", p_order);
    }

    function getTrackingLinkText(p_order: Order | null) {
      if (!p_order) return;
      if (p_order.isDelivered()) {
        return "Preuve de livraison >";
      }
      return "Suivre le colis >";
    }

    function openOrder(newtab: boolean = false): void {
      if (newtab) {
        window.open(
          router.resolve({
            name: ROUTE_EDITOR,
            params: { reference: props.p_order.project!.reference },
          }).href,
          "_blank"
        );
      } else
        router.push({
          name: ROUTE_EDITOR,
          params: { reference: props.p_order!.project!.reference },
        });
    }

    async function download3Dfile() {
      const arch = props.p_order.project?.getArch(props.p_order.jaw);
      if (!arch) {
        logger.error("Impossible de récupérer le fichier pour cette commande ");
        return;
      }

      await axios
        .get("archs/" + arch.id + "/stlI3D", {
          responseType: "blob",
        })
        .then((p_response: AxiosResponse) => {
          const file: File = new File([p_response.data], arch.fileName);
          saveAs(file, arch.getReference() + "_I3D.stl");
        })
        .catch((error) => {
          logger.error(
            error,
            "Impossible de récupérer le fichier pour cette commande"
          );
        });
    }

    function hasToBeShipped(): boolean {
      return props.p_order.status === OrderState.TO_SHIP;
    }

    function markAsShipped(): void {
      emit("markAsShipped", props.p_order);
    }

    function markKeyAsMade(): void {
      emit("markKeyAsMade", props.p_order);
    }

    return {
      Arcade,
      m_isSelected,
      MaterialString,
      DimensionString,
      m_menu,
      updateOrder,
      openOrder,
      trackOrder,
      getTrackingLinkText,
      download3Dfile,
      hasToBeShipped,
      markAsShipped,
      markKeyAsMade,
      mdiMessageTextOutline,
      mdiPackageVariantClosed,
      mdiKeyVariant,
      mdiPrinter3dNozzleOutline,
      mdiArrowCollapseRight,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProdProjectRow {
  position: relative;
  font-weight: 700;
}
.featured {
  color: black;
  background-color: rgba(var(--v-theme-wGrey), 0.2);
}

.normal {
  color: #7b8595;
}

.critical {
  color: black;
  background-color: rgba(var(--v-theme-secondary), 0.2);
}

.span_not_selected {
  color: rgb(var(--v-theme-primary));
  text-decoration: underline;
  cursor: pointer;
}
</style>
