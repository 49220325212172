<template>
  <editable-data-table-menu
    :p_data="m_project.patient.lastName + ' ' + m_project.patient.firstName"
    @cancel="cancel()"
    @save="save()"
    @open="updatePatientList"
    :p_disabled="p_disabled"
  >
    <w-autocomplete
      v-model="m_project.patientId"
      :items="m_patientList"
      :item-title="(item) => item.lastName + ' ' + item.firstName"
      :item-value="(item) => item.id"
      :rules="[(v) => (v && v > -1) || 'Valeur requise']"
      hide-details
      no-data-text="Aucun résultat"
      label="Sélectionner le patient"
      autofocus
    ></w-autocomplete>
  </editable-data-table-menu>
</template>

<script lang="ts">
import Patient from "@/models/Patient";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import { useRepo } from "pinia-orm";
import { computed, defineComponent, onMounted, ref } from "vue";
import EditableDataTableMenu from "./EditableDataTableMenu.vue";

export default defineComponent({
  name: "EditablePatient",
  components: { EditableDataTableMenu },
  inheritAttrs: false,
  props: {
    p_project: {
      type: Object as () => Project,
      required: true,
    },
    p_disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const m_project = computed({
      get: () => props.p_project,
      set: (value: Project) => context.emit("update:p_project", value),
    });
    const m_patientList = ref<Patient[]>([]);
    const m_dataSave = ref<Project>(props.p_project);

    function updatePatientList(): void {
      let userId = m_project.value.patient!.userId;

      m_patientList.value = useRepo(Patient)
        .query()
        .where("userId", userId)
        .orderBy("lastName")
        .get();
    }

    onMounted(() => {
      updatePatientList();
      m_dataSave.value = m_project.value;
    });

    function cancel(): void {
      m_project.value = m_dataSave.value as Project;
    }

    function save(): void {
      // check if the patientId is valid
      if (!m_project.value.patientId) {
        Logger.getInstance().error("Entrée non valide! ");
        cancel();
        return;
      }

      m_dataSave.value = m_project.value;
      context.emit("save");
    }

    return {
      m_project,
      m_patientList,
      cancel,
      save,
      updatePatientList,
    };
  },
});
</script>
