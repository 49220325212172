<template>
  <BaseDialog
    ref="dialog"
    :p_title="m_patient.id != null ? 'Editer un patient' : 'Nouveau patient'"
    @close="onClose()"
    p_noPadding
  >
    <w-form
      ref="formPatient"
      v-model="m_isFormValid"
      @submit.prevent="validateForm()"
    >
      <w-card-text>
        <w-text-field
          v-model.trim="m_patient.lastName"
          label="Nom du patient"
          :prepend-inner-icon="mdiAccountBox"
          :rules="[(v) => (v && !!v.trim()) || 'Nom requis']"
          required
          autofocus
          class="mb-4"
        ></w-text-field>
        <w-text-field
          v-model.trim="m_patient.firstName"
          label="Prénom du patient"
          :prepend-inner-icon="mdiAccountBox"
          :rules="[(v) => (v && !!v.trim()) || 'Prénom requis']"
          required
        ></w-text-field>
      </w-card-text>

      <w-card-actions class="d-flex justify-end align-center">
        <w-btn
          :disabled="!m_isFormValid"
          type="submit"
          >{{ m_patient.id != null ? "Enregistrer" : "Nouveau" }}
        </w-btn>
      </w-card-actions>
    </w-form>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import PatientHelper from "@/helpers/PatientHelper";
import Patient from "@/models/Patient";
import Logger from "@/shared/logger";
import { mdiAccountBox } from "@mdi/js";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PatientDialog",
  props: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const formPatient = ref<HTMLFormElement | null>(null);
    const m_isFormValid = ref(true);
    const m_patient = ref(new Patient());
    const dialog = ref<InstanceType<typeof BaseDialog> | null>(null);

    function setPatient(p_patient: Patient): void {
      Object.assign(m_patient.value, p_patient);
    }

    function onClose(): void {
      m_patient.value = new Patient();
      formPatient.value?.reset();
      context.emit("refresh");
    }

    function show(): void {
      dialog.value?.show();
    }

    function close(): void {
      dialog.value?.close();
    }

    async function validateForm(): Promise<void> {
      if (m_patient.value.id == null) {
        // Create new patient.
        m_patient.value.userId = AuthHelper.getLoggedUser().id;
        await PatientHelper.addPatient(m_patient.value as Patient).then(() => {
          logger.success("Patient ajouté");
          close();
        });
      } else {
        // Update patient.
        await PatientHelper.updatePatient(m_patient.value as Patient).then(
          () => {
            logger.success("Patient modifié");
            close();
          }
        );
      }
    }
    return {
      dialog,
      formPatient,
      m_isFormValid,
      m_patient,
      setPatient,
      show,
      onClose,
      validateForm,
      mdiAccountBox,
    };
  },
  components: { BaseDialog },
});
</script>
