<template>
  <w-container
    fill-height
    fluid
  >
    <w-row
      id="primary-stats"
      class="custom-height"
    >
      <remaining-tasks-per-day-pies :p_refreshInterval="interval" />
    </w-row>
    <w-row
      id="secondary-stats"
      class="custom-height second-row pt-4"
    >
      <w-col cols="6">
        <tasks-category-per-week-bar :p_refreshInterval="interval" />
      </w-col>
      <w-col cols="6">
        <shipped-versus-order-line :p_refreshInterval="interval" />
      </w-col>
    </w-row>
  </w-container>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AccueilStatsProd",
  components: {},
  props: {},
  setup() {
    // Interval of 5 mins
    const interval = ref(5 * 60 * 1000);
    return { interval };
  },
});
</script>

<style lang="scss" scoped>
.custom-height {
  height: 88dvh;
}

.second-row {
  margin: 50px 0 10px 0;
}
</style>
