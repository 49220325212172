import { Constants } from "@/Constants";
import Project from "@/models/Project";
import Logger from "@/shared/logger";
import {
  Arcade,
  CtrlZActionType,
  PointType,
  WireType,
} from "@winnove/vue-wlib/enums";
import {
  DoubleSide,
  Intersection,
  Line3,
  Mesh,
  MeshBasicMaterial,
  Plane,
  PlaneGeometry,
  Raycaster,
  Scene,
  Vector3,
} from "three";
import { CtrlZAction } from "./SceneCommon";
import SceneControls from "./SceneControls";
import SceneMeshOrPoints from "./SceneMeshOrPoints";

export default class SceneJawArchWirePlane extends SceneMeshOrPoints {
  private _project: Project | null;
  private _arcade: Arcade;

  constructor(
    scene: Scene,
    p_arcade: Arcade,
    prj: Project | null,
    controls: SceneControls
  ) {
    super(scene, controls);
    this._arcade = p_arcade;
    this._project = prj;
  }

  public updateProject(p_project: Project) {
    this._project = p_project;
  }

  public disposeMesh(): void {
    super.disposeMesh();
  }

  public disposeAll(): void {
    super.disposeAll();
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    super.rotate(p_axis, p_angle);
  }

  public setMeshVisible(p_visible: boolean): void {
    super.setMeshVisible(false); // We don't want to show the big plane
  }

  public has2DPlane(): boolean {
    return this.mesh !== null;
  }

  public getPlaneMesh(): Mesh | null {
    return this.mesh;
  }

  public isValid(): boolean {
    return this.points.length === 3;
  }

  public getPlane(): Plane | undefined {
    if (this.isValid()) {
      const plane = new Plane();
      plane.setFromCoplanarPoints(
        this.points[0].p,
        this.points[1].p,
        this.points[2].p
      );
      return plane;
    }
    return undefined;
  }

  public projectRaycast(
    p_cameraPos: Vector3,
    p_intersection: Vector3,
    p_archMesh: Mesh
  ): Vector3 | undefined {
    if (this.isValid()) {
      const plane = this.getPlane()!;
      // Project the intersection point and the camera position on the plane
      const projectedPoint = new Vector3();
      plane.projectPoint(p_intersection, projectedPoint);
      const projectedCamera = new Vector3();
      plane.projectPoint(p_cameraPos, projectedCamera);
      // Create a raycaster from the projected camera position to the projected intersection point
      const raycaster = new Raycaster(
        projectedCamera.clone(),
        projectedPoint.clone().sub(projectedCamera.clone()).normalize()
      );
      // Intersect the raycaster with the arch mesh
      const raycastResults: Array<Intersection> =
        raycaster.intersectObject(p_archMesh);
      if (raycastResults.length) return raycastResults[0].point;
      else return undefined;
    }
    return undefined;
  }

  public pointsAreAligned(): boolean {
    if (this.isValid()) {
      const plane = new Plane();
      plane.setFromCoplanarPoints(
        this.points[0].p,
        this.points[1].p,
        this.points[2].p
      );
      return plane.constant === 0;
    }
    return false;
  }

  public loadPoints(pointsJSON: string): void {
    const points = this._parsePointsJSON(pointsJSON, PointType.PLANE);
    points.forEach((p, i) => {
      p.m.name = this._getPointBaseName() + "|" + i;
      p.z.name = "zone" + p.m.name;
      this._addPoint(p);
      // Add point to the scene
      this.scene.add(p.m);
      this.scene.add(p.z);
    });
    this._updatePlaneMesh();
  }

  public addPoint(
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType,
    p_id: number
  ): CtrlZAction | undefined {
    // check that p_type is PLANE
    if (p_type !== PointType.PLANE) {
      Logger.getInstance().error(
        "SceneJawArchWireMesh.addPoint: p_type must be PLANE",
        "Une erreur s'est produite, impossible d'ajouter ce point"
      );
      return;
    }

    // Check if user tries to add a 4th point when creating the 2d plane
    if (this._getPointsLength() === 3) {
      Logger.getInstance().warning(
        "Le plan ne peut être constitué qu'à partir de 3 points."
      );
      return;
    }

    // Check min distance
    if (this._getPointMinDistance(p_point) < Constants.MIN_DISTANCE_PLANE) {
      Logger.getInstance().warning("Ce point est trop proche d'un autre point");
      return;
    }

    // Check if user is placing a plane point on a round wire arcade
    if (
      this._project?.getPrescription(this._arcade) !== null &&
      this._project?.getPrescription(this._arcade)!.wireType ===
        WireType.ROUNDED
    ) {
      Logger.getInstance().info(
        "Créer un plan de pose pour cette arcade n'est pas nécessaire mais vous pouvez tout de même."
      );
    }

    // Create point mesh
    const pointData = this._createPointData(p_point, p_normal, p_type);
    pointData.m.name =
      this._getPointBaseName() +
      "|" +
      (p_id === -1 ? this._getPointsLength() : p_id);
    pointData.z.name = "zone" + pointData.m.name;
    if (p_id === -1) this._addPoint(pointData);
    else {
      this._insertPoint(pointData, p_id);
      this._updatePointsName();
    }

    // Check if points are aligned
    if (this.pointsAreAligned()) {
      Logger.getInstance().warning(
        "Les 3 points sont parfaitement alignés, il n'est pas possible de créer un plan."
      );
      this.removePoint(pointData.m.name);
      return;
    }

    // Add point to the scene
    this.scene.add(pointData.m);
    this.scene.add(pointData.z);

    // Create plane mesh
    this._updatePlaneMesh();
    return {
      type: CtrlZActionType.REMOVE_POINT,
      name: pointData.m.name,
      basePosition: pointData.p,
      normal: pointData.n,
      finalPosition: pointData.m.position,
    };
  }

  private _updatePointsName(): void {
    this.points.forEach((p, i) => {
      p.m.name = this._getPointBaseName() + "|" + i;
      p.z.name = "zone" + p.m.name;
    });
  }

  public removePoint(p_name: string): CtrlZAction | undefined {
    const infos = super.removePoint(p_name);
    this._updatePlaneMesh();
    return infos;
  }

  private _updatePlaneMesh(): void {
    // Remove previous plane mesh
    this.disposeMesh();

    // Check that the plane is valid
    if (!this.isValid()) {
      return;
    }

    // Create plane mesh
    const plane = this.getPlane()!;
    const planeMesh = new Mesh(
      new PlaneGeometry(10000, 10000),
      new MeshBasicMaterial({ side: DoubleSide })
    );
    planeMesh.lookAt(plane.normal);
    plane.intersectLine(
      new Line3(new Vector3(0, -1000, 0), new Vector3(0, 1000, 0)),
      planeMesh.position
    );
    planeMesh.visible = false;
    this.mesh = planeMesh;
    this.mesh.name =
      "plane" + (this._arcade === Arcade.MAXILLA ? "maxilla" : "mandible");

    // Add plane mesh to the scene
    this.scene.add(this.mesh);
  }

  private _getPointBaseName(): string {
    const ptBaseName =
      this._arcade === Arcade.MANDIBLE
        ? Constants.MESH_MANDIBLE_NAME
        : Constants.MESH_MAXILLA_NAME;
    return ptBaseName;
  }
}
