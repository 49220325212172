<template>
  <editable-data-table-menu
    :p_data="hideContent ? '********' : (p_data ?? '-')"
    @cancel="cancel"
    @save="save"
  >
    <w-text-field
      v-model="m_data"
      :rules="[rules.password]"
      :type="'text'"
      autofocus
    ></w-text-field>
  </editable-data-table-menu>
</template>

<script lang="ts">
import UserHelper from "@/helpers/UserHelper";
import Logger from "@/shared/logger";
import { computed, defineComponent, onMounted, ref } from "vue";
import EditableDataTableMenu from "./EditableDataTableMenu.vue";

export default defineComponent({
  name: "EditableDataTablePassword",
  inheritAttrs: false,
  props: {
    p_data: {
      // prop sync
      type: String,
      default: "",
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const m_data = computed({
      get: () => props.p_data,
      set: (value: string) => emit("update:p_data", value),
    });
    const m_dataSave = ref(props.p_data);

    onMounted(() => {
      m_dataSave.value = props.p_data;
    });

    function cancel() {
      m_data.value = m_dataSave.value;
    }

    function save() {
      // check if the password is valid
      if (!UserHelper.testPwd(m_data.value)) {
        m_data.value = m_dataSave.value;
        Logger.getInstance().error(
          "Le mot de passe ne respecte pas les critères de sécurité."
        );
        return;
      }

      m_dataSave.value = m_data.value;
      emit("save");
    }

    return {
      m_data,
      rules: {
        required: (value: any) => !!value || "Required.",
        password: (value: string) => {
          return (
            UserHelper.testPwd(value) ||
            "Min. 8 caractères avec au moins un chiffre et un caractère spécial."
          );
        },
      },
      save,
      cancel,
    };
  },
  components: { EditableDataTableMenu },
});
</script>
