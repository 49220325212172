<template>
  <div class="winnova-background">
    <w-container
      id="container-login"
      fluid
      class="d-flex justify-center align-center"
    >
      <w-card
        class="pa-8 ma-auto"
        elevation="10"
      >
        <router-link :to="{ name: ROUTE_PROJECTS }">
          <w-card-subtitle class="d-flex">
            <w-img
              src="/img/logo/iform-blanc-clinique.png"
              class="mx-4"
              height="50px"
            />
          </w-card-subtitle>
        </router-link>
        <div v-if="!isValidToken()">
          <w-card-subtitle class="text-center">
            <h1>Mot de passe oublié</h1>
          </w-card-subtitle>
          <w-card-text class="text-center pa-0 mb-8">
            Veuillez saisir votre adresse e-mail. Vous recevrez un lien par
            e-mail pour créer un nouveau mot de passe.
          </w-card-text>
          <w-form
            v-model="m_isFormValid"
            @submit.prevent="reset()"
          >
            <div class="subtitle-2 grey--text text--darken-4 font-weight-bold">
              Email
            </div>
            <w-text-field
              v-model.trim="m_email"
              :rules="[rules.required, rules.email]"
              required
              autofocus
              name="email"
            ></w-text-field>
            <w-btn
              block
              :disabled="!m_isFormValid"
              class="mt-6"
              type="submit"
              size="large"
              variant="outlined"
              color="wWhite"
            >
              Réinitialiser mon mot de passe
            </w-btn>
          </w-form>
        </div>
        <div v-else>
          <w-card-subtitle class="text-center">
            <h1>Réinitialisation du mot de passe</h1>
          </w-card-subtitle>
          <w-card-text class="text-center pa-0 mb-8">
            Veuillez saisir un nouveau mot de passe.
          </w-card-text>
          <w-form
            v-model="m_isFormValid"
            @submit.prevent="updatePassword()"
          >
            <w-text-field
              required
              v-model="m_password"
              label="Nouveau mot de passe"
              :type="m_showPassword ? 'text' : 'password'"
              :rules="[rules.required, rules.password]"
              :append-inner-icon="m_showPassword ? mdiEye : mdiEyeOff"
              @click:append-inner="m_showPassword = !m_showPassword"
              class="mb-6"
            ></w-text-field>
            <w-text-field
              required
              v-model="m_passwordVerify"
              label="Confirmez votre nouveau mot de passe"
              :type="m_showPasswordVerify ? 'text' : 'password'"
              :rules="[
                (v) =>
                  v === m_password || 'Les mots de passe ne correspondent pas',
              ]"
              :append-inner-icon="m_showPasswordVerify ? mdiEye : mdiEyeOff"
              @click:append-inner="m_showPasswordVerify = !m_showPasswordVerify"
            ></w-text-field>
            <w-btn
              block
              :disabled="!m_isFormValid"
              class="mt-6"
              color="primary"
              type="submit"
              size="large"
            >
              Mettre à jour le mot de passe
            </w-btn>
          </w-form>
        </div>
      </w-card>
    </w-container>
  </div>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import UserHelper from "@/helpers/UserHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import router, { ROUTE_LOGIN, ROUTE_PROJECTS } from "@/router";
import Logger from "@/shared/logger";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import axios, { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "ResetPassword",
  components: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_isFormValid = ref(true);
    const m_email = ref("");
    const m_password = ref("");
    const m_showPassword = ref(false);
    const m_passwordVerify = ref("");
    const m_showPasswordVerify = ref(false);
    const validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let tokenEmail: string;
    let tokenId: number;
    let tokenName: number;

    onMounted(() => {
      AuthHelper.clearAuthToken();
      WinnoveHelper.clearAll();
    });

    function isValidToken(): boolean {
      const token: string = router.currentRoute.value.params.token as string;
      if (token) {
        const decoded: any = jwtDecode(token);
        if (
          decoded?.exp &&
          decoded.p_payload?.email &&
          decoded.p_payload.name &&
          decoded.p_payload.id &&
          new Date(decoded.exp) <= new Date()
        ) {
          tokenEmail = decoded.p_payload.email;
          tokenId = decoded.p_payload.id;
          tokenName = decoded.p_payload.name;
          return true;
        }
      }
      return false;
    }

    async function reset(): Promise<void> {
      if (!m_email.value.match(validEmail)) {
        logger.error("Adresse email non valide");
      } else {
        await axios
          .post("users/password-reset", { email: m_email.value })
          .then(() => {
            logger.info(
              "Un lien de réinitialisation du mot de passe vous a été envoyé par mail"
            );
            router.push({ name: ROUTE_LOGIN });
          })
          .catch((p_error: AxiosError) => {
            if (p_error.response) {
              logger.error("Adresse email non valide");
            }
          });
      }
    }

    async function updatePassword(): Promise<void> {
      if (m_password.value !== m_passwordVerify.value) {
        logger.error("Les deux mots de passe ne sont pas identique");
      } else {
        await axios
          .put("users/password-reset/" + tokenId, {
            email: tokenEmail,
            name: tokenName,
            password: m_password.value,
          })
          .then(() => {
            logger.info("Votre mot de passe a été mis à jour");
            router.push({ name: ROUTE_LOGIN });
          })
          .catch((p_error: AxiosError) => {
            if (p_error.response) {
              logger.error("Impossible de mettre à jour le mot de passe");
            }
          });
      }
    }

    watch(m_password, () => {
      m_passwordVerify.value = ""; // force validation
    });

    return {
      ROUTE_PROJECTS,
      m_isFormValid,
      m_email,
      m_password,
      m_showPassword,
      m_passwordVerify,
      m_showPasswordVerify,
      isValidToken,
      reset,
      updatePassword,
      rules: {
        required: (value: any) => !!value || "Adresse email requise.",
        password: (value: string) => {
          return UserHelper.testPwd(value)
            ? true
            : "Min. 8 caractères avec au moins un chiffre et un caractère spécial.";
        },
        email: (value: string) => {
          return !!value.match(validEmail) || "Adresse email non valide";
        },
      },
      mdiEye,
      mdiEyeOff,
    };
  },
});
</script>

<style lang="scss" scoped>
.winnova-background {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Pour éviter le débordement */
  position: relative;
  background: linear-gradient(
    to bottom right,
    rgb(var(--v-theme-wViolet)),
    rgb(var(--v-theme-wWhite))
  );
}

.winnova-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300vh;
  height: 300vh;
  background-image: url("/img/logo/WeNova_Lavande-60.svg");
  background-size: 250vh;
  transform: translate(-86.5vh, -80vh);
  opacity: 0.5;

  z-index: 0;
}

#container-login {
  height: 100vh;
  padding: 0 !important;

  .v-card {
    width: 420px;
    max-width: 90%;
  }
}
</style>
