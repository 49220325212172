import BaseModel from "./BaseModel";
import Project from "./Project";

// Store the current entites edited before persistence on server
export default class Session extends BaseModel {
  static entity = "session";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(1),
      token: this.string(""),
      project: this.attr(null),
    };
  }

  declare id: number;
  declare token: string;
  declare project: Project | null;

  public clear(): void {
    this.project = null;
  }

  static readonly piniaOptions = {
    persist: true,
  };
}
