import BaseModel from "./BaseModel";
import Project from "./Project";
import User from "./User";

export default class Patient extends BaseModel {
  static entity = "patients";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      userId: this.number(null),
      lastName: this.string(""),
      firstName: this.string(""),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      //
      user: this.belongsTo(User, "userId"),
      //
      projects: this.hasMany(Project, "patientId"),
    };
  }

  declare id: number | null;
  declare userId: number | null;
  declare lastName: string;
  declare firstName: string;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  //
  declare user: User | null;
  //
  declare projects: Project[] | null;

  public serialize(): {} {
    const object: Patient = Object.assign(new Patient(), this);
    object.user = null;
    object.projects = null;

    return object;
  }
}
