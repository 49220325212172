<template>
  <w-overlay
    v-model="m_visible"
    @click="hide()"
    class="d-flex align-center justify-center"
  >
    <iframe
      :src="m_link"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
      allowfullscreen
      width="800px"
      height="450px"
    ></iframe>
  </w-overlay>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "TutorialOverlay",
  setup(props, context) {
    const m_visible = ref(false);
    const m_link = ref("");

    function setVisible(p_visible: boolean): void {
      m_visible.value = p_visible;
    }

    function hide(): void {
      setVisible(false);
    }

    function show(p_link: string): void {
      setVisible(true);
      m_link.value = p_link;
    }

    return {
      m_visible,
      m_link,
      hide,
      show,
      setVisible,
    };
  },
});
</script>
