<template>
  <w-btn
    class="pl-8 ml-3 filter-btn mt-2"
    variant="tonal"
    color="primary"
    @click="emit('click')"
    :iconLeft="mdiFilterOutline"
    >{{ p_text }}
  </w-btn>
</template>

<script lang="ts">
import { mdiFilterOutline } from "@mdi/js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterBtn",
  components: {},
  props: {
    p_text: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      mdiFilterOutline,
      emit,
    };
  },
});
</script>

<style scoped>
.filter-btn {
  height: 40px;
  font-weight: normal !important;
}
</style>
