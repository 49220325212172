<template>
  <base-dialog
    ref="waitingDialog"
    :p_title="p_title"
    :p_icon="mdiCreation"
    max-width="800px"
    @close="closeImmediately"
  >
    <w-card-text class="mt-6">
      <w-progress-linear
        :modelValue="m_isOverdue ? 0 : m_progress"
        :color="'primary'"
        height="25"
        rounded
        :indeterminate="m_isOverdue"
        :class="[{ 'text-white': m_isOverdue || m_progress >= 50 }]"
      >
        <template v-slot:default="{ value }">
          <span v-if="m_isOverdue"
            >Nous avons besoin d'un peu plus de temps qu'estimé...</span
          >
          <strong v-else>{{ Math.ceil(value) }}%</strong>
        </template>
      </w-progress-linear>

      <div class="mt-8">
        <!-- for every progress stage -->
        <div
          v-for="(stage, index) in m_progressStages"
          :key="index"
          class="d-flex flex-row"
        >
          <w-icon
            v-if="stage.done"
            class="mr-2"
            color="primary"
            :icon="mdiCheckCircle"
          >
          </w-icon>
          <w-progress-circular
            v-else-if="stage.inProgress"
            indeterminate
            size="24"
            class="mr-2"
          ></w-progress-circular>
          <w-icon
            v-else
            left
            :icon="mdiCircleSmall"
          >
          </w-icon>
          <h6 class="text-subtitle-1">
            {{ stage.title }}
          </h6>
        </div>
      </div>
    </w-card-text>
  </base-dialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import { ColorsConstants } from "@/shared/constants";
import { mdiCheckCircle, mdiCircleSmall, mdiCreation } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent, ref, watch } from "vue";

// interface for the progress stages
export interface ProgressStage {
  title: string;
  done: boolean;
  inProgress: boolean;
  duration: number; // in ms
  cumulatedDuration?: number; // in ms
}

export default defineComponent({
  name: "WaitingDialog",
  components: {
    BaseDialog,
  },
  props: {
    p_title: {
      type: String,
      default: "Veuiillez patienter",
    },
    p_progressStages: {
      type: Array as () => ProgressStage[],
      required: true,
    },
    p_enableCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // ref for the dialog visibility
    const waitingDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    // Progress
    const m_progress = ref(0);
    // ref array of progress stages
    const m_progressStages = ref<ProgressStage[]>([]);

    watch(
      () => props.p_progressStages,
      (newVal) => {
        m_progressStages.value = newVal;
        resetProgress();
        updateCumulativeDuration();
      }
    );

    const m_totalDuration = computed(() => {
      return m_progressStages.value.reduce(
        (acc, stage) => acc + stage.duration,
        0
      );
    });

    const m_isOverdue = ref(false);

    let m_interval: number | undefined = undefined;

    // Start the progress : increase the timme every 100ms, set the progress stage to done when the time is up
    function startProgress(): void {
      let time = 0;
      m_isOverdue.value = false;

      resetProgress();
      updateCumulativeDuration();

      // start the interval
      const TIMESTEP = 300; // in ms
      m_interval = window.setInterval(() => {
        // increase the time
        time += TIMESTEP;
        if (time >= m_totalDuration.value) {
          time = m_totalDuration.value;
        }

        // update the progress
        m_progress.value = (time / m_totalDuration.value) * 100;

        // get the current stage
        const currentStage = m_progressStages.value.find(
          (stage) => !stage.done
        );

        // check if currentStage is defined
        if (!currentStage) {
          m_isOverdue.value = true; // set the overdue flag
          clearInterval(); // stop the interval
          return; // stop the function
        }

        // check if the time is up for the current stage
        const timeUp = time >= currentStage.cumulatedDuration!;

        // if currentStage is the last one
        if (
          currentStage ===
          m_progressStages.value[m_progressStages.value.length - 1]
        ) {
          currentStage.inProgress = true;
          if (timeUp) {
            m_isOverdue.value = true; // set the overdue flag
          }
        }
        // if not the last one
        else {
          currentStage.inProgress = true;
          if (timeUp) {
            currentStage.done = true; // set the stage to done
            currentStage.inProgress = false; // set the stage to not in progress
          }
        }

        // check if the progress is done
        if (m_progressStages.value.every((stage) => stage.done)) {
          clearInterval();
        }
      }, TIMESTEP);
    }

    // show the dialog and start the progress
    async function show(): Promise<void> {
      waitingDialog.value?.show();
      startProgress();
    }

    // reset the progress
    function resetProgress(): void {
      m_progress.value = 0;
      m_progressStages.value.forEach((stage) => {
        stage.done = false;
        stage.inProgress = false;
      });
      m_isOverdue.value = false;
    }

    // update the cumulative duration of the progress stages
    function updateCumulativeDuration() {
      m_progressStages.value.forEach((stage, index) => {
        stage.cumulatedDuration = m_progressStages.value
          .slice(0, index + 1)
          .reduce((acc, stage) => acc + stage.duration, 0);
      });
    }

    // clear the interval
    function clearInterval() {
      if (m_interval) {
        window.clearInterval(m_interval);
        m_interval = undefined;
      }
    }

    // set all progress stages to done and close the dialog
    function close(): void {
      // set all progress stages to done
      clearInterval();
      m_isOverdue.value = false;
      m_progressStages.value.forEach((stage) => {
        stage.done = true;
        stage.inProgress = false;
      });
      m_progress.value = 100;

      // close the dialog in 1s
      setTimeout(() => {
        closeImmediately();
      }, 1000);
    }

    // close the dialog immediately
    function closeImmediately(): void {
      waitingDialog.value?.close();
      resetProgress();
      clearInterval();
    }

    return {
      waitingDialog,
      m_progress,
      Privilege,
      ColorsConstants,
      m_progressStages,
      m_isOverdue,
      show,
      close,
      closeImmediately,
      mdiCreation,
      mdiCheckCircle,
      mdiCircleSmall,
    };
  },
});
</script>

<style lang="scss">
.subtitle_new_project_dialog {
  color: rgb(var(--v-theme-primary));
}

#formNewProject {
  .v-input__control > .v-input__slot:before {
    border-color: var(--v-theme-primary);
    border-width: 2px 0 0 0;
  }

  .v-text-field,
  .v-input--selection-controls {
    margin-top: 4px;
  }
}
</style>
