import { Color } from "three";

export const Constants = {
  // Snackbar
  SNACKBAR_ERROR_DURATION: 10000,
  SNACKBAR_WARNING_DURATION: 6000,
  SNACKBAR_INFO_DURATION: 4000,
  SNACKBAR_SUCCESS_DURATION: 3000,

  // Controls.
  CONTROLS_MIN_DISTANCE: 10,
  CONTROLS_MAX_DISTANCE: 300,
  CONTROLS_MIN_AZIMUTH_ANGLE: -Math.PI,
  CONTROLS_MAX_AZIMUTH_ANGLE: Math.PI,
  CONTROLS_ENABLE_PAN: true,
  CONTROLS_ROTATE_SPEED: 2,
  CONTROLS_ZOOM_SPEED: 2,
  CONTROLS_EDIT_POINT_SCALE: 1.15,
  CONTROLS_GIMBALS_AMOUNT: 4, //0: base/plane/wire(antero) 1-3: wire(transversal1&2-vertical)
  CONTROLS_GIMBAL_MESHES_AMOUNT: 3, //invisible meshes for non-orthogonal wire gimbal axes (transversal1&2-vertical)

  // Meshes.
  MESH_MAXILLA_NAME: "maxilla",
  MESH_MANDIBLE_NAME: "mandible",
  MESH_MATERIAL_COLOR: 0x808080,
  MESH_MATERIAL_SHININESS: 0,
  MESH_WIRE_MATERIAL_COLOR: 0x4f49e9,
  MESH_WIRE_MATERIAL_SHININESS: 1,
  MESH_PLANE_MATERIAL_COLOR: 0x504ca0,
  MESH_PLANE_SCALE: 0.25,
  MESH_OPACITY: 0.8,
  MESH_NO_OPACITY: 1,

  // Lights.
  LIGHT_COLOR: 0xffffff,

  // WirePoints
  WIRE_POINT_UNSELECTED_COLOR: 0xffffff,
  WIRE_POINT_SELECTED_COLOR: 0x00144a,

  // Camera.
  CAMERA_DEFAULT_POSITION_PADDING: 0,

  // Maths.
  INCH_TO_MM: 25.4,
  BEND_ANGLE_RADIUS: 0.8,
  BEND_ARCH_SEGMENTS_NB: 20,

  // Key Wire Shape
  KEY_SHAPE_TENON_BASE_WIDTH: 0.85,
  KEY_SHAPE_TENON_WIDTH: 0.55,
  KEY_SHAPE_TENON_BASE_LENGTH: 0.6,
  KEY_SHAPE_TENON_LENGTH: 1.6,

  //Occlusion.
  OCCLUSION_MIN_DISTANCE: 0.2,
  OCCLUSION_MAX_DISTANCE: 0.5,
  OCCLUSION_MIN_COLOR: new Color(0xff0000),
  OCCLUSION_MAX_COLOR: new Color(0xffff00),

  // Fabrication check
  FABRICATION_DEFAULT_MIN_SEG_CURVE: [
    1.655057009, 0.007233694, -0.000411132, 2.05466e-5, -4.08264e-7, 3.68176e-9,
    -1.13339e-11,
  ],
  FABRICATION_DEFAULT_MAX_ANGLE: 60,
  // TMA ROUNDED
  TMA_ROUNDED_MAX_ANGLE: 97,
  // TMA CURVE        cste, x, x^2, x^3, x^4, x^5, x^6
  TMA_MIN_SEG_CURVE: [
    1.655057009, 0.007233694, -0.000411132, 2.05466e-5, -4.08264e-7, 3.68176e-9,
    -1.13339e-11,
  ],
  // TMA ROUNDED 018
  TMA_018_ROUNDED_MAX_ANGLE: 100,
  // TMA CURVE        cste, x, x^2, x^3, x^4, x^5, x^6
  TMA_018_MIN_SEG_CURVE: [
    1.654619856, 0.000984832, 0.000116075, -1.80325e-6, 4.60378e-8,
    -5.18726e-10, 2.72863e-12,
  ],
  // TMA SQUARED
  TMA_SQUARED_MIN_SEG_CURVE: [
    1.666613509, 0.020374535, -0.001449776, 5.52691e-5, -9.97985e-7, 8.77869e-9,
    -2.85823e-11,
  ],
  TMA_SQUARED_MAX_ANGLE: 92,
  // TWISTED GOLD
  TWISTED_GOLD_MIN_SEG_CURVE: [
    1.682180431, 0.028363787, -0.002589928, 0.000126848, -2.91878e-6,
    3.14635e-8, -1.25024e-10,
  ],
  TWISTED_GOLD_ROUNDED_MAX_ANGLE: 91,
  // TWISTED STEEL
  TWISTED_STEEL_MIN_SEG_CURVE: [
    1.682180431, 0.028363787, -0.002589928, 0.000126848, -2.91878e-6,
    3.14635e-8, -1.25024e-10,
  ],
  TWISTED_STEEL_ROUNDED_MAX_ANGLE: 91,

  // Config.
  MIN_DISTANCE_2D: 2,
  MIN_DISTANCE_3D: 2,
  MIN_DISTANCE_PLANE: 6,
  PLANE_POINTS_NB: 3,

  // Snapshots.
  SNAPSHOT_WIDTH: 1920,
  SNAPSHOT_RATIO: 0.4961,
  SNAPSHOT_PADDING: 5,

  // Winnove.
  WIRE_MIN_POINTS: 3,
  DEFAULT_ALGO: 1,

  // API.
  TRACKING_API_URL:
    "https://www.ups.com/track?loc=fr_FR&tracknum=${p_trackingNumber}&requester=WT/trackdetails",
};
