import {
  FreemiumOrderStateString,
  InOfficeOrderStateString,
  OrderStateString,
  PremiumOrderStateString,
  Privilege,
} from "@winnove/vue-wlib/enums";

export interface EnumSelectEntry {
  value: number;
  text: string;
}

export class EnumHelper {
  static getNamesAndValues<T extends number>(p_e: any) {
    return EnumHelper.getNames(p_e).map((p_val) => ({
      name: p_val,
      value: p_e[p_val] as T,
    }));
  }

  static getNames(p_e: any): string[] {
    return EnumHelper._getObjValues(p_e).filter(
      (p_val) => typeof p_val === "string"
    ) as string[];
  }

  static getValues<T extends number>(p_e: any): number[] {
    return EnumHelper._getObjValues(p_e).filter(
      (p_val) => typeof p_val === "number"
    ) as T[];
  }

  static getSelectList<T extends number, U>(
    p_e: any,
    p_string: string[]
  ): Map<T, string> {
    const selectList = new Map<T, string>();
    this.getValues(p_e).forEach((p_val) =>
      selectList.set(p_val as T, p_string[p_val])
    );
    return selectList;
  }

  static getSelectListAsArray<T extends number, U>(
    p_e: any,
    p_string: string[]
  ): EnumSelectEntry[] {
    return Array.from(this.getSelectList(p_e, p_string), (p_val) => ({
      value: p_val[0] as T,
      text: p_val[1],
    }));
  }

  static getOrderStateList(p_privilege: Privilege): string[] {
    switch (p_privilege) {
      case Privilege.ADMIN:
        return OrderStateString;
      case Privilege.FREEMIUM:
        return FreemiumOrderStateString;
      case Privilege.PREMIUM:
        return PremiumOrderStateString;
      case Privilege.IN_OFFICE:
        return InOfficeOrderStateString;
      default:
        return [];
    }
  }

  private static _getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map((k) => e[k]);
  }
}
