import Task from "@/models/Task";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class TaskHelper {
  //update task
  static async updateTask(p_task: Task): Promise<void> {
    await axios
      .put("tasks/" + p_task.id, { task: p_task.serialize() })
      .then(() => {
        useRepo(Task).where("id", p_task.id).update(p_task);
      });
  }

  // add task
  static async addTask(p_task: Task): Promise<Task> {
    return await axios
      .post("tasks", { task: p_task.serialize() })
      .then(async (p_response: AxiosResponse) => {
        const taskData = p_response.data.task;
        const taskRepo = useRepo(Task);
        taskRepo.save(taskData);
        return taskRepo.find(taskData.id) as Task;
      });
  }

  // delete task
  static async deleteTask(p_task: Task): Promise<void> {
    await axios.delete("tasks/" + p_task.id).then(() => {
      const taskRepo = useRepo(Task);
      taskRepo.destroy(p_task.id);
    });
  }
}
