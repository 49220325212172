import DateHelper from "@/helpers/DateHelper";
import {
  Arcade,
  OrderState,
  Privilege,
  ProjectState,
} from "@winnove/vue-wlib/enums";
import { BooleanCast } from "pinia-orm/casts";
import Arch from "./Arch";
import BaseModel from "./BaseModel";
import Order from "./Order";
import Patient from "./Patient";
import Wire from "./Wire";
import WireHistory from "./WireHistory";

export interface ProjectCreationSettings {
  maxilla: boolean;
  mandible: boolean;
}

export default class Project extends BaseModel {
  static entity = "projects";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      patientId: this.number(null),
      reference: this.string(""),
      status: this.number(ProjectState.NEW),
      occlusion: this.boolean(false),
      installationDate: this.string(null),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      iteroId: this.string(null),
      threeShapeId: this.string(null),
      //
      patient: this.belongsTo(Patient, "patientId"),
      //
      archs: this.hasMany(Arch, "projectId"),
      //
      orders: this.hasMany(Order, "projectId"),
      //
      sortId: this.number(null),
    };
  }

  static casts() {
    return {
      occlusion: BooleanCast,
    };
  }

  declare id: number | null;
  declare patientId: number | null;
  declare reference: string;
  declare occlusion: boolean;
  declare installationDate: string;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  declare iteroId: string | null;
  declare threeShapeId: string | null;
  //
  declare patient: Patient | null;
  //
  declare archs: Arch[];
  //
  declare orders: Order[];
  //
  declare sortId?: number;

  public serialize(): {} {
    const object: Project = Object.assign(new Project(), this);
    object.patient = null;
    object.archs = [];
    object.orders = [];
    delete object.sortId;

    return object;
  }

  public archMaxilla(): Arch | null {
    return this._getArch(Arcade.MAXILLA);
  }
  public archMandible(): Arch | null {
    return this._getArch(Arcade.MANDIBLE);
  }
  public wireMaxilla(): Wire | null {
    return this.getWire(Arcade.MAXILLA);
  }
  public wireMandible(): Wire | null {
    return this.getWire(Arcade.MANDIBLE);
  }
  public wiresHistoryMaxilla(): WireHistory[] | null {
    return this.getWiresHistory(Arcade.MAXILLA);
  }
  public wiresHistoryMandible(): WireHistory[] | null {
    return this.getWiresHistory(Arcade.MANDIBLE);
  }
  public orderMaxilla(): Order | null {
    return this.getOrder(Arcade.MAXILLA);
  }
  public orderMandible(): Order | null {
    return this.getOrder(Arcade.MANDIBLE);
  }

  public getPrescription(p_arcade: Arcade): Wire | null {
    return this.getWire(p_arcade);
  }

  public getArch(p_arcade: Arcade): Arch | null {
    return this._getArch(p_arcade);
  }

  // If patient exists, returns its last name formated as XXXXXXXX
  public getPatientLastName(): string {
    if (this.patient) return this.patient.lastName.toUpperCase();
    return "";
  }

  public getPatientFirstName(): string {
    function capitalizeFirstLetter(string: string) {
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    }

    if (this.patient) return capitalizeFirstLetter(this.patient.firstName);
    return "";
  }

  public getDoctorName(): string {
    if (this.patient?.user) return this.patient.user.lastName.toUpperCase();
    return "";
  }

  public getFormatedInstallationDate(): string {
    return DateHelper.formatDate(this.installationDate);
  }

  public getFormatedCreationDate(): string {
    return DateHelper.formatDate(this.creationDate);
  }

  public setStatus(p_status: ProjectState, p_privilege: Privilege): void {
    this.status = p_status;
  }

  public isSaved(): boolean {
    return this.id != null && this.id != -1;
  }

  public isFromFreemium(): boolean {
    return this.patient!.user!.privilege == Privilege.FREEMIUM;
  }

  public canOrder(): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.canOrder();
    }
    return res;
  }

  public cancellable(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.cancellable(p_privilege);
    }
    return res;
  }

  public canChangePrescription(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.canChangePrescription(p_privilege);
    }
    res = res && this.checkIfAllOrdersAreInSameState();
    return res;
  }

  public checkIfAllOrdersAreInSameState(): boolean {
    const orderStatus: OrderState = this.orders[0].status;
    if (orderStatus !== OrderState.CANCELED) {
      for (const order of this.orders) {
        if (order.status !== OrderState.CANCELED && order.status != orderStatus)
          return false;
      }
    }
    return true;
  }

  public canPlaceOrder(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.canPlaceOrder(p_privilege);
    }
    // Check that all orders are in the same state
    res = res && this.checkIfAllOrdersAreInSameState();

    return res;
  }

  public canDrawWire(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.canDrawWire(p_privilege);
    }
    return res;
  }

  public canGenerateWireWithAI(
    p_privilege: Privilege,
    p_selectedArcade: Arcade
  ): boolean {
    if (this.getOrder(p_selectedArcade)) {
      return this.getOrder(p_selectedArcade)!.canGenerateWireWithAI(
        p_privilege
      );
    }
    return false;
  }

  public canManuallyEditWire(p_privilege: Privilege): boolean {
    return this.canDrawWire(p_privilege);
  }

  public canDrawPlane(p_privilege: Privilege): boolean {
    return this.canDrawWire(p_privilege);
  }

  public hasToDrawWire(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.hasToDrawWire(p_privilege);
    }
    return res;
  }

  public hasToDrawAllWire(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.hasToDrawWire(p_privilege);
    }
    res = res && this.checkIfAllOrdersAreInSameState();
    return res;
  }

  public hasToDrawPlane(): boolean {
    if (
      this.wireMandible()?.hasToDrawPlane() ||
      this.wireMaxilla()?.hasToDrawPlane()
    )
      return true;
    return false;
  }

  public hasToCompletePrescription(): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.hasToCompletePrescription();
    }
    // Check that all orders are in the same state
    res = res && this.checkIfAllOrdersAreInSameState();
    return res;
  }

  public anActionIsNeededFromUser(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.anActionIsNeededFromUser(p_privilege);
    }
    return res;
  }

  public hasNotBeenOrdered(p_privilege: Privilege): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.hasNotBeenOrdered(p_privilege);
    }
    return res;
  }

  public hasToConfirmWire() {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.canValidate();
    }
    return res;
  }

  // returns true if project has all required Archs depending on what was ordered
  public hasRequiredArchs(): boolean {
    const hasMandiArch: boolean = !!this.archMandible();
    const hasMaxiArch: boolean = !!this.archMaxilla();
    if (this.orderMaxilla() && hasMandiArch && hasMaxiArch) return true;
    if (this.orderMandible() && hasMandiArch) return true;

    return false;
  }

  public hasBothArchs(): boolean {
    return !!this.archMandible() && !!this.archMaxilla();
  }

  private _getArch(p_arcade: Arcade): Arch | null {
    for (const arch of this.archs) {
      if (arch.jaw === p_arcade) {
        return arch;
      }
    }
    return null;
  }

  public getWire(p_arcade: Arcade): Wire | null {
    for (const order of this.orders) {
      if (order.jaw === p_arcade) {
        return order.wire;
      }
    }
    return null;
  }

  public getWireFromId(p_id: number): Wire | null {
    for (const order of this.orders) {
      if (order.wire?.id === p_id) {
        return order.wire;
      }
    }
    return null;
  }

  public getWiresHistory(p_arcade: Arcade): WireHistory[] | null {
    for (const order of this.orders) {
      if (order.jaw === p_arcade) {
        const wiresHistorySorted: WireHistory[] | undefined =
          order.wire?.wiresHistory
            .slice()
            .sort((wireA, wireB) => Number(wireB.id) - Number(wireA.id));
        return JSON.parse(JSON.stringify(wiresHistorySorted)) || null;
      }
    }
    return null;
  }

  public getOrder(p_arcade: Arcade): Order | null {
    for (const order of this.orders) {
      if (order.jaw === p_arcade) {
        return order;
      }
    }
    return null;
  }

  public toBeCompleted(): boolean {
    let res: boolean = false;
    for (const order of this.orders) {
      res = res || order.toBeCompleted();
    }
    return res;
  }
}
