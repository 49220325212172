<template>
  <v-app>
    <v-main>
      <router-view />
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import Snackbar from "@/components/main/Snackbar.vue";
import { LoaderBus } from "@/components/shared/AppBar.vue";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { StatusCodes } from "http-status-codes";
import { onErrorCaptured } from "vue";
import { AuthHelper } from "./helpers/AuthHelper";
import Logger from "./shared/logger";

const logger = Logger.getInstance();

// Handle loader.
axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // if (!config.headers?.disableLoader)
    LoaderBus.emit("show");
    return config;
  },
  (error: any) => {
    LoaderBus.emit("hide");
    return Promise.reject(error);
  }
);
// Handle token, errors and loader.
axios.interceptors.response.use(
  (p_response: AxiosResponse) => {
    LoaderBus.emit("hide");

    if (p_response.config.responseType != "blob") {
      AuthHelper.setAuthToken(p_response.data.token);
    }

    return p_response;
  },
  (p_error: AxiosError) => {
    LoaderBus.emit("hide");
    if (p_error.response) {
      if (p_error.response.status == StatusCodes.UNAUTHORIZED) {
        AuthHelper.logout();
        return;
      }
    } else {
      logger.error(
        p_error.message,
        "Une erreur de communication est survenue. Veuillez réessayer et nous contacter si l'erreur persiste."
      );
    }

    return Promise.reject(p_error);
  }
);

onErrorCaptured((p_error: any) => {
  logger.error(
    p_error,
    "Une erreur critique est survenue. Nous sommes déjà sur le coup, mais n'hésitez pas à nous contacter si l'erreur persiste."
  );
});
</script>
