<template>
  <w-tooltip
    location="bottom"
    offset-y
  >
    <template v-slot:activator="{ props }">
      <CommentBadgeIcon
        :p_order="p_order"
        v-bind="props"
        :p_privilege="p_privilege"
      />
    </template>
    <span v-if="!m_hasMessages"> Aucun messages </span>
    <div v-else>
      <span v-if="m_unreadMessages === 0">Aucun nouveaux messages</span>
      <span v-else-if="m_unreadMessages === 1">1 nouveau message</span>
      <span v-else>{{ m_unreadMessages }} nouveaux messages</span>
    </div>
  </w-tooltip>
</template>

<script lang="ts">
import Comment from "@/models/Comment";
import Order from "@/models/Order";
import { CommentType, Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "CommentBadgeIconWithTooltip",
  props: {
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const m_hasMessages = computed(() => {
      if (props.p_privilege === Privilege.ADMIN) {
        return props.p_order.hasAnyComments();
      }
      return props.p_order.hasClientComments();
    });

    const m_unreadMessages = computed(() => {
      let m_unreadMessages = 0;
      let comments = props.p_order.comments;
      if (props.p_privilege !== Privilege.ADMIN) {
        comments = props.p_order.comments.filter(
          (comment: Comment) =>
            comment.type === CommentType.CLIENT ||
            comment.type === CommentType.REQUEST_UPDATE
        );
      }
      comments.forEach((comment) => {
        if (!comment.hasBeenReadByCurrentUser()) m_unreadMessages += 1;
      });
      return m_unreadMessages;
    });

    return {
      m_hasMessages,
      m_unreadMessages,
    };
  },
});
</script>
