<template>
  <w-card
    class="pa-0 pt-0 ma-0 mt-0 reduce-br"
    elevation="0"
  >
    <!-- show wire config -->
    <w-card-text class="px-4 pb-0 pt-2">
      <p class="text-caption">
        Décrivez les modifications à apporter à ce fil, puis cliquez sur
        "Envoyer".
      </p>
      <w-textarea
        v-model="m_updateComment"
        data-cy="modification-comment-area"
        class="commentTextArea mt-2"
        auto-grow
        rows="2"
        row-height="10"
        :label="
          p_privilege === Privilege.ADMIN
            ? 'Modifications Client Demandées'
            : 'Modifications Demandées'
        "
        color="white"
        :hide-details="true"
      ></w-textarea>
    </w-card-text>

    <w-card-actions class="d-flex justify-end">
      <!-- Cancel button activator -->
      <w-btn
        data-cy="change-decision-button"
        color="error"
        size="small"
        @click="$emit('cancel')"
      >
        Changer de décision
      </w-btn>

      <!-- Submit validation -->
      <w-btn
        data-cy="submit-validation-button"
        color="white"
        size="small"
        @click="$emit('submit', m_updateComment)"
      >
        Envoyer
      </w-btn>
    </w-card-actions>
  </w-card>
</template>

<script lang="ts">
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "WireEditRequestCard",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const m_updateComment = ref("");

    return {
      Privilege: Privilege,
      m_updateComment,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.reduce-br {
  border-radius: 5px;
}
.project-info-tabs {
  img {
    background-color: white !important;
    border-radius: 12px;
  }
}
</style>
