import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";
import Patient from "@/models/Patient";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";
import WinnoveHelper, { DataTableOptions } from "./WinnoveHelper";

export default class PatientHelper {
  static async addPatient(p_patient: Patient): Promise<Patient> {
    return await axios
      .post("patients", { patient: p_patient.serialize() })
      .then((p_response: AxiosResponse) => {
        const patient = p_response.data.patient;
        const patientRepo = useRepo(Patient);
        patientRepo.save(patient);
        return patient;
      });
  }

  static async updatePatient(p_patient: Patient): Promise<void> {
    // Update patient.
    await axios
      .put("patients/" + p_patient.id, { patient: p_patient.serialize() })
      .then((p_response: AxiosResponse) => {
        useRepo(Patient).where("id", p_patient.id).update(p_patient);
      });
  }

  static async fetchPatients(
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<number> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    return await axios
      .get("patients/", {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then((p_response: AxiosResponse) => {
        const patients = p_response.data.patients;
        const patientRepo = useRepo(Patient);
        patientRepo.fresh(patients);
        const count: number = p_response.data.count;
        return count;
      });
  }
}
