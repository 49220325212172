import { Dimension, Ending, Material, WireType } from "@winnove/vue-wlib/enums";
import { Vector3 } from "three";
import { Constants } from "../Constants";
import BaseModel from "./BaseModel";
import Order from "./Order";
import WireHistory from "./WireHistory";

export default class Wire extends BaseModel {
  static entity = "wires";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      basePoints: this.string(""),
      planePoints: this.string(""),
      wirePoints: this.string(""),
      nbPoints: this.number(0),
      teethRange: this.string("-"),
      wireBundle: this.string(""),
      wireType: this.number(WireType.ROUNDED),
      material: this.number(Material.TMA),
      dimension: this.number(Dimension._016),
      ending: this.number(Ending.NONE),
      keyNeeded: this.number(1),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      drawDuration: this.number(0),
      order: this.hasOne(Order, "wireId"),
      wiresHistory: this.hasMany(WireHistory, "wireId"),
    };
  }

  // static casts() {
  //   return {
  //     keyNeeded: BooleanCast,
  //   };
  // }

  declare id: number | null;
  declare basePoints: string;
  declare planePoints: string;
  declare wirePoints: string;
  declare nbPoints: number;
  declare teethRange: string;
  declare wireBundle: string;
  declare wireType: WireType;
  declare material: Material;
  declare dimension: Dimension;
  declare ending: Ending;
  declare keyNeeded: number;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  declare drawDuration: number;
  //
  declare order: Order | null;
  declare wiresHistory: WireHistory[];

  public serialize(): {} {
    const object: Wire = Object.assign(new Wire(), this);
    object.order = null;
    object.wiresHistory = [];

    return object;
  }

  public getMinDistance(): number {
    return this.wireType === WireType.SQUARED
      ? Constants.MIN_DISTANCE_2D
      : Constants.MIN_DISTANCE_3D;
  }

  public hasEnoughPointToAssemble(): boolean {
    return this.nbPoints >= Constants.WIRE_MIN_POINTS;
  }

  private _parsePoints(p_points: string): Vector3[] {
    const result: Vector3[] = [];
    JSON.parse(p_points).forEach((p_point: any) => {
      result.push(new Vector3(p_point.x, p_point.y, p_point.z));
    });
    return result;
  }

  public getWirePoints(): Vector3[] {
    return this._parsePoints(this.wirePoints);
  }

  public getPlanePoints(): Vector3[] {
    return this._parsePoints(this.planePoints);
  }

  public getBasePoints(): Vector3[] {
    return this._parsePoints(this.basePoints);
  }

  public hasToDrawPlane(): boolean {
    return this.wireType === WireType.SQUARED;
  }

  private _hasWiresHistory(): boolean {
    return this.wiresHistory.length > 0;
  }

  private _getWiresHistory(): WireHistory[] {
    return this.wiresHistory;
  }

  //Un plan est valide s'il a exactement 3 Points.
  //Créer un plan pour un fil rond n'est pas nécessaire mais si l'utilisateur le commence il doit le finir.
  public isPlaneValid(pointsNb: number): boolean {
    if (
      (this.wireType === WireType.SQUARED &&
        pointsNb != Constants.PLANE_POINTS_NB) ||
      (this.wireType === WireType.ROUNDED &&
        pointsNb > 0 &&
        pointsNb < Constants.PLANE_POINTS_NB)
    )
      return false;
    return true;
  }
}
