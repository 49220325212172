import Comment from "@/models/Comment";
import Order from "@/models/Order";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class CommentHelper {
  //update comment
  static async updateComment(p_comment: Comment): Promise<void> {
    await axios
      .put("comments/" + p_comment.id, { comment: p_comment.serialize() })
      .then(async () => {
        p_comment.initialContent = p_comment.content;
        useRepo(Comment).where("id", p_comment.id).update(p_comment);
      });
  }

  static async uploadCommentHasDrawnImage(
    p_file: File | null,
    p_commentId: number
  ): Promise<void> {
    const formData = new FormData();
    formData.set("image", p_file!);
    await axios.post("comments/" + p_commentId + "/drawn-image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const comment = useRepo(Comment).find(p_commentId) as Comment;
    comment.drawnImage = true;
    useRepo(Comment).where("id", p_commentId).update(comment);
  }

  static async getDrawnImage(p_commentId: number): Promise<string> {
    const result = await axios
      .get(`comments/${p_commentId}/drawn-image`, {
        responseType: "blob",
        headers: { disableLoader: true },
      })
      .then((p_response: AxiosResponse) => {
        // get attached file and display it on the modificationImage
        if (p_response.data) {
          const blob = new Blob([p_response.data], {
            type: "image/png",
          });
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          return imageUrl;
        }
      });
    return result || "";
  }
  // add comment
  static async addComment(p_comment: Comment): Promise<Comment> {
    return await axios
      .post("comments", { comment: p_comment.serialize() })
      .then(async (p_response: AxiosResponse) => {
        // save comment in pinia-orm
        const commentData = p_response.data.comment;
        commentData.initialContent = commentData.content;
        commentData.creationDate = new Date().toISOString();
        useRepo(Comment).save(commentData);
        // remove initial comment from pini-orm
        useRepo(Comment).destroy(p_comment.id!);
        // return new comment
        return useRepo(Comment).find(commentData.id) as Comment;
      });
  }

  static async deleteComment(p_comment: Comment): Promise<void> {
    await axios.delete("comments/" + p_comment.id).then(async () => {
      useRepo(Comment).destroy(p_comment.id!);
    });
  }

  //send read notification comment
  static async readComments(
    p_orderId: number,
    p_comments: Comment[],
    p_removeProcess: boolean = false
  ): Promise<void> {
    const unreadComments = p_comments.filter(
      (comment) => !comment.hasBeenReadByCurrentUser()
    );
    const commentIds = unreadComments.map((comment) => comment.id);
    await axios
      .put(`orders/${p_orderId}/read`, { commentIds: commentIds })
      .then(async (res) => {
        const newComments = res.data.newComments;
        for (const comment of newComments) {
          // Pinia Cast isnt working so we need to cast the boolean values (it kinda works, but give warnings)
          comment.readByAccountManager = !!comment.readByAccountManager;
          comment.readByClient = !!comment.readByClient;
          comment.readByProduction = !!comment.readByProduction;
          comment.drawnImage = !!comment.drawnImage;
          useRepo(Comment)
            .where("id", comment.id)
            .update(comment as Comment);
        }
        const process = res.data.process;
        if (process && p_removeProcess) {
          useRepo(Order).destroy(process.orderId);
        }
      });
  }
}
