<template>
  <base-dialog
    ref="pdfDialog"
    p_title="Attestation PDF"
    p_noPadding
  >
    <w-card-text>
      <w-file-input
        v-model="m_file_qa"
        accept=".png"
        label="QA"
        show-size
      ></w-file-input>
    </w-card-text>

    <w-card-text>
      <w-file-input
        v-model="m_file_scale"
        accept=".png"
        label="Scale"
        show-size
      ></w-file-input>
    </w-card-text>

    <w-card-text>
      <w-file-input
        v-model="m_file_snapshot"
        accept=".png"
        label="Snapshot (optionnal)"
        show-size
      ></w-file-input>
    </w-card-text>

    <w-card-text>
      <w-text-field
        v-model="m_bundle"
        label="Lot"
        maxlength="10"
      ></w-text-field>
    </w-card-text>

    <w-card-actions class="justify-end mt-2">
      <w-btn
        color="primary"
        @click="closeDialog()"
        variant="tonal"
      >
        Annuler
      </w-btn>
      <w-btn
        :disabled="
          m_bundle &&
          ((!m_file_qa && !m_file_qa_existing) ||
            (!m_file_scale && !m_file_scale_existing))
        "
        @click="generateAndDownloadPDF()"
        :loading="m_pdfLoading"
      >
        Générer
      </w-btn>
      <w-btn
        v-if="p_wire.order.pdf"
        @click="downloadAndClose()"
        :loading="m_pdfLoading"
      >
        Télécharger le PDF existant
      </w-btn>
    </w-card-actions>
  </base-dialog>
</template>

<script lang="ts">
import WireHelper from "@/helpers/WireHelper";
import Wire from "@/models/Wire";
import { AlgoVersion, Privilege } from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { StatusCodes } from "http-status-codes";
import { defineComponent, ref } from "vue";
import BaseDialog from "./BaseDialog.vue";

export default defineComponent({
  name: "PDFDownloadButton",
  props: {
    p_wire: {
      type: Object as () => Wire,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const pdfDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_file_qa_existing = ref(false);
    const m_file_qa = ref<File[]>([]);
    const m_file_scale_existing = ref(false);
    const m_file_scale = ref<File[]>([]);
    const m_file_snapshot = ref<File[]>([]);
    const m_bundle = ref(props.p_wire.wireBundle);
    const m_pdfLoading = ref(false);

    async function checkImages(): Promise<void> {
      m_bundle.value = props.p_wire.wireBundle;
      await axios
        .get("wires/" + props.p_wire.id + "/qa/check")
        .then((p_response: AxiosResponse) => {
          const status = p_response.status;
          if (status == StatusCodes.OK) {
            m_file_qa_existing.value = p_response.data.file_exists;
          }
        });
      await axios
        .get("wires/" + props.p_wire.id + "/scale/check")
        .then((p_response: AxiosResponse) => {
          const status = p_response.status;
          if (status == StatusCodes.OK) {
            m_file_scale_existing.value = p_response.data.file_exists;
          }
        });
    }

    function download(): void {
      if (props.p_privilege === Privilege.ADMIN) openPDFDialog();
      else downloadExistingPDF();
    }
    async function downloadExistingPDF(): Promise<void> {
      m_pdfLoading.value = true;
      // Download the pdf
      await axios
        .get("wires/" + props.p_wire.id + "/generated_pdf", {
          responseType: "blob",
        })
        .then((p_response: AxiosResponse) => {
          let fileName: string = props.p_wire.order!.getReference() + ".pdf";
          const file: File = new File([p_response.data], fileName);
          saveAs(file, fileName);
        });

      m_pdfLoading.value = false;
    }

    async function openPDFDialog() {
      await checkImages();
      pdfDialog.value?.show();
    }

    async function generateAndDownloadPDF(): Promise<void> {
      m_pdfLoading.value = true;

      // Upload snapshot if exists.
      if (m_file_snapshot.value[0]) {
        let formDataSnapshot = new FormData();
        formDataSnapshot.set("snapshot", m_file_snapshot.value[0]);
        await axios.post(
          "wires/" + props.p_wire.id + "/snapshot",
          formDataSnapshot,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }

      // Upload qa.
      if (m_file_qa.value[0]) {
        let formDataQa = new FormData();
        formDataQa.set("qa", m_file_qa.value[0]);
        await axios.post("wires/" + props.p_wire.id + "/qa", formDataQa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      // Upload scale.
      if (m_file_scale.value[0]) {
        let formDataScale = new FormData();
        formDataScale.set("scale", m_file_scale.value[0]);
        await axios.post("wires/" + props.p_wire.id + "/scale", formDataScale, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      // Update the wire bundle.
      const wire = props.p_wire;
      wire.wireBundle = m_bundle.value;
      await WireHelper.updateWire(wire, AlgoVersion.DEFAULT);

      // Generate and Download the pdf
      await axios
        .get("wires/" + props.p_wire.id + "/pdf", {
          responseType: "blob",
        })
        .then((p_response: AxiosResponse) => {
          let fileName: string = props.p_wire.order!.getReference() + ".pdf";
          const file: File = new File([p_response.data], fileName);
          saveAs(file, fileName);
        });

      context.emit("refresh-wire");
      m_pdfLoading.value = false;
      closeDialog();
    }

    async function downloadAndClose(): Promise<void> {
      await downloadExistingPDF();
      closeDialog();
    }

    function closeDialog(): void {
      pdfDialog.value?.close();
    }

    return {
      m_file_qa_existing,
      m_file_qa,
      m_file_scale_existing,
      m_file_scale,
      m_file_snapshot,
      m_bundle,
      pdfDialog,
      m_pdfLoading,
      checkImages,
      download,
      downloadExistingPDF,
      generateAndDownloadPDF,
      downloadAndClose,
      closeDialog,
    };
  },
  components: { BaseDialog },
});
</script>
