<template>
  <div
    v-if="canDownload"
    style="width: 100%"
  >
    <w-list-item
      @click="$emit('download')"
      density="compact"
      base-color="primary"
      :append-icon="mdiDownload"
    >
      PDF
    </w-list-item>
  </div>
</template>

<script lang="ts">
import Wire from "@/models/Wire";
import { mdiDownload } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "PDFDownloadButton",
  props: {
    p_wire: {
      type: Object as () => Wire,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    const canDownload = computed(() => {
      return props.p_wire.order!.canDownloadPdf(props.p_privilege);
    });

    return {
      canDownload,
      mdiDownload,
    };
  },
});
</script>
