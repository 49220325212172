<template>
  <a
    :href="m_modificationImageLink"
    target="_blank"
    style="position: relative"
    class="hover-link"
  >
    <w-badge
      class="mt-2 modification-image-div d-flex justify-center align-center"
      overlap
      :icon="mdiOpenInNew"
      color="wDarkGrey"
    >
      <img
        data-cy="modification-image"
        :src="m_modificationImageLink"
        class="modification-image"
        alt="Modification"
      />
    </w-badge>
  </a>
</template>

<script lang="ts">
import CommentHelper from "@/helpers/CommentHelper";
import Comment from "@/models/Comment";
import { mdiOpenInNew } from "@mdi/js";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "ConversationImageLink",
  props: {
    p_comment: {
      type: Comment,
      required: true,
    },
  },
  setup(props) {
    const m_modificationImageLink = ref("#");

    onMounted(() => {
      fecthCommentImage();
    });

    async function fecthCommentImage() {
      CommentHelper.getDrawnImage(props.p_comment.id as number).then(
        (response: string) => {
          m_modificationImageLink.value = response;
        }
      );
    }

    return {
      mdiOpenInNew,
      m_modificationImageLink,
    };
  },
});
</script>
<style lang="scss">
.modification-image-div {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: 80px;
  width: 80px;
}

.modification-image {
  max-width: 78px;
  max-height: 78px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.hover-link:hover .v-badge__badge {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}

.hover-link .v-badge__badge {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
</style>
