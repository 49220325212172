<template>
  <div
    v-if="m_visible"
    class="d-flex justify-center align-center draw_image_div"
  >
    <div style="position: relative; width: 100%; height: 100%">
      <ImageDrawingToolbar
        class="drawing_toolbar"
        ref="drawingToolbar"
        :p_canCtrlZ="canCtrlZ"
        :p_canCtrlY="canCtrlY"
        :p_selectedColor="selectedColor"
        @ctrl-z="undo()"
        @ctrl-y="redo()"
        @selectColor="selectColor"
      >
      </ImageDrawingToolbar>
      <Transition
        name="draw-img-fade"
        appear
      >
        <canvas
          v-if="showImage"
          data-cy="drawing-canvas"
          id="drawing_canvas"
          ref="drawingCanvas"
          class="canvas-image"
        ></canvas>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import Logger from "@/shared/logger";
import { computed, defineComponent, nextTick, ref } from "vue";
import ImageDrawingToolbar from "./ImageDrawingToolbar.vue";

interface DrawingPath {
  points: { x: number; y: number }[]; // coordinates relative to the center of the image
  lineWidth: number;
  lineCap: "butt" | "round" | "square";
  strokeStyle: string;
}

export default defineComponent({
  name: "ImageDrawing",
  components: { ImageDrawingToolbar },
  setup(props, context) {
    // variables
    const logger = Logger.getInstance();
    const m_visible = ref(false);
    const snapshotImage = ref(new Image());
    const isDrawing = ref(false);
    const showImage = ref(false);
    const selectedColor = ref("red");
    const drawingToolbar = ref<InstanceType<typeof ImageDrawingToolbar> | null>(
      null
    );
    const drawingCanvas = ref<InstanceType<typeof HTMLCanvasElement> | null>(
      null
    );
    const drawingContext = ref<CanvasRenderingContext2D | null>(null);

    const drawingHistory = ref<Array<DrawingPath>>([]);

    const redoStack = ref<Array<DrawingPath>>([]);

    // computed refs

    const canCtrlZ = computed(() => {
      return drawingHistory.value && drawingHistory.value.length > 0;
    });

    const canCtrlY = computed(() => {
      return redoStack.value && redoStack.value.length > 0;
    });

    // drawing functions

    function getEventRelativeCoordinates(e: MouseEvent | TouchEvent): {
      posX: number;
      posY: number;
    } {
      let posX = 0;
      let posY = 0;
      if (e instanceof MouseEvent) {
        posX = e.offsetX;
        posY = e.offsetY;
      } else if (e instanceof TouchEvent) {
        const rect = drawingCanvas.value!.getBoundingClientRect();
        const touch = e.touches[0];
        posX = touch.clientX - rect.left;
        posY = touch.clientY - rect.top;
      }
      return { posX, posY };
    }

    function getImageCenterCoordinates(): { cX: number; cY: number } {
      const canvasWidth = drawingCanvas.value!.clientWidth;
      const canvasHeight = drawingCanvas.value!.clientHeight;
      const cX = canvasWidth / 2;
      const cY = canvasHeight / 2;
      return { cX, cY };
    }

    function startDrawing(e: MouseEvent | TouchEvent): void {
      if (e instanceof MouseEvent && e.button !== 0) {
        // If it's not a left click, return early and don't start drawing
        return;
      }
      const { posX, posY } = getEventRelativeCoordinates(e);
      const { cX, cY } = getImageCenterCoordinates();
      drawingContext.value!.lineWidth = 5;
      drawingContext.value!.lineCap = "round";
      drawingContext.value!.strokeStyle = selectedColor.value;

      drawingHistory.value.push({
        points: [{ x: posX - cX, y: posY - cY }],
        lineWidth: drawingContext.value!.lineWidth,
        lineCap: drawingContext.value!.lineCap,
        strokeStyle: drawingContext.value!.strokeStyle,
      });

      isDrawing.value = true;
      drawingContext.value!.moveTo(posX, posY);
      draw(e);
    }

    function endDrawing(): void {
      isDrawing.value = false;
    }

    function draw(e: MouseEvent | TouchEvent): void {
      if (!isDrawing.value) return;
      const { posX, posY } = getEventRelativeCoordinates(e);
      const { cX, cY } = getImageCenterCoordinates();
      const lastPath = drawingHistory.value[drawingHistory.value.length - 1];
      lastPath.points.push({ x: posX - cX, y: posY - cY });

      drawingContext.value!.lineTo(posX, posY);
      drawingContext.value!.stroke();
      drawingContext.value!.beginPath();
      drawingContext.value!.moveTo(posX, posY);
    }

    function redrawFromHistory(): void {
      // clear the canvas
      drawingContext.value!.clearRect(
        0,
        0,
        drawingCanvas.value!.width,
        drawingCanvas.value!.height
      );
      // draw the background image
      addImageToCanvas(snapshotImage.value);
      // redraw the history
      const { cX, cY } = getImageCenterCoordinates();
      drawingContext.value!.closePath();
      drawingHistory.value.forEach((path) => {
        // set the drawing context
        drawingContext.value!.lineWidth = path.lineWidth;
        drawingContext.value!.lineCap = path.lineCap;
        drawingContext.value!.strokeStyle = path.strokeStyle;
        // draw the path
        drawingContext.value!.beginPath();
        path.points.forEach((point) => {
          drawingContext.value!.lineTo(point.x + cX, point.y + cY);
          drawingContext.value!.stroke();
          drawingContext.value!.beginPath();
          drawingContext.value!.moveTo(point.x + cX, point.y + cY);
        });
      });
      drawingContext.value!.strokeStyle = selectedColor.value;
    }

    async function loadImage(imageString: string) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
          snapshotImage.value = image;
          resolve(image);
        };
        image.onerror = (error) => {
          logger.error(String(error));
          reject(error);
        };

        image.src = imageString;
      });
    }

    function addImageToCanvas(image: HTMLImageElement) {
      drawingContext.value!.drawImage(
        image,
        (drawingCanvas.value!.clientWidth - image.width) / 2,
        (drawingCanvas.value!.clientHeight - image.height) / 2,
        image.width,
        image.height
      );
    }

    function show(p_imageData: any): void {
      m_visible.value = true;

      // clear the canvas history
      clearHistory();

      // compute the size of the image
      loadImage(p_imageData).then((image) => {
        // show the image - trigger the transition
        showImage.value = true;

        // show the toolbar
        drawingToolbar.value?.show();

        window.addEventListener("resize", resizeCanvas);

        // Wait for the next tick to ensure that the DOM is ready
        nextTick(() => {
          // listen to mouse events
          drawingCanvas.value!.addEventListener("mousedown", startDrawing);
          drawingCanvas.value!.addEventListener("mouseup", endDrawing);
          drawingCanvas.value!.addEventListener("mousemove", draw);
          drawingCanvas.value!.addEventListener("mouseleave", endDrawing);
          // listen to touch events while preventing the default behavior
          drawingCanvas.value!.addEventListener(
            "touchstart",
            (e) => {
              e.preventDefault();
              startDrawing(e);
            },
            { passive: false }
          );
          drawingCanvas.value!.addEventListener(
            "touchend",
            (e) => {
              e.preventDefault();
              endDrawing();
            },
            { passive: false }
          );
          drawingCanvas.value!.addEventListener(
            "touchmove",
            (e) => {
              e.preventDefault();
              draw(e);
            },
            { passive: false }
          );

          drawingContext.value = drawingCanvas.value!.getContext("2d");

          // dirty, but it looks like somethink in v-img is changing the size of the element after the nextTick
          // setTimeout(() => {
          const canvaWidth = drawingCanvas.value!.clientWidth;
          const canvaHeight = drawingCanvas.value!.clientHeight;

          drawingCanvas.value!.width = canvaWidth;
          drawingCanvas.value!.height = canvaHeight;
          redrawFromHistory();
          // }, 250);
        });
      });
    }

    function hide(): void {
      drawingCanvas.value?.removeEventListener("mousedown", startDrawing);
      drawingCanvas.value?.removeEventListener("mouseup", endDrawing);
      drawingCanvas.value?.removeEventListener("mousemove", draw);
      drawingCanvas.value?.removeEventListener("mouseleave", endDrawing);
      drawingCanvas.value?.removeEventListener("touchstart", startDrawing);
      drawingCanvas.value?.removeEventListener("touchend", endDrawing);
      drawingCanvas.value?.removeEventListener("touchmove", draw);

      window.removeEventListener("resize", resizeCanvas);

      showImage.value = false;
      // hide the toolbar
      drawingToolbar.value?.hide();

      m_visible.value = false;
    }

    function clearHistory(): void {
      drawingHistory.value.splice(0, drawingHistory.value.length);
      redoStack.value.splice(0, redoStack.value.length);
    }

    function resizeCanvas() {
      if (!drawingCanvas.value) return;

      const canvaWidth = drawingCanvas.value.clientWidth;
      const canvaHeight = drawingCanvas.value.clientHeight;

      drawingCanvas.value.width = canvaWidth;
      drawingCanvas.value.height = canvaHeight;

      redrawFromHistory();
    }

    function undo(): void {
      if (!drawingHistory.value || drawingHistory.value.length === 0) return;
      const last = drawingHistory.value.pop();
      if (last) {
        redoStack.value.push(last);
        redrawFromHistory();
      }
    }

    function redo(): void {
      if (!redoStack.value || redoStack.value.length === 0) return;
      const last = redoStack.value.pop();
      if (last) {
        drawingHistory.value.push(last);
        redrawFromHistory();
      }
    }

    function selectColor(p_color: string): void {
      selectedColor.value = p_color;
    }

    async function renderImage(): Promise<File | null> {
      // Check if the drawingCanvas exists
      if (!drawingCanvas.value) {
        return Promise.resolve(null);
      }

      return new Promise<File | null>((resolve) => {
        drawingCanvas.value!.toBlob((blob) => {
          if (blob) {
            // Create a File from the Blob
            const file = new File([blob], "image.png", { type: "image/png" });
            resolve(file);
          } else {
            resolve(null);
          }
        }, "image/png");
      });
    }

    function hasUserDrawn(): boolean {
      return drawingHistory.value.length > 0;
    }

    return {
      m_visible,
      drawingCanvas,
      canCtrlZ,
      canCtrlY,
      showImage,
      selectedColor,
      show,
      hide,
      undo,
      redo,
      selectColor,
      clearHistory,
      renderImage,
      hasUserDrawn,
    };
  },
});
</script>

<style lang="scss">
// center the image
.drawing_toolbar {
  position: absolute;
  z-index: 100;
  right: 10px;
  @media screen and (max-width: 960px) {
    bottom: 10px;
  }
  @media screen and (min-width: 960px) {
    top: 10px;
  }
}
.canvas-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
}

.draw-img-fade-enter-active,
.draw-img-fade-leave-active {
  transition: opacity 0.25s ease;
}

.draw-img-fade-enter,
.draw-img-fade-leave-to {
  opacity: 0;
}

.draw_image_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: white;
  overflow: hidden;
}
</style>
