import Cookies from "js-cookie";
import FilterValue from "./FilterValue";

export default class PaginationFilter {
  filterValues: Array<FilterValue>;
  name: string;
  dbQuery: string;

  constructor(name: string, dbQuery: string, filterValues: Array<FilterValue>) {
    this.filterValues = filterValues;
    this.name = name;
    this.dbQuery = dbQuery;
  }

  private _getCheckedFilters(): Array<FilterValue> {
    return this.filterValues.filter((filter) => filter.isChecked);
  }

  public getCheckedFilterValues(): Array<number> {
    const checkedFilters = this._getCheckedFilters();
    let checkedValues: Array<number> = [];
    checkedFilters.forEach(
      (filter) => (checkedValues = checkedValues.concat(filter.value))
    );
    return checkedValues;
  }

  storeValInCookies(): void {
    Cookies.set(this.name, JSON.stringify(this.filterValues));
  }

  private _getValFromCookies(): Array<FilterValue> | null {
    const storedValues = Cookies.get(this.name);
    if (storedValues) {
      return JSON.parse(storedValues);
    }
    return null;
  }

  updateFilterFromCookies(): void {
    const storedValues = this._getValFromCookies();
    if (storedValues) {
      this.filterValues.forEach((val) => {
        for (const element of storedValues) {
          if (element.name === val.name) {
            val.isChecked = element.isChecked;
            break;
          }
        }
      });
    }
  }

  areAllValuesChecked(): boolean {
    return this.filterValues.every((filter) => filter.isChecked);
  }

  getCheckedValuesLabel(): string {
    return this.areAllValuesChecked()
      ? "tous"
      : this.filterValues
          .filter((val) => val.isChecked)
          .map((val) => val.name)
          .join(", ");
  }

  getSortedValues(): Array<FilterValue> {
    return this.filterValues.sort((val1, val2) =>
      val1.name < val2.name ? -1 : 1
    );
  }

  resetValues(): void {
    this.checkAll();
  }

  uncheckAll(): void {
    this.filterValues.forEach((val) => (val.isChecked = false));
  }

  checkAll(): void {
    this.filterValues.forEach((val) => (val.isChecked = true));
  }

  checkByValues(vals: Array<any>): void {
    // check all filterValues that have at least one value in vals
    this.filterValues.forEach(
      (val) => (val.isChecked = val.value.some((v) => vals.includes(v)))
    );
  }

  uncheckByValues(vals: Array<any>): void {
    // uncheck all filterValues that have at least one value in vals
    this.filterValues.forEach(
      (val) => (val.isChecked = !val.value.some((v) => vals.includes(v)))
    );
  }
}
