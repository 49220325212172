import Arch from "@/models/Arch";
import Order from "@/models/Order";
import Logger from "@/shared/logger";
import axios, { AxiosResponse } from "axios";
import saveAs from "file-saver";
import { useRepo } from "pinia-orm";

export default class ArchHelper {
  // update arch
  static async updateArch(p_arch: Arch): Promise<void> {
    await axios
      .put("archs/" + p_arch.id, { arch: p_arch.serialize() })
      .then(async () => {
        useRepo(Arch).where("id", p_arch.id).update(p_arch);
      });
  }

  static async download3Dfile(p_order: Order) {
    const arch = p_order.project?.getArch(p_order.jaw);
    if (!arch) {
      Logger.getInstance().error(
        "Impossible de récupérer le fichier pour cette commande "
      );
      return;
    }

    await axios
      .get("archs/" + arch.id + "/stlI3D", {
        responseType: "blob",
      })
      .then((p_response: AxiosResponse) => {
        const file: File = new File([p_response.data], arch.fileName);
        saveAs(file, arch.getReference() + "_I3D.stl");
      })
      .catch((error) => {
        Logger.getInstance().error(
          error,
          "Impossible de récupérer le fichier pour cette commande"
        );
      });
  }

  static async downloadI3DZip(p_orders: Order[]) {
    const archs: Arch[] = [];

    p_orders.forEach((order) => {
      const arch: Arch | null | undefined = order.project?.getArch(order.jaw);
      if (!arch) {
        Logger.getInstance().error(
          "Impossible de récupérer le fichier pour cette commande "
        );
      } else {
        archs.push(arch);
      }
    });
    await axios
      .post(
        "archs/zipStlI3D",
        { archs },
        {
          responseType: "blob",
        }
      )
      .then((p_response: AxiosResponse) => {
        const file: File = new File([p_response.data], "STL_I3D.zip");
        saveAs(file, Date.now() + "_STL_I3D.zip");
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          Logger.getInstance().warning(
            error,
            "Un des fichiers d'impression 3D n'est pas encore généré"
          );
        } else {
          Logger.getInstance().error(
            error,
            "Impossible de télécharger le fichier compressé"
          );
        }
      });
  }
}
