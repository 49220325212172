<template>
  <w-card class="h-100 w-100 ma-3 bg-white">
    <w-card-title>
      <w-select
        v-model="m_date"
        data-cy="select-day-remaining-tasks"
        :items="m_date_options"
        outlined
        :label="m_currentDate"
        color="white"
        class="custom-select ma-2"
        @update:modelValue="refreshPiesData"
        hide-details
      />
    </w-card-title>
    <w-row class="custom-height w-100 ma-0">
      <w-col class="mb-6 d-flex flex-column justify-center align-center max100">
        <w-pie
          :data="m_drawData"
          :options="m_options"
          :p_loading="m_loading"
          :p_empty="m_drawEmpty"
        />
        <h3 class="mt-3">
          Dessin
          <w-btn
            rounded
            size="small"
            @click="goToDrawing()"
            variant="text"
          >
            <w-icon :icon="mdiOpenInNew" />
            <w-tooltip
              activator="parent"
              location="bottom"
            >
              <span>Aller aux dessins</span>
            </w-tooltip>
          </w-btn>
        </h3>
      </w-col>
      <w-col class="mb-6 d-flex flex-column justify-center align-center max100">
        <w-pie
          :data="m_makeData"
          :options="m_options"
          :p_loading="m_loading"
          :p_empty="m_makeEmpty"
        />
        <h3 class="mt-3">
          Fabrication
          <w-btn
            rounded
            size="small"
            @click="goToMake()"
            variant="text"
          >
            <w-icon :icon="mdiOpenInNew" />
            <w-tooltip
              activator="parent"
              location="bottom"
            >
              <span>Aller à la fabrication</span>
            </w-tooltip>
          </w-btn>
        </h3>
      </w-col>
      <w-col class="mb-6 d-flex flex-column justify-center align-center max100">
        <w-pie
          :data="m_shippingData"
          :options="m_options"
          :p_loading="m_loading"
          :p_empty="m_shippingEmpty"
        />
        <h3 class="mt-3">
          Expédition
          <w-btn
            rounded
            size="small"
            @click="goToShipping()"
            variant="text"
          >
            <w-icon :icon="mdiOpenInNew" />
            <w-tooltip
              activator="parent"
              location="bottom"
            >
              <span>Aller aux expéditions</span>
            </w-tooltip>
          </w-btn>
        </h3>
      </w-col>
    </w-row>
    <w-btn
      class="go-to-bottom"
      size="small"
      variant="tonal"
      rounded
      @click="goTo('#secondary-stats', {})"
      data-cy="go-to-bottom-stats"
    >
      <w-icon :icon="mdiChevronDown" />
      <w-tooltip
        activator="parent"
        location="top"
      >
        <span>Aller aux statistiques secondaires</span>
      </w-tooltip>
    </w-btn>
  </w-card>
</template>

<script lang="ts">
import DateHelper from "@/helpers/DateHelper";
import router, {
  ROUTE_PRODUCTIONS_DRAW,
  ROUTE_PRODUCTIONS_SHIP,
  ROUTE_PRODUCTIONS_TOMAKE,
} from "@/router";
import { mdiChevronDown, mdiOpenInNew } from "@mdi/js";
import {
  PROD_STAT_PIE_1,
  PROD_STAT_PIE_2,
  PROD_STAT_PIE_3,
} from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useGoTo } from "vuetify";

enum DATE_OPTIONS {
  J0 = "Aujourd'hui",
  J1 = "J+1",
  J2 = "J+2",
}

export default defineComponent({
  name: "RemainingTasksPerDayPies",
  components: {},
  props: {
    p_refreshInterval: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const m_date_options = ref([
      DATE_OPTIONS.J0,
      DATE_OPTIONS.J1,
      DATE_OPTIONS.J2,
    ]);
    const m_date = ref(DATE_OPTIONS.J0);
    const m_currentDate = ref();

    let m_loading = ref(true);
    const m_backgroundColor = ref([
      PROD_STAT_PIE_1,
      PROD_STAT_PIE_2,
      PROD_STAT_PIE_3,
    ]);
    const m_drawData = ref();
    const m_drawEmpty = ref(false);
    const m_makeData = ref();
    const m_makeEmpty = ref(false);
    const m_shippingData = ref();
    const m_shippingEmpty = ref(false);
    const m_interval = ref();
    const goTo = useGoTo();

    const m_options = ref({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        datalabels: {
          color: "white",
          anchor: "center",
          align: "center",
          formatter: formatterNotZero,
          font: {
            weight: "bold",
            size: 20,
          },
        },
      },
    });

    function formatterNotZero(value: number) {
      return value == 0 ? "" : value;
    }

    onMounted(() => {
      // Verify if past 16:30, then default date is J+1
      let dateAt4h30 = new Date();
      dateAt4h30.setHours(16, 30, 0, 0);
      if (new Date() > dateAt4h30) m_date.value = DATE_OPTIONS.J1;

      refreshPiesData();
      m_interval.value = setInterval(refreshPiesData, props.p_refreshInterval);
    });

    onUnmounted(() => {
      clearInterval(m_interval.value);
    });

    async function refreshPiesData(): Promise<void> {
      m_loading.value = true;
      m_drawEmpty.value = false;
      m_makeEmpty.value = false;
      m_shippingEmpty.value = false;
      let date = new Date();
      let offset = 0;
      if (m_date.value == DATE_OPTIONS.J1) offset = 1;
      if (m_date.value == DATE_OPTIONS.J2) offset = 2;
      date = new Date(DateHelper.getNextBusinessDay(offset));
      m_currentDate.value = moment.utc(date).format("DD/MM/YYYY");

      await axios
        .get(
          `stat_production_process/${date.toISOString().slice(0, 10)}/tasks/`,
          {
            // query URL without using browser cache
            headers: {
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          }
        )
        .then((p_response: AxiosResponse) => {
          if (p_response) {
            const response = p_response.data.response.taskDetail[0];
            m_drawData.value = constructPieDrawChart(
              response.toDraw,
              response.toValidate
            );
            m_drawEmpty.value = response.toDraw + response.toValidate === 0;
            m_makeData.value = constructPieMakeChart(
              response.toKey,
              response.toMake,
              response.toI3D
            );
            m_makeEmpty.value =
              response.toKey + response.toMake + response.toI3D === 0;
            m_shippingData.value = constructPieShipChart(
              response.toShip,
              response.critical
            );
            m_shippingEmpty.value = response.toShip + response.critical === 0;

            m_loading.value = false;
          }
        });
    }

    function constructPieDrawChart(toDraw: number, toValidate: number): any {
      return {
        labels: ["À dessiner", "Validation"],
        datasets: [
          {
            backgroundColor: m_backgroundColor,
            data: [toDraw, toValidate],
          },
        ],
      };
    }

    function constructPieMakeChart(
      toKey: number,
      toMake: number,
      toI3D: number
    ): any {
      return {
        labels: ["Clé", "Fil", "I3D"],
        datasets: [
          {
            backgroundColor: m_backgroundColor,
            data: [toKey, toMake, toI3D],
          },
        ],
      };
    }

    function constructPieShipChart(toShip: number, critical: number): any {
      return {
        labels: ["À expédier", "En retard"],
        datasets: [
          {
            backgroundColor: m_backgroundColor,
            data: [toShip, critical],
          },
        ],
      };
    }

    function goToDrawing(): void {
      router.push({ name: ROUTE_PRODUCTIONS_DRAW });
    }

    function goToMake(): void {
      router.push({ name: ROUTE_PRODUCTIONS_TOMAKE });
    }

    function goToShipping(): void {
      router.push({ name: ROUTE_PRODUCTIONS_SHIP });
    }

    return {
      DateHelper,
      m_date,
      m_date_options,
      m_loading,
      m_drawData,
      m_drawEmpty,
      m_makeData,
      m_makeEmpty,
      m_shippingData,
      m_shippingEmpty,
      m_options,
      m_currentDate,
      refreshPiesData,
      constructPieDrawChart,
      constructPieMakeChart,
      constructPieShipChart,
      mdiOpenInNew,
      mdiChevronDown,
      goToDrawing,
      goToMake,
      goToShipping,
      goTo,
      formatterNotZero,
    };
  },
});
</script>

<style lang="scss">
.custom-select {
  width: fit-content;
}

.custom-height {
  height: 85%;
}

.go-to-bottom {
  position: absolute;
  right: 25px;
  bottom: 15px;
}

.max100 {
  max-width: 100%;
  max-height: 100%;
}
</style>
