import { Privilege, Role } from "@winnove/vue-wlib/enums";
import { BooleanCast } from "pinia-orm/casts";
import Address from "./Address";
import BaseModel from "./BaseModel";

export default class User extends BaseModel {
  static entity = "users";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      lastName: this.string(""),
      firstName: this.string(""),
      password: this.string(""),
      privilege: this.number(Privilege.FREEMIUM),
      firstConnect: this.boolean(true),
      email: this.string(""),
      phoneNumber: this.string(""),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      preferences: this.string(""),
      blocked: this.number(0),
      role: this.number(Role.DEFAULT),
      unavailableDates: this.string(""),
      //
      //
      //patients: this.hasMany(Patient, 'userId'),
      //projects: this.hasManyThrough(Project, Patient, 'userId', 'patientId'),
      //robots: this.hasMany(Robot, 'userId'),
      //stat: this.hasOne(Stat, 'userId'),
      address: this.hasOne(Address, "userId"),
    };
  }

  static casts() {
    return {
      firstConnect: BooleanCast,
    };
  }

  declare id: number | null;
  declare lastName: string;
  declare firstName: string;
  declare password: string;
  declare privilege: number;
  declare firstConnect: number;
  declare email: string;
  declare phoneNumber: string;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  declare preferences: string;
  declare blocked: number;
  declare role: number;
  declare unavailableDates: string;
  //
  //
  //declare patients: Patient[];
  //declare projects: Project[];
  //declare robots: Robot[];
  //declare stat: Stat | null;
  declare address: Address | null;

  public getInitials(): string {
    return this.firstName[0].toUpperCase() + this.lastName[0].toUpperCase();
  }

  public serialize(): {} {
    const object: User = Object.assign(new User(), this);
    object.address = null;

    return object;
  }

  public isAddressComplete(): boolean {
    if (this.address) {
      return this.address.isValid() && this.phoneNumber !== "";
    } else {
      return false;
    }
  }
}
