<template>
  <w-dialog
    v-bind="{ ...$attrs, ...$props }"
    v-model="m_visible"
  >
    <w-card>
      <w-card-title
        v-if="p_title"
        :class="p_noPadding ? '' : ' pt-8 px-8'"
      >
        <h2 class="text-center text-wWhite">
          <w-icon
            v-if="p_icon"
            :icon="p_icon"
            class="mr-2"
          >
          </w-icon>
          {{ p_title }}
        </h2></w-card-title
      >
      <w-card-subtitle
        class="pt-0"
        v-if="p_subtitle"
      >
        <h3 class="mx-8 text-center text-wWhite">
          {{ p_subtitle }}
        </h3>
      </w-card-subtitle>
      <div :class="['bg-white', { 'pb-8 px-8': !p_noPadding }]">
        <slot></slot>
      </div>

      <w-btn
        v-if="!persistent"
        variant="plain"
        color="white"
        :icon="mdiClose"
        @click="
          $emit('close');
          close();
        "
        size="x-large"
        width="27px"
        height="27px"
        style="color: black; position: absolute; right: 0; top: 0; margin: 10px"
      >
      </w-btn>
    </w-card>
  </w-dialog>
</template>

<script lang="ts">
import { mdiClose } from "@mdi/js";
import WDialog from "@winnove/vue-wlib/lib/w-dialog.vue";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "BaseDialog",
  extends: WDialog,
  props: {
    p_title: {
      type: String,
      default: "",
    },
    p_subtitle: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    p_noPadding: {
      type: Boolean,
      default: false,
    },
    p_icon: {
      type: String,
      default: "",
    },
    transition: {
      type: String,
      default: "dialog-bottom-transition",
    },
  },
  setup(props, context) {
    const m_visible = ref(false);

    function close(): void {
      m_visible.value = false;
    }

    function show(): void {
      m_visible.value = true;
    }
    const isOpen = computed(() => m_visible.value);

    return {
      show,
      close,
      m_visible,
      mdiClose,
      isOpen,
    };
  },
  emits: ["close"],
});
</script>

<!-- <style scoped lang="scss">
.w-card {
  border-color: rgb(var(--v-theme-primary));
  border-width: 3px;
}
</style> -->
