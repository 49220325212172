import BaseModel from "./BaseModel";
import User from "./User";

export default class Robot extends BaseModel {
  static entity = "robots";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      userId: this.number(null),
      ip: this.string(""),
      serialNb: this.number(null),
      config: this.string(""),
      creationDate: this.string(null),
      modificationDate: this.string(null),
      //
      user: this.belongsTo(User, "userId"),
    };
  }

  declare id: number | null;
  declare userId: number | null;
  declare ip: string;
  declare serialNb: number;
  declare config: string;
  declare creationDate: string | null;
  declare modificationDate: string | null;
  //
  declare user: User | null;

  public serialize(): {} {
    const object: Robot = Object.assign(new Robot(), this);
    object.user = null;

    return object;
  }
}
