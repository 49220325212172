<template>
  <w-card
    flat
    class="bg-white h-100"
  >
    <div
      id="conversation-container"
      class="overflow-y-auto pa-0 conversation-custom-height"
      ref="conversationContainer"
    >
      <p
        v-if="m_comments.length === 0"
        class="h-100 w-100 d-flex justify-center align-center"
      >
        Aucun message
      </p>
      <template
        v-for="(comment, index) in m_comments"
        :key="comment.id"
      >
        <span
          v-if="shouldShowDateSeparator(index)"
          class="conversation-separator date-separator"
        >
          {{ DateHelper.formatDate(comment.creationDate) }}
          <w-divider />
        </span>
        <span
          v-if="shouldShowNotReadSeparator(index)"
          class="conversation-separator not-read-separator"
        >
          {{ m_unreadMessages }}
          {{ m_unreadMessages > 1 ? "Messages non lus" : "Message non lu" }}
          <w-divider />
        </span>
        <conversation-message
          :p_comment="comment"
          @deleteComment="deleteComment"
        />
      </template>
    </div>
    <w-card-actions class="px-0">
      <w-textarea
        v-model="p_commentContent"
        placeholder="Ecrivez votre message..."
        @keydown.enter.shift.exact="addComment"
        hide-details
        auto-grow
        rows="1"
        row-height="5"
        @click="setMessagesToRead"
      >
        <template v-slot:append-inner>
          <w-tooltip
            location="top"
            offset-y
          >
            <template v-slot:activator="{ props }">
              <w-icon
                :icon="mdiSend"
                class="mx-1 my-auto"
                @click="addComment"
                v-bind="props"
              />
            </template>
            <span
              >Entrée pour passer à la ligne<br />Shift+Entrée pour
              envoyer</span
            >
          </w-tooltip>
        </template>
      </w-textarea>
    </w-card-actions>
  </w-card>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import CommentHelper from "@/helpers/CommentHelper";
import DateHelper from "@/helpers/DateHelper";
import Comment from "@/models/Comment";
import Order from "@/models/Order";
import { mdiSend } from "@mdi/js";
import { CommentType } from "@winnove/vue-wlib/enums";
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "Conversation",
  props: {
    p_order: {
      type: Order,
      required: true,
    },
    p_sendReadNotification: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, context) {
    const p_commentContent = ref("");
    const conversationContainer = ref<HTMLDivElement>();
    const m_comments = ref<Comment[]>([]);
    const m_unreadMessages = ref<number>(0);

    onMounted(() => {
      refresh();
    });

    watch(
      () => props.p_order,
      () => {
        refresh();
      }
    );

    function refresh() {
      m_comments.value = props.p_order.comments.filter(
        (comment: Comment) =>
          comment.type === CommentType.CLIENT ||
          comment.type === CommentType.REQUEST_UPDATE
      );
      m_unreadMessages.value = 0;
      m_comments.value.forEach((comment) => {
        if (!comment.hasBeenReadByCurrentUser()) m_unreadMessages.value += 1;
      });
      scrollToBottom();
    }

    function scrollToBottom() {
      nextTick(() => {
        if (conversationContainer.value) {
          conversationContainer.value.scrollTop =
            conversationContainer.value.scrollHeight;
        }
      });
    }

    function shouldShowDateSeparator(index: number): boolean {
      if (index === 0) return true;
      let currentDate = m_comments.value[index].creationDate;
      let previousDate = m_comments.value[index - 1].creationDate;
      if (currentDate && previousDate) {
        const currentMessageDate = new Date(currentDate).toDateString();
        const previousMessageDate = new Date(previousDate).toDateString();
        return currentMessageDate !== previousMessageDate;
      }
      return true;
    }

    function shouldShowNotReadSeparator(index: number): boolean {
      return index === m_comments.value.length - m_unreadMessages.value;
    }

    async function addComment(event: KeyboardEvent) {
      event.preventDefault();
      if (p_commentContent.value) {
        let comment = new Comment();
        comment.content = p_commentContent.value.trimEnd();
        comment.userId = AuthHelper.getLoggedUser().id;
        comment.orderId = props.p_order.id;
        comment.type = CommentType.CLIENT;
        await CommentHelper.addComment(comment);
        context.emit("requestUpdateFromPinia");
        p_commentContent.value = "";
      }
    }

    async function deleteComment(p_comment: Comment) {
      await CommentHelper.deleteComment(p_comment);
      context.emit("requestUpdateFromPinia");
    }

    async function setMessagesToRead() {
      if (
        props.p_sendReadNotification &&
        (props.p_order.hasModificationCommentNotRead() ||
          props.p_order.hasClientCommentNotRead())
      ) {
        await CommentHelper.readComments(
          props.p_order.id!,
          m_comments.value as Comment[]
        );
        context.emit("requestUpdateFromPinia");
      }
    }

    return {
      p_commentContent,
      mdiSend,
      conversationContainer,
      m_comments,
      m_unreadMessages,
      addComment,
      scrollToBottom,
      shouldShowNotReadSeparator,
      shouldShowDateSeparator,
      setMessagesToRead,
      DateHelper,
      deleteComment,
    };
  },
});
</script>

<style lang="scss" scoped>
.conversation-separator {
  text-align: center;
  font-size: 12px;
  color: #9e9e9e;
  margin: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  hr {
    width: 80%;
  }
}

.date-separator {
  color: rgb(var(--v-theme-wDarkGrey));
}

.not-read-separator {
  color: rgb(var(--v-theme-wBlue));
  font-weight: 500;
}

#conversation-container {
  border-color: rgba(158, 158, 158, 0.2);
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  overflow: hidden;
}

#conversation-container::-webkit-scrollbar {
  width: 8px;
}

#conversation-container::-webkit-scrollbar-thumb {
  background-color: rgba(var(--v-theme-wDarkGrey));
  border-radius: 10px;
}

.conversation-custom-height {
  height: calc(100% - 72px);
}
</style>
