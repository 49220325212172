<script lang="ts">
import mitt from "mitt";
import { defineComponent } from "vue";

export type LoaderBusData = {
  show: void;
  hide: void;
};
export const LoaderBus = mitt<LoaderBusData>();

export default defineComponent({
  name: "Loader",
  setup(props, context) {},
});
</script>
