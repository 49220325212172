import BaseModel from "./BaseModel";

export default class Country extends BaseModel {
  static readonly entity = "countries";
  static readonly primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      code: this.string(""),
      name: this.string(""),
    };
  }

  declare id: number | null;
  declare code: string;
  declare name: string;

  public serialize(): {} {
    const object: Country = Object.assign(new Country(), this);
    return object;
  }
}
