import Config from "@/models/Config";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class ConfigHelper {
  public static async addConfig(p_config: Config): Promise<Config> {
    return await axios
      .post("configs/", { config: p_config.serialize() })
      .then((p_response: AxiosResponse) => {
        const config = p_response.data.config;
        useRepo(Config).save(config);
        // remove initial config from pini-orm
        useRepo(Config).destroy(p_config.id);
        // return new config
        return useRepo(Config).find(config.id) as Config;
      });
  }

  public static async updateConfig(p_config: Config): Promise<void> {
    await axios
      .put("configs/" + p_config.id, { config: p_config.serialize() })
      .then(() => {
        useRepo(Config).where("id", p_config.id).update(p_config);
      });
  }
}
