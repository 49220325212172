import { Constants } from "@/Constants";
import Logger from "@/shared/logger";
import { CtrlZActionType, PointType } from "@winnove/vue-wlib/enums";
import {
  BufferGeometry,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  Vector3,
} from "three";
import { SAH, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";
import { CtrlZAction, PointData } from "./SceneCommon";
import SceneMesh from "./SceneMesh";

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

// Class for meshes that are binded to points. (e.g. planes, wires, ...)
export default class SceneMeshOrPoints extends SceneMesh {
  public points: PointData[] = [];

  public setPointsVisible(p_visible: boolean, p_zonesVisible?: boolean): void {
    this.points.forEach((p) => {
      p.m.visible = p_visible;
    });
  }

  public disposePoints(): void {
    this.points.forEach((p) => {
      this._disposeMesh(p.m);
      this._disposeMesh(p.z);
    });
    this.points.length = 0;
  }

  public removePoint(p_name: string): CtrlZAction | undefined {
    const index: number = parseInt(p_name.split("|")[1]);
    if (index >= 0 && index < this.points.length) {
      const infos = {
        type: CtrlZActionType.ADD_POINT,
        name: this.points[index].m.name,
        basePosition: this.points[index].p,
        normal: this.points[index].n,
        finalPosition: this.points[index].m.position,
      };
      this._disposeMesh(this.points[index].m);
      this._disposeMesh(this.points[index].z);
      this.points.splice(index, 1);
      // Update the name of the points after the removed one
      for (let i = 0; i < this.points.length; i++) {
        this.points[i].m.name = p_name.split("|")[0] + "|" + i.toString();
        this.points[i].z.name = "zone" + this.points[i].m.name;
      }
      return infos;
    } else {
      Logger.getInstance().error(
        "Le point suivant n'a pas été trouvé : " + p_name,
        "Erreur lors de la suppression de ce point. Veuillez réessayer."
      );
    }
  }

  public disposeAll(): void {
    this.disposeMesh();
    this.disposePoints();
  }

  public rotate(p_axis: Vector3, p_angle: number): void {
    if (!this.mesh) return;
    this._rotateMesh(this.mesh, p_axis, p_angle);
    this.points.forEach((p) => {
      this._rotateMesh(p.m, p_axis, p_angle);
      this._rotateMesh(p.z, p_axis, p_angle);
      p.p.applyAxisAngle(p_axis, p_angle);
      p.n.applyAxisAngle(p_axis, p_angle);
    });
  }

  protected _addPoint(p_point: PointData): void {
    this.points.push(p_point);
  }

  protected _insertPoint(p_point: PointData, p_index: number): void {
    this.points.splice(p_index, 0, p_point);
  }

  protected _getPointPosition(p_index: number): Vector3 {
    return this.points[p_index].p;
  }

  protected _getPointNormal(p_index: number): Vector3 {
    return this.points[p_index].n;
  }

  protected _getPointMeshPosition(p_index: number): Vector3 {
    return this.points[p_index].m.position;
  }

  protected _getPointMesh(p_type: PointType): Mesh {
    // Create point mesh.
    const mGeometry = new SphereGeometry(1, 12, 8);
    if (p_type == PointType.WIRE) {
      // Manual editing, bigger point.
      const sf = Constants.CONTROLS_EDIT_POINT_SCALE;
      mGeometry.scale(sf, sf, sf);
    }

    let mColor = Constants.MESH_WIRE_MATERIAL_COLOR;
    if (p_type == PointType.WIRE)
      mColor = Constants.LIGHT_COLOR; // Manual editing, white point.
    else if (p_type == PointType.PLANE)
      mColor = Constants.MESH_PLANE_MATERIAL_COLOR; // Pink point
    const mMaterial = new MeshBasicMaterial({ color: mColor });

    return new Mesh(mGeometry, mMaterial);
  }

  protected _getPointZoneMesh(): Mesh {
    // Create point restrict zone mesh.
    const zGeometry = new SphereGeometry(Constants.MIN_DISTANCE_3D, 24, 16);
    const zMaterial = new MeshBasicMaterial({
      color: Constants.MESH_WIRE_MATERIAL_COLOR,
      transparent: true,
      opacity: 0.2,
      side: DoubleSide,
      polygonOffset: true,
      polygonOffsetFactor: 1,
      polygonOffsetUnits: 1,
    });
    zGeometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });

    return new Mesh(zGeometry, zMaterial);
  }

  protected _parsePointsJSON(
    pointsJSON: string,
    p_type: PointType
  ): PointData[] {
    const points: PointData[] = [];
    try {
      const pointsArray = JSON.parse(pointsJSON);
      pointsArray.forEach((point: any) => {
        const pt =
          p_type === PointType.WIRE
            ? new Vector3(point.x, point.y, point.z)
            : new Vector3(point.p.x, point.p.y, point.p.z);
        const normal =
          p_type === PointType.WIRE
            ? new Vector3(0, 0, 0)
            : new Vector3(point.n.x, point.n.y, point.n.z);
        const pointData = this._createPointData(pt, normal, p_type);
        points.push(pointData);
      });
    } catch (e: any) {
      if (e instanceof SyntaxError)
        console.warn("Erreur lors du chargement des points : " + e.message);
      // using console.warn to avoid displaying the error in the UI
      else throw e;
    }
    return points;
  }

  protected _createPointData(
    p_point: Vector3,
    p_normal: Vector3,
    p_type: PointType
  ): PointData {
    const pointData: PointData = {
      p: p_point.clone(),
      n: p_normal.clone(),
      m: this._getPointMesh(p_type),
      z: this._getPointZoneMesh(),
    };
    pointData.z.visible = false;
    pointData.m.position.set(p_point.x, p_point.y, p_point.z);
    pointData.z.position.set(p_point.x, p_point.y, p_point.z);
    if (p_type === PointType.PLANE) {
      const scale = Constants.MESH_PLANE_SCALE;
      pointData.m.scale.set(scale, scale, scale);
    }

    return pointData;
  }

  protected _getPointsLength(): number {
    return this.points.length;
  }

  protected _getPointMinDistance(p_pt: Vector3): number {
    let minDistance = Number.MAX_VALUE;
    this.points.forEach((p) => {
      const distance = p_pt.distanceTo(p.m.position);
      if (distance < minDistance) minDistance = distance;
    });
    return minDistance;
  }

  public getPoints(): string {
    const pts: PointData[] = [];
    this.points.forEach((p_point: PointData) => {
      const pt = JSON.parse(JSON.stringify(p_point));
      delete pt.p.isVector3;
      delete pt.n.isVector3;
      delete pt.m;
      delete pt.z;
      pts.push(pt);
    });
    return JSON.stringify(pts);
  }

  public getPointsNb(): number {
    return this.points.length;
  }

  protected _setColorToPoints(p_color: number): void {
    this.points.forEach((p) => {
      (p.m.material as MeshBasicMaterial)?.color.setHex(p_color);
    });
  }
}
