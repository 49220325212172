<template>
  <w-menu
    v-model="m_menu"
    :close-on-content-click="false"
    transition="slide-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ props }">
      <div class="field">
        <span
          class="label"
          for="multiple-date-range"
          >Jours d'indisponibilité
        </span>
        <div id="multiple-date-range">
          <w-btn
            rounded
            v-bind="props"
            id="add-range"
            color="secondary"
            ref="dateMenuBtn"
            >Ajouter une période</w-btn
          >
          <v-chip-group>
            <v-chip
              v-for="(date, index) in m_multipleDates"
              :key="index"
            >
              {{ date[0] }}{{ date[0] != date[1] ? " - " + date[1] : "" }}
              <w-btn
                size="x-small"
                rounded
                @click="deleteRange(index)"
                variant="tonal"
                class="pa-0 ml-2"
              >
                <w-icon :icon="mdiClose" />
                <w-tooltip
                  activator="parent"
                  location="bottom"
                >
                  <span>Supprimer cette période</span>
                </w-tooltip>
              </w-btn>
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </template>
    <div class="confirm-edit">
      <v-confirm-edit>
        <template v-slot:default="{}">
          <w-date-picker
            class="custom-date-picker-range text-white bg-primary"
            data-cy="date-picker-range-popup"
            v-model="m_datePickerValue"
            multiple="range"
            :min="p_min"
            :max="p_max"
            hide-header
            show-adjacent-months
            :allowed-dates="p_allowedDates"
          >
            <template v-slot:actions>
              <w-btn
                :disabled="m_datePickerValue.length === 0"
                @click="confirmDateSelection"
                >OK</w-btn
              >
              <w-btn @click="closeDateSelection">Annuler</w-btn>
            </template>
          </w-date-picker>
        </template>
      </v-confirm-edit>
    </div>
  </w-menu>
</template>

<script lang="ts">
import { mdiClose } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import WBtn from "@winnove/vue-wlib/lib/w-btn.vue";
import { DateTime } from "luxon";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "MultipleDatePickerRange",
  props: {
    p_dates: {
      type: String,
      default: "",
    },
    p_label: {
      type: String,
      default: "",
    },
    p_min: {
      type: String,
      default: "",
    },
    p_max: {
      type: String,
      default: "",
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    p_allowedDates: {
      type: Function,
      default: undefined,
    },
  },
  setup(props, context) {
    const m_multipleDates = ref<string[][]>(JSON.parse(props.p_dates));
    const m_datePickerValue = ref([]);
    const m_menu = ref(false);
    const dateMenuBtn = ref<InstanceType<typeof WBtn> | null>(null);

    onMounted(() => {
      m_multipleDates.value = props.p_dates ? JSON.parse(props.p_dates) : [];
    });

    function confirmDateSelection() {
      if (m_datePickerValue.value) {
        const firstDateDatetime = m_datePickerValue.value[0];
        const lastDateDatetime =
          m_datePickerValue.value[m_datePickerValue.value.length - 1];
        const firstDate =
          DateTime.fromISO(firstDateDatetime).toFormat("dd/MM/yyyy");
        const lastDate =
          DateTime.fromISO(lastDateDatetime).toFormat("dd/MM/yyyy");
        m_multipleDates.value.push([firstDate, lastDate]);
        context.emit("change", JSON.stringify(m_multipleDates.value));
      }
      closeDateSelection();
    }

    function closeDateSelection() {
      m_datePickerValue.value = [];
      m_menu.value = false;

      // Workaround since the activator props binding to button mess up with the modelValue of the menu
      if (dateMenuBtn.value) {
        dateMenuBtn.value!.$el.click();
      }
    }

    function deleteRange(index: number) {
      m_multipleDates.value.splice(index, 1);
      context.emit("change", JSON.stringify(m_multipleDates.value));
    }

    return {
      Privilege,
      m_multipleDates,
      m_datePickerValue,
      m_menu,
      mdiClose,
      confirmDateSelection,
      closeDateSelection,
      deleteRange,
      dateMenuBtn,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-date-picker-range {
  :deep(.v-date-picker-month__day button:not(.v-btn--disabled)) {
    color: white !important;
  }

  :deep(.v-date-picker-month__day--selected button) {
    background-color: rgb(var(--v-theme-wBlue)) !important;
    border: 1px solid !important;
  }
}

.field {
  flex: 1 0;
  grid-area: field;
  position: relative;
  align-items: flex-start;
  display: flex;
  border-radius: 4px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
  margin-bottom: 22px;
}

.label {
  position: absolute;
  top: 0;
  color: rgb(118, 118, 118);
  font-size: 12px;
  transform: translateY(-50%);
  margin: 0 10px;
  padding: 0 4px;
  background-color: rgb(var(--v-theme-background));
}

#multiple-date-range {
  display: flex;
  align-items: center;
  margin: 10px;
  width: calc(100% - 24px);

  & #add-range {
    height: 40px;
    padding: 0 12px;
    margin: 4px 8px 4px 0;
  }
}

.confirm-edit {
  margin-bottom: 5px;
}
.confirm-edit > :nth-child(2) {
  display: none;
}
.confirm-edit > :nth-child(3) {
  display: none;
}
</style>
