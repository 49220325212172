import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";
import Address from "@/models/Address";
import Arch from "@/models/Arch";
import Billing from "@/models/Billing";
import Comment from "@/models/Comment";
import Config from "@/models/Config";
import Order from "@/models/Order";
import Patient from "@/models/Patient";
import ProductionProcess from "@/models/ProductionProcess";
import Project from "@/models/Project";
import Robot from "@/models/Robot";
import Session from "@/models/Session";
import StatOrder from "@/models/StatOrder";
import Task from "@/models/Task";
import User from "@/models/User";
import Wire from "@/models/Wire";
import WireHistory from "@/models/WireHistory";
import Logger from "@/shared/logger";
import {
  AccountManagerStep,
  Compression,
  Dimension,
  DimensionNumber,
  ProcessStep,
} from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import JSZip from "jszip";
import { Item, useRepo } from "pinia-orm";
import { BufferGeometry } from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { Constants } from "../Constants";
import { AuthHelper } from "./AuthHelper";

const compressionHeaders = [
  [0x50, 0x4b, 0x03, 0x04], //PK (zip)
  [0x52, 0x61, 0x72, 0x21, 0x1a, 0x07], //Rar! (rar)
  [0x75, 0x73, 0x74, 0x61, 0x72], //ustar (tar)
];

const compressionExtensions = [".zip", ".rar", ".tar"];

export interface ServerDataOptions {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
}

export interface SortOptions {
  key: string;
  order: string;
}
export interface DataTableOptions {
  page: number;
  itemsPerPage: number;
  sortBy: SortOptions[];
  groupBy: SortOptions[];
  search: string;
}

export default class WinnoveHelper {
  static inchDiameterDimensionToMMRadius(p_dimension: Dimension): number {
    return (DimensionNumber[p_dimension] * Constants.INCH_TO_MM) / 2;
  }

  static clearAll(): void {
    useRepo(Session).flush();
    this.clearAllWithoutSession();
    localStorage.clear();
  }

  static getSession(): Session {
    const sessionRepo = useRepo(Session);
    let session: Item<Session> = sessionRepo.query().first();
    if (!session) {
      session = new Session();
      sessionRepo.save(session);
      return session;
    } else {
      return session;
    }
  }

  static setSession(p_session: Session): void {
    const sessionRepo = useRepo(Session);
    sessionRepo.save(p_session);
  }

  static clearAllWithoutSession(): void {
    useRepo(Address).flush();
    useRepo(Arch).flush();
    useRepo(Order).flush();
    useRepo(Patient).flush();
    useRepo(Project).flush();
    useRepo(Robot).flush();
    useRepo(User).flush();
    useRepo(Wire).flush();
    useRepo(Config).flush();
    useRepo(Comment).flush();
    useRepo(Billing).flush();
    useRepo(WireHistory).flush();
    useRepo(Task).flush();
    useRepo(ProductionProcess).flush();
  }

  static clearAndInsertAllSummaryData(p_summary: any): void {
    // add sortId to each project
    let sortId = 0;
    for (const project of p_summary.projects) {
      project.sortId = sortId++;
    }

    // clear and insert all data
    useRepo(Project).fresh(p_summary.projects);
    useRepo(Patient).fresh(p_summary.patients);
    useRepo(Arch).fresh(p_summary.archs);
    useRepo(Wire).fresh(p_summary.wires);
    useRepo(Order).fresh(p_summary.orders);
    useRepo(Address).fresh(p_summary.addresses);
    useRepo(Robot).fresh(p_summary.robots);
    useRepo(User).fresh(p_summary.users);
    useRepo(Config).fresh(p_summary.configs);
    useRepo(WireHistory).fresh(p_summary.wiresHistory);
    useRepo(Task).fresh(p_summary.tasks);
    useRepo(ProductionProcess).fresh(p_summary.productionProcess);
    useRepo(StatOrder).fresh(p_summary.statOrders);

    // add initialContent to each comment
    for (const comment of p_summary.comments) {
      comment.initialContent = comment.content;
    }
    useRepo(Comment).fresh(p_summary.comments);
  }

  public static dataTableOptions2ServerDataOptions(
    p_options: DataTableOptions
  ): ServerDataOptions {
    const serverDataOptions: ServerDataOptions = {
      page: p_options.page,
      itemsPerPage: p_options.itemsPerPage,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    };
    for (const sortOption of p_options.sortBy) {
      serverDataOptions.sortBy.push(sortOption.key);
      serverDataOptions.sortDesc.push(sortOption.order === "desc");
    }
    for (const groupOption of p_options.groupBy) {
      serverDataOptions.groupBy.push(groupOption.key);
      serverDataOptions.groupDesc.push(groupOption.order === "desc");
    }
    return serverDataOptions;
  }

  // get projects list depending on options and search. Returns the number of projects matching the search
  static async getSummaryData(
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>,
    p_maxDate?: Date
  ): Promise<number> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }

    const serverOptions = p_options
      ? this.dataTableOptions2ServerDataOptions(p_options)
      : {};
    return await axios
      .get("users/" + AuthHelper.getLoggedUser().id + "/summary", {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
          maxDate: p_maxDate,
        },
        headers: { disableLoader: true },
      })
      .then(async (p_response: AxiosResponse) => {
        const summary: any = p_response.data.summary;
        const count: number = p_response.data.count;
        WinnoveHelper.clearAndInsertAllSummaryData(summary);
        return count;
      });
  }

  static async getProdSummaryData(
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>,
    p_maxDate?: Date
  ): Promise<number> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }

    const serverOptions = p_options
      ? this.dataTableOptions2ServerDataOptions(p_options)
      : {};
    return await axios
      .get("users/" + AuthHelper.getLoggedUser().id + "/summary/prod", {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
          maxDate: p_maxDate,
        },
        headers: { disableLoader: true },
      })
      .then(async (p_response: AxiosResponse) => {
        const summary: any = p_response.data.summary;
        const count: number = p_response.data.count;
        WinnoveHelper.clearAndInsertAllSummaryData(summary);
        return count;
      });
  }

  static async getProductionSummaryData(
    p_uri: string,
    p_step: ProcessStep | AccountManagerStep,
    p_options?: DataTableOptions,
    p_maxDate?: string,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ count: number; urgent: number; critical: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }

    const serverOptions = p_options
      ? this.dataTableOptions2ServerDataOptions(p_options)
      : {};
    return await axios
      .get(
        "users/" + AuthHelper.getLoggedUser().id + "/summary/" + p_uri + p_step,
        {
          params: {
            ...serverOptions,
            search: p_options?.search ?? "",
            filters: JSON.stringify(filters),
            maxDate: p_maxDate,
          },
          headers: { disableLoader: true },
        }
      )
      .then(async (p_response: AxiosResponse) => {
        const summary: any = p_response.data.summary;
        const count: number = p_response.data.count;
        const urgent: number = p_response.data.urgent;
        const critical: number = p_response.data.critical;
        WinnoveHelper.clearAndInsertAllSummaryData(summary);
        return { count, urgent, critical };
      });
  }

  static async getSummaryOrderCount(
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>,
    p_maxDate?: Date
  ): Promise<number> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }

    const serverOptions = p_options
      ? this.dataTableOptions2ServerDataOptions(p_options)
      : {};
    return await axios
      .get("users/" + AuthHelper.getLoggedUser().id + "/summary/count", {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
          maxDate: p_maxDate,
        },
        headers: { disableLoader: true },
      })
      .then(async (p_response: AxiosResponse) => {
        const count: number = p_response.data.count;
        return count;
      });
  }

  static async getUserTaskSummaryData(): Promise<number> {
    return await axios
      .get("users/" + AuthHelper.getLoggedUser().id + "/summary/tasks", {
        params: {},
        headers: { disableLoader: true },
      })
      .then(async (p_response: AxiosResponse) => {
        const summary: any = p_response.data.summary;
        const count: number = p_response.data.count;
        WinnoveHelper.clearAndInsertAllSummaryData(summary);
        return count;
      });
  }

  static async fetchConfig(): Promise<void> {
    return await axios
      .get("configs/", { headers: { disableLoader: true } })
      .then((p_response: AxiosResponse) => {
        const configs: any = p_response.data.configs;
        const configRepo = useRepo(Config);
        configRepo.fresh(configs);
      });
  }

  static async decompressZIP(
    p_data: ArrayBuffer
  ): Promise<ArrayBuffer | Error> {
    // load the zip's data through the JSZip library and return the arraybuffer of the stl in it
    // if there's no stl or multiple ones, print an error.
    const zipTool = new JSZip();
    const MAX_FILES = 100;
    const MAX_FILE_SIZE = 1000000000; // 1GB
    try {
      let totalSize = 0;
      // Load the zip file and check if it's not too big
      const zipFile = await zipTool.loadAsync(p_data);
      zipFile.forEach((_relativePath: any, zipEntry: any) => {
        zipEntry.async("arraybuffer").then((fileData: any) => {
          totalSize += fileData.byteLength;
        });
      });

      if (totalSize > MAX_FILE_SIZE)
        return Error(
          "L'archive .zip est trop volumineuse. Veuillez la vérifier."
        );

      // Filter out the __MACOSX folder that MacOS creates when zipping files
      const realFiles = Object.values(zipFile.files).filter(
        (file: JSZip.JSZipObject) => !file.name.startsWith("__MACOSX")
      );

      // Check if the zip contains too many files
      if (realFiles.length > MAX_FILES)
        return Error(
          "L'archive .zip contient trop de fichiers. Veuillez la vérifier."
        );

      // Filter out the files that are not .stl
      const stlFiles: any = realFiles.filter((file: JSZip.JSZipObject) =>
        file.name.endsWith(".stl")
      );

      // Check if the zip contains too many stl files
      if (stlFiles.length === 0)
        return Error(
          "L'archive .zip ne contient pas de STL. Veuillez la vérifier."
        );
      else if (stlFiles.length === 1)
        return await stlFiles[0].async("arraybuffer");
      else
        return Error(
          "L'archive .zip contient plusieurs STLs. Veuillez n'en sélectionner qu'un seul."
        );
    } catch (error: any) {
      Logger.getInstance().error(error.message);
      return error;
    }
  }

  static async decompressRAR(
    p_data: ArrayBuffer
  ): Promise<ArrayBuffer | Error> {
    return Error(
      "Les archives .rar ne sont pas encore supportées. Veuillez extraire le .stl de celle-ci avec WinRAR ou 7-Zip."
    );
  }

  static async decompressTAR(
    p_data: ArrayBuffer
  ): Promise<ArrayBuffer | Error> {
    return Error(
      "Les archives .tar ne sont pas encore supportées. Veuillez extraire le .stl de celle-ci avec WinRAR ou 7-Zip."
    );
  }

  static getFileCompression(p_data: ArrayBuffer, p_name: string): Compression {
    // compare the header of the data to the 3 supported (or soon supported) compression formats'
    // headers (we take the 1rst 6 bytes as that can contain each header, rar being the biggest)
    // we also check for the extension of the file because some headers vary depending on the OS
    const dataHeader = new Uint8Array(p_data).subarray(0, 6);
    let compression = Compression.NONE;
    compressionHeaders.every((header, compressionId) => {
      header.every((byte, byteId) => {
        if (dataHeader[byteId] !== byte) return false;
        if (byteId === header.length - 1)
          compression = compressionId as Compression;
        return true;
      });
      if (p_name.endsWith(compressionExtensions[compressionId]))
        compression = compressionId as Compression;
      if (compression === Compression.NONE) return true;
    });
    return compression;
  }

  static async readFile(p_file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      if (p_file.name.endsWith(".stl") && p_file.size === 0) {
        // this condition is typical of a stl file that was dragged directly from an archive (zip, rar, tar...)
        // it's impossible to get the archive itself (to decompress it) when only the file was dropped
        // because the browser can't access the user's local files, it's therefore invalid
        reject(
          Error(
            "Le .stl est compressé. Veuillez déposer l'archive directement ou la décompresser avec WinRAR ou 7-Zip puis déposer les .stl décompressés."
          )
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = async (p_event: any) => {
        let data = p_event.target.result;

        // get the compression type (if any) from the header of the data
        // to eventually decompress it before analysing/using it
        const compression = this.getFileCompression(data, p_file.name);
        if (compression !== Compression.NONE) {
          let result: ArrayBuffer | Error;
          switch (compression) {
            case Compression.ZIP:
              result = await this.decompressZIP(data);
              break;
            case Compression.RAR:
              result = await this.decompressRAR(data);
              break;
            case Compression.TAR:
              result = await this.decompressTAR(data);
          }
          if (result instanceof ArrayBuffer) data = result;
          else {
            reject(result);
            return;
          }
        }

        const fileSize = data.byteLength;
        if (fileSize < 84) {
          reject(
            Error(
              "Le fichier STL est corrompu. Veuillez en faire part à votre fournisseur."
            )
          );
          return;
        }
        const triangleCount = new DataView(data).getUint32(80, true);

        if ((fileSize - 84) % 50 !== 0) {
          reject(
            Error(
              "Le fichier STL est corrompu. Veuillez en faire part à votre fournisseur."
            )
          );
          return;
        }
        if (triangleCount !== (fileSize - 84) / 50) {
          reject(
            Error(
              "Le fichier STL est corrompu. Veuillez en faire part à votre fournisseur."
            )
          );
          return;
        }
        // parse the file to see if it's a valid stl
        try {
          const geometry: BufferGeometry = new STLLoader().parse(data);
          (geometry.getAttribute("position").array as Float32Array).forEach(
            (coord) => {
              if (coord < -1000 || coord > 1000)
                // if the file is a stl but a coordinate is too big/small, it's invalid
                reject(
                  Error(
                    "Le fichier STL est corrompu. Veuillez en faire part à votre fournisseur."
                  )
                );
              return;
            }
          );
          resolve(data);
        } catch (e) {
          // if the file is a stl that could be read but not parsed, it's invalid
          reject(
            Error(
              "Le fichier n'est pas un .stl ou le STL est corrompu. Veuillez le vérifier."
            )
          );
        }
      };
      reader.onerror = () => {
        // if the file can't be read, it's invalid
        reject(
          Error(
            "Impossible d'accéder à ce fichier. Veuillez vérifier qu'il n'a pas été supprimé et a les bonnes permissions."
          )
        );
      };
      reader.readAsArrayBuffer(p_file);
    });
  }

  static isDev(): boolean {
    let isDev = false;
    const mode = import.meta.env.MODE;
    if (mode === "dev" || mode === "remote-test" || mode === "remote-prod")
      isDev = true;

    return isDev;
  }

  static isStaging(): boolean {
    return import.meta.env.MODE === "staging";
  }

  static isProd(): boolean {
    return import.meta.env.MODE === "production";
  }
}
