import { AlgoVersion } from "@winnove/vue-wlib/enums";
import { Vector3 } from "three";
import BaseModel from "./BaseModel";
import User from "./User";
import Wire from "./Wire";

export default class WireHistory extends BaseModel {
  static entity = "wiresHistory";
  static primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      wireId: this.number(null),
      userId: this.number(null),
      basePoints: this.string("[]"),
      wirePoints: this.string("[]"),
      algoVersion: this.number(AlgoVersion.DEFAULT),
      timer: this.number(-1),
      timestamp: this.string(""),
      user: this.belongsTo(User, "userId"),
      wire: this.belongsTo(Wire, "wireId"),
    };
  }

  declare id: number | null;
  declare wireId: number | null;
  declare userId: number | null;
  declare basePoints: string;
  declare wirePoints: string;
  declare algoVersion: AlgoVersion;
  declare timer: number;
  declare timestamp: string;
  //
  declare wire: Wire | null;
  declare user: User | null;

  public serialize(): {} {
    const object: WireHistory = Object.assign(new WireHistory(), this);

    return object;
  }

  private _parsePoints(p_points: string): Vector3[] {
    const result: Vector3[] = [];
    JSON.parse(p_points).forEach((p_point: any) => {
      result.push(new Vector3(p_point.x, p_point.y, p_point.z));
    });
    return result;
  }

  public getWirePoints(): Vector3[] {
    return this._parsePoints(this.wirePoints);
  }

  public getBasePoints(): Vector3[] {
    return this._parsePoints(this.basePoints);
  }

  public getTimestamp(): string {
    return this.timestamp;
  }
}
