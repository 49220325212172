<template>
  <BaseDialog
    ref="shippingDialog"
    p_title="Envoyer les commandes sélectionnées"
  >
    <w-card-text>
      <w-text-field
        v-model="m_trackingNumber"
        label="Numéro de suivi"
        :rules="[(v) => (v && !!v.trim()) || 'Numéro de suivi requis']"
        required
        data-cy="order-shipping-dialog-text"
      ></w-text-field>
      <w-btn
        @click="markAsShipped(m_trackingNumber)"
        class="mt-1"
        block
        :disabled="!m_trackingNumber"
        data-cy="order-shipping-validate-btn"
      >
        Valider le numéro de suivi.
      </w-btn>
    </w-card-text>
  </BaseDialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import Order from "@/models/Order";
import { ColorsConstants } from "@/shared/constants";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "OrderShippingDialog",
  components: { BaseDialog },
  props: {},
  setup(props, context) {
    const shippingDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_trackingNumber = ref("");
    const m_orders = ref<Order[]>([]);

    function show(p_orders: Order[]): void {
      shippingDialog.value?.show();
      m_orders.value = p_orders;
    }

    function hide(): void {
      shippingDialog.value?.close();
      m_trackingNumber.value = "";
    }

    function markAsShipped(trackingNumber: string): void {
      context.emit("markAsShipped", trackingNumber, m_orders.value);
      hide();
    }
    return {
      shippingDialog,
      m_trackingNumber,
      ColorsConstants,
      markAsShipped,
      show,
      hide,
    };
  },
});
</script>
