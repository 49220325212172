<template>
  <w-container
    fluid
    class="overflow-y-auto pa-0"
  >
    <div
      class="d-flex flex-column-reverse flex-md-row justify-start justify-md-space-between"
    >
      <div class="my-2 mx-4 d-flex flex-row justify-start">
        <w-btn
          data-cy="show-group-by-ortho"
          class="mr-2"
          size="small"
          variant="outlined"
          height="24px"
          :prepend-icon="mdiFormatListGroup"
          @click="togglegroupByOrtho()"
        >
          Grouper par ortho.
        </w-btn>
      </div>

      <div class="d-flex flex-row my-2 mx-4 production-chip-container">
        <ProductionProjectTableStatsChip
          :p_number="m_totalCount"
          p_color="wGrey"
          :p_icon="mdiCheck"
          :p_loading="m_loading"
          p_label="tâches à faire"
        ></ProductionProjectTableStatsChip>
      </div>
    </div>

    <w-data-table-server
      data-cy="production-table"
      class="table_production"
      :headers="m_tasksHeaders"
      :items="m_ordersItems"
      :items-length="m_totalCount"
      item-key="id"
      :page="m_page"
      :items-per-page="m_itemsPerPage"
      @update:options="optionsChanged"
    >
      <template v-slot:body>
        <!-- for every doctor -->
        <template
          v-for="(it, indexOrders) in getOrders()"
          :key="it.name"
        >
          <tr
            class="tableInfoRow"
            v-if="isGroupByOrtho()"
          >
            <td
              :colspan="m_tasksHeaders.length"
              class=""
            >
              <h4>Dr. {{ it.name }}</h4>
            </td>
          </tr>

          <tr
            class="ProductionProjectRow normal"
            data-cy="production-table-row"
            v-for="(order, index) in it.items"
            :key="order.id"
          >
            <!-- Reference. -->
            <td>
              <p
                v-if="order"
                class="ma-0"
                data-cy="production-table-row-reference"
              >
                {{ order.getReference() }}
              </p>
            </td>

            <!-- Doctor. -->
            <td align-self="center">
              <p class="ma-0">Dr. {{ order.project.getDoctorName() }}</p>
            </td>

            <td>
              <p class="ma-0">Nouveau(x) message(s) à lire</p>
            </td>

            <td style="max-width: 200px">
              <w-expansion-panels
                v-model="m_panels[getPanelIndex(indexOrders, index)]"
                @update:modelValue="onlyOnePanelOpened"
              >
                <w-expansion-panel>
                  <w-expansion-panel-title>
                    Voir la conversation
                  </w-expansion-panel-title>
                  <w-expansion-panel-text style="height: 350px">
                    <Conversation
                      :p_order="order"
                      @requestUpdateFromPinia="refresh"
                      :p_sendReadNotification="false"
                      class="px-2 pt-2"
                    />
                  </w-expansion-panel-text>
                </w-expansion-panel>
              </w-expansion-panels>
            </td>

            <!-- actions -->
            <td align-self="right">
              <div class="d-flex flex-row justify-end align-center pr-6">
                <!-- Marquer comme fait -->
                <w-tooltip
                  left
                  v-if="m_role === Role.ACCOUNT_MANAGER"
                >
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="markAsRead(order)"
                      v-bind="props"
                      data-cy="mark-as-done-btn"
                    >
                      <w-icon :icon="mdiCheck"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Marquer comme lu</span>
                </w-tooltip>
                <!-- open project -->
                <w-tooltip left>
                  <template v-slot:activator="{ props }">
                    <w-btn
                      variant="text"
                      size="small"
                      icon
                      @click="openOrder(order)"
                      @click.middle="openOrder(order, true)"
                      v-bind="props"
                    >
                      <w-icon :icon="mdiArrowCollapseRight"> </w-icon>
                    </w-btn>
                  </template>
                  <span>Ouvrir</span>
                </w-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </w-data-table-server>
  </w-container>
</template>

<script lang="ts">
import OrderHelper from "@/helpers/OrderHelper";
import WinnoveHelper, { DataTableOptions } from "@/helpers/WinnoveHelper";
import Order from "@/models/Order";
import Logger from "@/shared/logger";
import {
  AccountManagerStep,
  Arcade,
  Privilege,
  Role,
} from "@winnove/vue-wlib/enums";

import router, { ROUTE_EDITOR } from "@/router";
import { mdiArrowCollapseRight, mdiCheck, mdiFormatListGroup } from "@mdi/js";

import { AuthHelper } from "@/helpers/AuthHelper";
import CommentHelper from "@/helpers/CommentHelper";
import CookieHelper from "@/helpers/CookieHelper";
import SortHelper from "@/helpers/SortHelper";
import Comment from "@/models/Comment";
import { useRepo } from "pinia-orm";
import {
  WritableComputedRef,
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  groupBy: [],
  search: "",
};

export default defineComponent({
  name: "ProductionTable",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_cookie: {
      type: String,
      required: true,
    },
    p_groupByOrtho: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const m_tasksHeaders = [
      {
        title: "REF UNIQUE",
        sortable: false,
        value: "ref",
      },
      {
        title: "PRATICIEN",
        value: "Users.lastName",
        sortable: false,
      },
      {
        title: "TYPE",
        sortable: false,
      },
      {
        title: "CONTENU",
        sortable: false,
      },

      {
        title: "ACTIONS",
        sortable: false,
      },
    ];
    // selection
    const m_actionMenu = ref(false);

    const m_panels = ref<number[]>([]);
    const m_openedPanel = ref<number>(0);

    const m_ordersItems = ref<Order[]>([]);
    let m_orders: Order[] = [];
    const m_loading = ref(true);
    const m_role = ref(Role.DEFAULT);

    const m_totalCount = ref(0);
    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie(props.p_cookie)
        ? JSON.parse(CookieHelper.getCookie(props.p_cookie)!)
        : DEFAULT_OPTIONS
    );

    const m_page: WritableComputedRef<number> = computed({
      get: () => m_options.value.page,
      set: (val: number) => {
        m_options.value.page = val;
        onOptionsChanged();
      },
    });
    const m_itemsPerPage: WritableComputedRef<number> = computed({
      get: () => m_options.value.itemsPerPage,
      set: (val: number) => {
        m_options.value.itemsPerPage = val;
        onOptionsChanged();
      },
    });

    const m_groupByOrtho = ref(props.p_groupByOrtho);

    let _refreshProjectListTimeout: ReturnType<typeof setTimeout> | null = null;

    function refresh(): void {
      m_orders = useRepo(Order).query().withAllRecursive().get();
      m_ordersItems.value = useRepo(Order).query().withAllRecursive().get();
      m_panels.value = Array(m_orders.length).fill(-1);
      m_panels.value[m_openedPanel.value] = 0;
      m_totalCount.value = m_orders.length;
    }

    function onlyOnePanelOpened() {
      m_openedPanel.value = m_panels.value.findIndex(
        (panel, index) => panel === 0 && index !== m_openedPanel.value
      );
      m_panels.value = Array(m_orders.length).fill(-1);
      m_panels.value[m_openedPanel.value] = 0;
    }

    async function refreshProjectList(): Promise<void> {
      m_loading.value = true;
      if (_refreshProjectListTimeout) clearTimeout(_refreshProjectListTimeout);
      _refreshProjectListTimeout = setTimeout(() => {
        // Get summary data
        (async () => {
          try {
            const counts: {
              count: number;
              urgent: number;
              critical: number;
            } = await WinnoveHelper.getProductionSummaryData(
              "accountmanager/",
              AccountManagerStep.IN_TASKS,
              m_options.value,
              ""
            );
            m_totalCount.value = counts.count;
          } catch (e: any) {
            Logger.getInstance().error(e.message);
            WinnoveHelper.clearAllWithoutSession();
            return;
          } finally {
            refresh();
            m_loading.value = false;
          }
        })();
      }, 250);
    }

    onMounted(async () => {
      m_role.value = AuthHelper.getLoggedUser().role;
      await refreshProjectList();
    });

    function onOptionsChanged(): void {
      refreshProjectList();
      CookieHelper.setCookie(props.p_cookie, m_options.value);
    }

    async function updateOrder(order: Order): Promise<void> {
      await OrderHelper.updateOrder(order);
      refreshProjectList();
    }

    function openOrder(order: Order, newtab: boolean = false): void {
      if (newtab) {
        window.open(
          router.resolve({
            name: ROUTE_EDITOR,
            params: { reference: order.project!.reference },
          }).href,
          "_blank"
        );
      } else
        router.push({
          name: ROUTE_EDITOR,
          params: { reference: order!.project!.reference },
        });
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      onOptionsChanged();
    }

    function getOrders(): ItemGroup<Order>[] {
      if (isGroupByOrtho()) {
        return groupAndSortOrders();
      } else {
        return sortOrders();
      }
    }

    function groupAndSortOrders(): ItemGroup<Order>[] {
      return SortHelper.groupAndSortOrders(m_orders, true);
    }

    function sortOrders(): ItemGroup<Order>[] {
      return SortHelper.sortOrdersAsGroup(m_orders, true);
    }

    function isGroupByOrtho(): boolean {
      return m_groupByOrtho.value;
    }

    function togglegroupByOrtho(): void {
      m_groupByOrtho.value = !m_groupByOrtho.value;
    }

    async function markAsRead(order: Order) {
      await CommentHelper.readComments(
        order.id!,
        order.comments as Comment[],
        true
      );
      refresh();
    }

    function getPanelIndex(indexOrders: number, index: number): number {
      if (indexOrders > 0) {
        const orders = getOrders();
        for (let i = 0; i < indexOrders; i++) {
          index += orders[i].items.length;
        }
      }
      return index;
    }

    return {
      m_tasksHeaders,
      Privilege,
      Arcade,
      m_page,
      m_itemsPerPage,
      m_loading,
      m_totalCount,
      m_options,
      m_actionMenu,
      m_panels,
      m_role,
      Role,
      refreshProjectList,
      updateOrder,
      groupAndSortOrders,
      m_ordersItems,
      onOptionsChanged,
      optionsChanged,
      openOrder,
      markAsRead,
      isGroupByOrtho,
      getOrders,
      togglegroupByOrtho,
      refresh,
      onlyOnePanelOpened,
      getPanelIndex,
      mdiArrowCollapseRight,
      mdiCheck,
      mdiFormatListGroup,
    };
  },
});
</script>

<style lang="scss">
.ProductionProjectRow {
  position: relative;
  font-weight: 700;
}

.tableInfoRow {
  background-color: rgb(var(--v-theme-background));
  color: rgb(var(--v-theme-primary));
  td {
    height: auto !important;
  }
}

.table_production {
  margin: auto;

  .v-data-table__wrapper {
    padding: 0 16px;
    background-color: rgb(var(--v-theme-background));
    overflow-y: scroll;
    max-height: calc(100vh - 64px - 60px - 40px);
    @media screen and (max-width: 960px) {
      max-height: calc(100vh - 56px - 60px - 40px);
    }

    table {
      background-color: white;
      max-width: 1800px !important;
      margin: auto;
    }
  }

  th {
    top: 15px !important;
    background: rgb(var(--v-theme-background)) !important;
  }

  // Blocks to hide items shadows on the sides
  th:first-of-type:before {
    content: "";
    position: absolute;
    left: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }
  th:last-of-type:before {
    content: "";
    position: absolute;
    right: -10px;
    width: 10px;
    background: rgb(var(--v-theme-background));
    height: 100%;
    top: 0;
  }

  .v-data-footer {
    margin: auto;
    max-width: 1800px;
  }

  .urgent {
    color: black;
    background-color: rgba(var(--v-theme-wGrey));
  }

  .normal {
    color: black;
    background-color: rgba(var(--v-theme-wGrey), 0.2);
  }

  .critical {
    color: black;
    background-color: rgba(var(--v-theme-secondary), 0.5);
  }

  .unavailable {
    color: rgba(black, 0.3);
    :deep(.v-chip) {
      opacity: 0.3;
    }
  }
}

#shipping-search-bar-container {
  width: 200px;
  height: 24px;
}

#shipping-search-bar-container .v-text-field__slot {
  height: 14px;
  & > input {
    height: 14px;
  }
}

#shipping-search-bar-container .v-input {
  height: 24px;
  margin: 0 !important;

  .v-input__control {
    height: 24px;

    .v-field {
      height: 24px;
      padding: 0 6px;

      .v-field__prepend-inner {
        height: 24px;
        font-size: 0.75rem;
      }

      .v-field__clearable {
        height: 24px;
        font-size: 0.75rem;
      }

      .v-field__field {
        height: 24px;

        .v-field__input {
          padding-top: 0;
          padding-bottom: 16px;
          font-size: 0.85rem;
        }
      }
    }
  }
}
</style>
