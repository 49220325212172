<template>
  <w-tooltip right>
    <template v-slot:activator="{ props }">
      <w-avatar
        :color="p_color"
        :size="p_size"
        v-bind="props"
        >{{ p_user ? p_user.getInitials() : "WM" }}</w-avatar
      >
    </template>
    <span
      >{{ p_tooltip }} {{ p_user ? p_user.firstName : "" }}
      {{ p_user ? p_user.lastName : "Winnove Med" }}.</span
    >
  </w-tooltip>
</template>

<script lang="ts">
import User from "@/models/User";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AvatarWithTooltip",
  props: {
    p_user: {
      type: User,
      required: false,
    },
    p_color: {
      type: String,
      required: true,
    },
    p_size: {
      type: String,
      required: true,
    },
    p_tooltip: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>
