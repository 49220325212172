import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";
import Billing from "@/models/Billing";
import axios, { AxiosResponse } from "axios";
import WinnoveHelper, { DataTableOptions } from "./WinnoveHelper";

export default class BillingHelper {
  static async getRemainingTokens(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ remaining: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};
    if (userId)
      return await axios
        .get(`billings/remaining/${userId}`, {
          params: {
            ...serverOptions,
            search: p_options?.search ?? "",
            filters: JSON.stringify(filters),
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const remaining: any = p_response.data.remaining;
          const count: number = p_response.data.count;
          return {
            remaining: remaining,
            count: count,
          };
        });
    else
      return await axios
        .get(`billings/remaining`, {
          params: {
            ...serverOptions,
            search: p_options?.search ?? "",
            filters: JSON.stringify(filters),
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const remaining: any = p_response.data.remaining;
          // billings.credits are received as string from the server, so we need to convert them to number
          for (const element of remaining) element.credits = +element.credits;

          const count: number = p_response.data.count;
          return {
            remaining: remaining,
            count: count,
          };
        });
  }

  static async getBillingHistory(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ billings: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    const url: string = userId ? `billings/history/${userId}` : "billings/";

    return await axios
      .get(url, {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then(async (p_response: AxiosResponse) => {
        const billings: any = p_response.data.billing;
        const count: number = p_response.data.count;
        return {
          billings: billings,
          count: count,
        };
      });
  }

  static async getBillingHistoryInvoice(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ billings: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    return await axios
      .get(`billings/invoicedHistory/${userId}`, {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then(async (p_response: AxiosResponse) => {
        const billings: any = p_response.data.billing;
        const count: number = p_response.data.count;
        return {
          billings: billings,
          count: count,
        };
      });
  }

  static async addBilling(p_newBilling: Billing): Promise<Billing> {
    return await axios
      .post(`billings`, { billing: p_newBilling.serialize() })
      .then(async (p_response: AxiosResponse) => {
        const billing: any = p_response.data.billing;
        return billing;
      });
  }

  static async deleteBilling(p_billing: Billing): Promise<void> {
    await axios.delete("billings/" + p_billing.id, {});
  }

  static async updateBilling(p_billing: Billing): Promise<void> {
    await axios.put("billings/" + p_billing.id, {
      billing: p_billing,
    });
  }
}
