import Address from "@/models/Address";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class AddressHelper {
  public static async updateAddress(p_address: Address): Promise<void> {
    // Update user.
    await axios
      .put("addresses/" + p_address.id, { address: p_address.serialize() })
      .then((p_response: AxiosResponse) => {
        useRepo(Address).where("id", p_address.id).update(p_address);
      });
  }

  static async fetchAddresses(): Promise<void> {
    return await axios.get("addresses/").then((p_response: AxiosResponse) => {
      const addresses = p_response.data.addresses;
      const addressRepo = useRepo(Address);
      addressRepo.fresh(addresses);
    });
  }
}
