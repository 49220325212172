<template>
  <w-chip
    class="mt-2 ml-3 px-4 filter-chip"
    variant="tonal"
    label
    link
    color="primary"
  >
    <w-icon
      :icon="mdiFilterMenuOutline"
      class="mr-2"
    ></w-icon>
    {{ p_filter.name }} : {{ valuesLabel }}
    <w-icon
      v-if="!allChecked"
      :icon="mdiCloseCircle"
      class="ml-2"
      @click="resetFilter"
    ></w-icon>

    <w-menu
      offset-y
      :close-on-content-click="false"
      transition="slide-y-transition"
      activator="parent"
      max-width="300px"
      min-width="100px"
      v-model="m_filterMenu"
    >
      <w-list density="compact">
        <w-list-item
          density="compact"
          style="min-height: 0; padding: 0 10px"
        >
          <div class="d-flex justify-center">
            <w-btn
              variant="outlined"
              rounded
              size="small"
              color="wWhite"
              @click="toggleCheckbox()"
              >{{ toggleCheck ? "Tout cocher" : "Tout décocher" }}</w-btn
            >
          </div>
        </w-list-item>
        <w-list-item
          v-for="(value, index) in sortedValues"
          :key="index"
          density="compact"
          style="padding: 0 10px"
          height="28px"
        >
          <w-checkbox
            v-model="value.isChecked"
            :label="value.name"
            density="compact"
            hide-details
            @change="storeFilter()"
          />
        </w-list-item>
      </w-list>
    </w-menu>
  </w-chip>
</template>

<script lang="ts">
import { mdiCloseCircle, mdiFilterMenuOutline } from "@mdi/js";
import { computed, ComputedRef, defineComponent, ref, Ref } from "vue";
import FilterValue from "./FilterValue";
import PaginationFilter from "./PaginationFilter";

export default defineComponent({
  name: "FilterChip",
  components: {},
  props: {
    p_filter: {
      type: Object as () => PaginationFilter,
      required: true,
    },
  },
  setup(props, context) {
    const toggleCheck: Ref<boolean> = ref(false);

    const m_filterMenu: Ref<boolean> = ref(false);

    // String showing all the checked values
    const valuesLabel: ComputedRef<string> = computed(() => {
      let values = props.p_filter.getCheckedValuesLabel();
      // if more than 3 values, we show only the first 3 followed by "..."
      const splitValues = values.split(", ");
      if (splitValues.length > 3) {
        values = splitValues.slice(0, 3).join(", ") + ", ...";
      }
      return values;
    });

    // Indicates wether all values are checked or not
    const allChecked: ComputedRef<boolean> = computed(() => {
      return props.p_filter.areAllValuesChecked();
    });

    // Values sorted alphabetically
    const sortedValues: ComputedRef<Array<FilterValue>> = computed(() => {
      return props.p_filter.getSortedValues();
    });

    // Resets all filter values to "checked"
    function resetFilter(e: PointerEvent) {
      if (allChecked.value) {
        // If all values are already checked, no need to do anything.
        // Normal chip behavior (opening filter settings).
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      props.p_filter.resetValues();
      storeFilter();
    }

    // Function to store the filter in cookies
    function storeFilter() {
      props.p_filter.storeValInCookies();
      context.emit("update");
    }

    // Function to toggle all values
    function toggleCheckbox() {
      if (toggleCheck.value) props.p_filter.checkAll();
      else props.p_filter.uncheckAll();
      toggleCheck.value = !toggleCheck.value;
      storeFilter();
    }

    return {
      toggleCheck,
      valuesLabel,
      allChecked,
      sortedValues,
      m_filterMenu,
      mdiFilterMenuOutline,
      mdiCloseCircle,
      resetFilter,
      storeFilter,
      toggleCheckbox,
    };
  },
});
</script>

<style scoped lang="scss">
.filter-chip {
  height: 40px;
}
</style>
