<template>
  <div class="pa-1">
    <w-btn
      tabindex="0"
      @click="onClick()"
      :class="[
        'basic-tool-btn',
        { 'bg-wDeepBlue': !p_active || p_keepBackgroundWhite },
        { 'bg-white': p_active && !p_keepBackgroundWhite },
      ]"
      size="x-small"
      variant="text"
      color="white"
      rounded
      :disabled="p_disabled"
      height="44px"
      width="44px"
      style="border: 2px solid"
    >
      <!-- Tooltip shows the caption message and if p_link is set, the associated GIF / image -->
      <w-tooltip
        :location="p_tooltipOnTheRight ? 'right' : 'bottom'"
        :disabled="isMobile"
        activator="parent"
      >
        <span>{{ p_caption }}</span>
        <div class="d-flex flex-column align-center jus">
          <w-img
            v-if="p_link"
            :src="p_link"
            alt="basic-tool-img"
            width="250"
          />
        </div>
      </w-tooltip>

      <!-- Show different images depending on the state of the tool -->
      <!-- disabled -->
      <img
        v-if="p_disabled"
        :src="p_imgDisabled"
        width="40px"
        alt=""
      />
      <!-- active -->
      <img
        v-else-if="p_active"
        :src="p_imgActive"
        width="40px"
        alt=""
      />
      <!-- default -->
      <img
        v-else
        :src="p_imgDefault"
        width="40px"
        alt=""
      />
    </w-btn>
  </div>
</template>

<script lang="ts">
import Logger from "@/shared/logger";
import { isMobile } from "@/utils/deviceUtils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BasicTool",
  inheritAttrs: false,
  props: {
    p_imgDefault: {
      type: String,
      required: true,
    },
    p_imgActive: {
      type: String,
      required: true,
    },
    p_imgDisabled: {
      type: String,
      required: true,
    },
    p_caption: {
      type: String,
      required: true,
    },
    p_disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_disabledWarning: {
      type: String,
      required: false,
      default: "Outil non actif dans ce mode",
    },
    p_active: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_tooltipOnTheRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_link: {
      type: String,
      required: false,
      default: "",
    },
    p_keepBackgroundWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const logger = Logger.getInstance();
    function onClick(): void {
      if (props.p_disabled) {
        logger.warning(props.p_disabledWarning);
        return;
      }
      context.emit("click");
    }
    return {
      onClick,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.basic-tool-btn {
  padding: 0;
  margin: 0;

  // TODO: remove v-btn__overlay background on hover
}
</style>
