<template>
  <w-row
    v-if="m_visible"
    id="row-occlusion-legend"
    class="rounded-xl"
    :style="gradientCSS"
  >
    <w-col
      md="6"
      class="text-left"
      >{{ OCCLUSION_MIN_DISTANCE }}mm</w-col
    >
    <w-col
      md="6"
      class="text-right"
      >{{ OCCLUSION_MAX_DISTANCE }}mm</w-col
    ></w-row
  >
</template>

<script lang="ts">
import { Constants } from "@/Constants";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "OcclusionLegend",
  setup(props, context) {
    const m_visible = ref(false);

    function show(): void {
      m_visible.value = true;
    }

    function hide(): void {
      m_visible.value = false;
    }

    function setVisible(p_visible: boolean): void {
      m_visible.value = p_visible;
    }

    const gradientCSS =
      "background: linear-gradient(to left, #ffff00, #ff0000)";

    return {
      m_visible,
      show,
      hide,
      setVisible,
      OCCLUSION_MIN_DISTANCE: Constants.OCCLUSION_MIN_DISTANCE,
      OCCLUSION_MAX_DISTANCE: Constants.OCCLUSION_MAX_DISTANCE,
      gradientCSS,
    };
  },
});
</script>

<style lang="scss" scoped>
#row-occlusion-legend {
  position: absolute;
  z-index: 1;
  @media screen and (max-width: 960px) {
    top: 26px;
    right: 26px;
    width: 150px;
  }
  @media screen and (min-width: 960px) {
    top: 88px;
    left: calc(50% - 100px);
    width: 200px;
  }
}
</style>
