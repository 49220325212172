<template>
  <main class="PhoneTemplate">
    <div class="svg-container">
      <svg
        width="82"
        height="76"
        viewBox="0 0 83 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="animated-path"
          d="M75.1522 1.02568C73.3399 1.50377 72.0068 2.62624 71.3193 4.28917C71.1319 4.76726 70.9444 6.5549 70.7986 9.67289C69.6321 32.8915 66.4658 51.6826 62.3205 60.0803C61.2373 62.2213 59.925 64.0506 59.1959 64.3831C57.5294 65.1522 53.2591 60.8702 49.218 54.3848C48.3639 53.0129 47.6557 51.7449 47.6557 51.5994C47.6557 51.4331 48.5931 49.396 49.7179 47.0679C52.5509 41.2061 54.4465 36.8617 54.8839 35.1364C55.4047 33.0578 55.3839 27.7572 54.8631 25.8864C53.5299 21.0847 50.3012 17.9252 45.3643 16.5948C43.5104 16.0959 39.1568 16.0959 37.1362 16.5948C32.0743 17.8628 28.5331 21.5628 27.5332 26.7179C27.0958 28.9628 27.2208 32.9746 27.8249 35.2404C28.3248 37.0696 30.1787 41.3308 33.1159 47.3174L35.1989 51.5994L34.1991 53.2415C30.5745 59.2696 26.8458 63.614 24.6378 64.4455C23.7837 64.7781 23.7004 64.7781 23.2213 64.4247C21.784 63.3438 19.7426 59.5814 18.4094 55.5696C15.1806 45.8623 12.7643 29.0668 11.8269 9.88076C11.5561 4.49703 11.3686 3.7695 9.7855 2.3768C8.01489 0.81781 5.74435 0.568372 3.66128 1.71163C1.28658 3.0004 0.682491 4.55939 0.869967 8.92457C1.9115 30.6881 4.59866 48.9179 8.41068 59.8309C9.49387 62.9281 11.8061 67.5219 13.2226 69.4343C18.3469 76.3354 25.4502 77.4579 32.241 72.4483C34.7198 70.6191 38.7818 66.1084 40.9899 62.6994C41.3232 62.2213 41.3232 62.2213 43.0104 64.4663C48.6556 72.0326 53.4466 75.3585 58.696 75.3585C63.6537 75.3585 67.799 72.4068 71.1527 66.5034C74.2148 61.1404 76.8395 52.0775 78.6934 40.6033C80.1516 31.5404 81.568 16.1583 81.6722 8.28019C81.7139 5.0167 81.693 4.72569 81.2556 3.9358C80.0057 1.58691 77.4852 0.402079 75.1522 1.02568ZM43.4896 27.4454C44.6352 28.0274 45.0727 29.9814 44.5103 32.0185C44.2811 32.8915 41.6773 38.982 41.4065 39.2522C41.3232 39.3353 40.5316 37.7555 39.6359 35.7185C38.2194 32.5173 37.9903 31.8314 37.9069 30.5634C37.7611 28.4016 38.2819 27.5701 40.0525 27.1128C40.9482 26.8634 42.7188 27.0505 43.4896 27.4454Z"
          fill="none"
        />
      </svg>
    </div>
    <h1>Winnove Medical</h1>
    <p class="french">
      Nous vous invitons à nous retrouver sur votre Ordinateur ou votre tablette
    </p>
    <p class="english">Please joins us on your Desktop or your tablet</p>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "PhoneTemplate",
  components: {},
  props: {},
});
</script>

<style lang="scss" scoped>
.PhoneTemplate {
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  margin: 0;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;

  h1 {
    color: #008fff;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 30px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
  }
  P {
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;
  }
  .french {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
  .english {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
  }
  .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animated-path {
    stroke: #008fff;
    stroke-width: 2;
    fill: #0091ff00;
    stroke-dasharray: 1575;
    stroke-dashoffset: 1575;
    animation: dash 10s ease-in-out 1s infinite;
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 1575;
      fill: #0091ff00;
    }
    50% {
      fill: #0091ff;
      stroke-dashoffset: 0;
    }
    100% {
      fill: #0091ff00;
      stroke-dashoffset: 1575;
    }
  }
}
.PhoneTemplate::-webkit-scrollbar {
  display: none;
}
</style>
