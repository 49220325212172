import Config from "@/models/Config";
import { DateTime } from "luxon";

export default class DateHelper {
  static formatDate(p_date: string | null): string {
    if (p_date) {
      return new Date(p_date).toLocaleDateString();
    }
    return "-";
  }

  static formatDateISO(p_date: string | null): string {
    if (p_date) {
      return DateTime.fromISO(p_date).toISO().split("T")[0];
    }
    return "-";
  }

  static formatDateWithHours(p_date: string | null): string {
    if (p_date) {
      return DateTime.fromISO(p_date).toLocaleString(DateTime.DATETIME_SHORT);
    }
    return "-";
  }

  static formatDateAndGetHours(p_date: string | null): string {
    if (p_date) {
      return DateTime.fromISO(p_date).toFormat("HH:mm");
    }
    return "-";
  }

  static compareDates(p_date1: string | null, p_date2: string | null): number {
    if (p_date1 && p_date2) {
      return new Date(p_date1).getTime() - new Date(p_date2).getTime();
    }
    return 0;
  }

  static getCurrentWeekNumber(): number {
    const currentWeekNumber = DateTime.local().toFormat("WW");
    return currentWeekNumber;
  }

  static getWeekNumber(p_date: DateTime): number {
    const weekNumber = +p_date.toFormat("WW");
    return weekNumber;
  }

  static getNextAvailableDate(): string {
    const productionDelay: number = Config.getProductionDelayMin();
    return this.getNextBusinessDay(productionDelay);
  }

  static getNextBusinessDay(p_dayOffset: number): string {
    const nextAvailableDate: Date = new Date(Date.now());

    const closedDays: string[] = Config.getClosedDays();
    while (p_dayOffset > 0) {
      nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
      // Check saturday and sunday.
      if (nextAvailableDate.getDay() == 6 || nextAvailableDate.getDay() == 0) {
        continue;
      }
      // Check closed days.
      if (closedDays.includes(nextAvailableDate.toISOString().slice(0, 10))) {
        continue;
      }

      p_dayOffset--;
    }

    return nextAvailableDate.toISOString().slice(0, 10);
  }

  static getLastAvailableDate(): string {
    const lastAvailableDate: Date = new Date(Date.now());
    lastAvailableDate.setMonth(
      lastAvailableDate.getMonth() + Config.getProductionDelayMax()
    );
    return lastAvailableDate.toISOString().slice(0, 10);
  }

  static parseDate(p_date: string | null): string | null {
    if (!p_date) return null;

    const [day, month, year] = p_date.split("/");
    if (day && month && year) {
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    } else {
      return null;
    }
  }

  static formatDateInUTC(p_timestamp: Date): Date {
    // ugly but back send utc date and front perfom it as a local date
    // this trick avoid an other query
    const date = new Date();
    date.setUTCDate(p_timestamp.getDate());
    date.setUTCFullYear(p_timestamp.getFullYear());
    date.setUTCMonth(p_timestamp.getMonth());
    date.setUTCHours(p_timestamp.getHours());
    date.setUTCMinutes(p_timestamp.getMinutes());
    date.setUTCSeconds(p_timestamp.getSeconds());
    return date;
  }
}
