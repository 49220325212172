import BaseModel from "./BaseModel";
import Order from "./Order";
import User from "./User";

export default class StatOrder extends BaseModel {
  static readonly entity = "StatsOrder";
  static readonly primaryKey = "id";

  static fields() {
    return {
      id: this.number(null),
      orderId: this.number(null),
      order: this.belongsTo(Order, "orderId"), // Order Relation
      timestamp: this.string(""),
      updatedBy: this.number(null),
      updated: this.belongsTo(User, "updatedBy"), // User Relation
      status: this.number(0),
    };
  }

  declare id: number | null;
  declare orderId: number | null;
  declare order: Order | null;
  declare timestamp: string;
  declare updatedBy: number | null;
  declare updated: User | null;
  declare status: number;

  public serialize(): {} {
    const object: StatOrder = Object.assign(new StatOrder(), this);

    object.order = this.order ? this.order : null;
    object.updated = this.updated ? this.updated : null;

    return object;
  }
}
