<template>
  <w-form ref="form">
    <w-textarea
      v-model="m_comment.content"
      class="commentTextArea mt-2"
      auto-grow
      rows="2"
      row-height="10"
      :append-inner-icon="
        m_comment.hasChanged() ? mdiSend : p_readonly ? '' : mdiPencil
      "
      :label="m_label"
      @click:append-inner="edit"
      :readonly="p_readonly"
      :color="color"
      :rules="
        m_comment.hasChanged()
          ? ['Pour enregistrer, cliquez sur l\'icon']
          : [true]
      "
      :hide-details="!m_comment.hasChanged()"
    ></w-textarea>
  </w-form>
</template>

<script lang="ts">
import CommentHelper from "@/helpers/CommentHelper";
import Comment from "@/models/Comment";
import { mdiPencil, mdiSend } from "@mdi/js";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "CommentArea",
  props: {
    p_comment: {
      // prop sync
      type: Object as () => Comment,
      default: new Comment(),
    },
    p_label: {
      type: String,
      default: "Comment",
    },
    p_readonly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  setup(props, context) {
    const m_comment = computed({
      get: () => props.p_comment,
      set: (value) => context.emit("update:p_comment", value),
    });
    const m_label = computed(() => {
      let label = props.p_label;
      if (props.p_readonly) {
        label += " (non modifiable)";
      }
      return label;
    });
    const form = ref<HTMLFormElement | null>(null);

    async function edit(): Promise<void> {
      if (m_comment.value.hasChanged()) {
        await updateComment(m_comment.value).then((updatedComment: Comment) => {
          m_comment.value = updatedComment;
        });
        form.value?.resetValidation();
      }
    }

    async function updateComment(p_com: Comment): Promise<Comment> {
      if (p_com.existsInDatabase()) {
        // update existing comment
        await CommentHelper.updateComment(p_com);
      } else {
        // Create new comment
        await CommentHelper.addComment(p_com).then((addedComment: Comment) => {
          if (addedComment.existsInDatabase()) {
            p_com = addedComment;
          } else {
            throw new Error(
              "Une erreur s'est produite. Le commentaire n'a pas pu être ajouté, veuillez réessayer."
            );
          }
        });
      }
      context.emit("requestUpdateFromPinia");
      return p_com;
    }

    return {
      m_comment,
      m_label,
      form,
      edit,
      mdiPencil,
      mdiSend,
    };
  },
});
</script>

<style lang="scss">
.v-textarea.commentTextArea {
  font-weight: bold;

  textarea {
    font-size: 0.75em;
    line-height: 1.1em;
  }
}
</style>
