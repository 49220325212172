export default class FilterValue {
  name: string;
  value: number[];
  isChecked: boolean;

  constructor(name: string, value: number[], isChecked: boolean = true) {
    this.name = name;
    this.value = value;
    this.isChecked = isChecked;
  }

  static fromEnum(
    enumString: string[],
    enumValue: number[],
    isChecked: boolean = true
  ): FilterValue[] {
    // build an array of FilterValue from an enum. When enumString names are similar, concatenate the enumValue
    const filterValues: FilterValue[] = [];
    enumString.forEach((name, index) => {
      const value = enumValue[index];
      const existingFilter = filterValues.find(
        (filter) => filter.name === name
      );
      if (existingFilter) {
        existingFilter.value.push(value);
      } else {
        filterValues.push(new FilterValue(name, [value], isChecked));
      }
    });
    return filterValues;
  }
}
