/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from "@/plugins";

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// styles
import "@/styles/style.scss";

// sentry
import { version } from "@/../package.json";
import * as Sentry from "@sentry/vue";
import { AuthHelper } from "./helpers/AuthHelper";
import WinnoveHelper from "./helpers/WinnoveHelper";

const app = createApp(App);

registerPlugins(app);

const user = AuthHelper.getLoggedUser();

if (!WinnoveHelper.isDev()) {
  Sentry.init({
    app,
    dsn: "https://4ab03b955a86a204100a1fe185f76039@o4506852381556736.ingest.sentry.io/4506852383522816",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://backend.test.winnovemed.com",
      "https://backend.winnovemed.com",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
    release: `iform-front@v${version}`,
    initialScope: {
      user: {
        id: user.id ?? undefined,
        username: user.lastName,
        email: user.email,
      },
    },
  });
}

app.mount("#app");
