import User from "@/models/User";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class UserHelper {
  public static async addUser(p_user: User): Promise<void> {
    await axios
      .post("users/", { user: p_user.serialize() })
      .then((p_response: AxiosResponse) => {
        const userRepo = useRepo(User);
        userRepo.save(p_response.data.user);
      });
  }

  public static async updateUser(p_user: User): Promise<void> {
    // Update user.
    await axios
      .put("users/" + p_user.id, { user: p_user.serialize() })
      .then((p_response: AxiosResponse) => {
        useRepo(User).where("id", p_user.id).update(p_user);
      });
  }

  public static testPwd(p_pwd: string): boolean {
    const pattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
    if (pattern.test(p_pwd)) return true;
    return false;
  }

  public static async updateUserPwd(p_user: User): Promise<boolean> {
    if (this.testPwd(p_user.password)) {
      // Update user.
      return await axios
        .put("users/password/" + p_user.id, { user: p_user.serialize() })
        .then((p_response: AxiosResponse) => {
          useRepo(User).where("id", p_user.id).update(p_user);
          return true;
        });
    } else {
      return false;
    }
  }

  public static async fetchUsers(): Promise<void> {
    await axios.get("users/").then((p_response: AxiosResponse) => {
      const userRepo = useRepo(User);
      userRepo.fresh(p_response.data.users);
    });
  }
}
