<template>
  <w-card
    class="pa-0 pt-0 ma-0 mt-0 reduce-br"
    elevation="0"
  >
    <w-card-title>
      <h4>Modification du fil</h4>
    </w-card-title>

    <!-- show wire config -->
    <w-card-text class="px-4 pb-0">
      <p>
        À l'aide des outils de la barre de gauche, vous pouvez modifier le fil.
        Une fois que vous avez terminé, cliquez sur "Envoyer" pour envoyer le
        fil en fabrication.
      </p>
    </w-card-text>

    <w-card-actions class="d-flex justify-end mt-2">
      <!-- Cancel button activator -->
      <w-btn
        color="error"
        size="small"
        @click="$emit('cancel')"
      >
        Changer de décision
      </w-btn>

      <!-- Change file button -->
      <w-btn
        size="small"
        color="white"
        @click="$emit('submit')"
      >
        Envoyer
      </w-btn>
    </w-card-actions>
  </w-card>
</template>

<script lang="ts">
import Order from "@/models/Order";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WireEditCard",
  props: {
    p_order: {
      type: Object as () => Order,
      required: true,
    },
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
  },
  setup(props, context) {
    return {
      Privilege: Privilege,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-btn::before {
  background-color: transparent;
}

.reduce-br {
  border-radius: 5px;
}
.project-info-tabs {
  img {
    background-color: white !important;
    border-radius: 12px;
  }
}
</style>
