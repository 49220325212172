import Country from "@/models/Country";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class CountryHelper {
  static async fetchCountries(): Promise<void> {
    return await axios.get("countries/").then((p_response: AxiosResponse) => {
      const countries = p_response.data.countries;
      const countriesRepo = useRepo(Country);
      countriesRepo.fresh(countries);
    });
  }
}
