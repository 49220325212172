<template>
  <w-container
    fluid
    style="max-width: 800px; margin: 0 auto"
  >
    <w-data-table-server
      class="table_patient text-primary"
      :headers="m_headers"
      :items="m_patients"
      :items-length="m_totalCount"
      :search="m_search"
      :page="m_options.page"
      :items-per-page="m_options.itemsPerPage"
      :sortBy="m_options.sortBy"
      :hover="true"
      item-key="id"
      fixed-header
      @update:options="optionsChanged"
    >
      <template v-slot:top>
        <w-row class="pt-1 pb-4 background-grey">
          <w-col
            md="3"
            offset-md="1"
            ><w-btn
              @click="newPatient()"
              block
              >Nouveau patient
            </w-btn>
          </w-col>
          <w-col
            md="7"
            class="text-primary"
          >
            <w-text-field
              v-model="m_search"
              label="Rechercher un patient"
              class="mx-4"
              :prepend-inner-icon="mdiMagnify"
              rounded
              hide-details
              color="primary"
              base-color="primary"
            ></w-text-field>
          </w-col>
        </w-row>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.lastName="{ item }">
        <span>{{ item.lastName.toUpperCase() }}</span>
      </template>

      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <w-btn
          variant="tonal"
          size="small"
          color="default"
          @click="filterProjectsByPatient(item)"
          class="ml-1"
        >
          <w-icon :icon="mdiEye"></w-icon>
          <w-tooltip
            activator="parent"
            location="bottom"
          >
            <span>Voir ses projets</span>
          </w-tooltip>
        </w-btn>

        <w-btn
          variant="tonal"
          size="small"
          color="default"
          @click="editPatient(item)"
          class="ml-1"
        >
          <w-icon :icon="mdiPencil"></w-icon>
          <w-tooltip
            activator="parent"
            location="bottom"
          >
            <span>Modifier</span>
          </w-tooltip>
        </w-btn>
      </template>
    </w-data-table-server>
    <PatientDialog
      ref="patientDialog"
      @refresh="refresh()"
    />
  </w-container>
</template>

<script lang="ts">
import PatientDialog from "@/components/dashboard/dialogs/PatientDialog.vue";
import CookieHelper from "@/helpers/CookieHelper";
import PatientHelper from "@/helpers/PatientHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import Patient from "@/models/Patient";
import router, { ROUTE_PATIENTS } from "@/router";
import { mdiEye, mdiMagnify, mdiPencil } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: [{ key: "creationDate", order: "desc" }],
  groupBy: [],
  search: "",
};

const m_headers = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "NOM",
    key: "lastName",
  },
  {
    title: "PRENOM",
    key: "firstName",
  },
  {
    title: "ACTIONS",
    key: "actions",
    sortable: false,
    filterable: false,
    align: "end",
  },
];

export default defineComponent({
  name: "PatientTable",
  components: {
    PatientDialog,
  },
  props: {},
  setup() {
    const m_patients = ref([] as Patient[]);
    const m_totalCount = ref(0);
    const patientDialog = ref<InstanceType<typeof PatientDialog> | null>(null);

    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie("PatientDashboardOptionsV3")
        ? JSON.parse(CookieHelper.getCookie("PatientDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    const m_search = ref<string>(m_options.value.search);

    onMounted(() => {
      refresh();
    });

    async function refresh(): Promise<void> {
      m_totalCount.value = await PatientHelper.fetchPatients(m_options.value);
      m_patients.value = useRepo(Patient).all();
    }

    function newPatient(): void {
      patientDialog.value?.show();
    }

    function editPatient(p_patient: Patient): void {
      patientDialog.value?.setPatient(p_patient);
      patientDialog.value?.show();
    }

    function filterProjectsByPatient(p_patient: Patient): void {
      router.push({
        name: ROUTE_PATIENTS,
        params: { filter: "patient", id: p_patient.id!.toString() },
      });
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    async function _onOptionsChanged(): Promise<void> {
      CookieHelper.setCookie("PatientDashboardOptionsV3", m_options.value);

      if (m_search.value === null) m_search.value = "";

      const count: number = await PatientHelper.fetchPatients(m_options.value);
      m_totalCount.value = count;
      m_patients.value = useRepo(Patient).all();
    }

    return {
      m_headers,
      patientDialog,
      m_patients,
      m_search,
      m_options,
      m_totalCount,
      newPatient,
      editPatient,
      refresh,
      optionsChanged,
      filterProjectsByPatient,
      ROUTE_PATIENTS,
      mdiMagnify,
      mdiPencil,
      mdiEye,
    };
  },
});
</script>

<style scoped lang="scss">
.background-grey {
  background-color: #f6f9fd;
}
</style>
