import { CtrlZActionType } from "@winnove/vue-wlib/enums";
import { Box3, Mesh, PerspectiveCamera, Vector3 } from "three";

// Define an user point.
// Short naming to minimize database size.
export interface PointData {
  p: Vector3; // Position.
  n: Vector3; // Normal.
  m: Mesh; // Mesh.
  z: Mesh; // Clickable zone mesh.
}

export interface CtrlZAction {
  type: CtrlZActionType;
  name: string;
  basePosition: Vector3;
  normal: Vector3;
  finalPosition: Vector3;
}

export function getCameraDefaultPositionFromBBox(
  p_camera: PerspectiveCamera,
  p_box: Box3,
  p_padding: number
): Vector3 {
  const size = new Vector3();
  const center = new Vector3();
  p_box.getSize(size);
  p_box.getCenter(center);
  const width: number = size.x + p_padding;
  const fov: number = p_camera.fov;
  const aspect: number = p_camera.aspect;

  // Compute distance to fit the scene in the screen (horizontal only).
  // Algorithm resolution.
  //fov = 2 * Math.atan((width / aspect) / (2 * dist)) * (180 / Math.PI);
  //fov = Math.atan((width / aspect) / (2 * dist)) * (360 / Math.PI);
  //fov / (360 / Math.PI) = Math.atan((width / aspect) / (2 * dist));
  //(width / aspect) / (2 * dist) = Math.tan(fov / (360 / Math.PI));
  //dist = width / aspect / 2 / Math.tan(fov / (360 / Math.PI));

  const distance: number = width / aspect / 2 / Math.tan(fov / (360 / Math.PI));

  const position: Vector3 = center;
  position.setZ(position.z + distance + size.z / 2);

  return position;
}
