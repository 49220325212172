import Arch from "@/models/Arch";
import Billing from "@/models/Billing";
import Order from "@/models/Order";
import Project from "@/models/Project";
import Wire from "@/models/Wire";
import {
  BLUE_DARK,
  BLUE_DARK_TEXT,
  BLUE_LIGHT,
  BLUE_LIGHT_TEXT,
  BLUE_MEDIUM,
  BLUE_MEDIUM_TEXT,
  FreemiumOrderStateColor,
  FreemiumOrderStateString,
  GREEN,
  GREEN_LIGHT,
  GREEN_LIGHT_TEXT,
  GREEN_TEXT,
  GREY,
  GREY_TEXT,
  InOfficeOrderStateColor,
  InOfficeOrderStateString,
  ORANGE_DARK,
  ORANGE_DARK_TEXT,
  OrderState,
  OrderStateColor,
  OrderStateString,
  PremiumOrderStateColor,
  PremiumOrderStateString,
  Privilege,
  RED_LIGHT,
  RED_LIGHT_TEXT,
} from "@winnove/vue-wlib/enums";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class ProjectHelper {
  static async archiveProject(p_project: Project): Promise<Project> {
    if (!p_project.id) {
      throw new Error("Le projet doit avoir un ID pour être archivé.");
    }

    return await axios
      .put(`projects/archive/${p_project.id}`)
      .then((p_response: AxiosResponse) => {
        const project = p_response.data.project;
        const projectRepo = useRepo(Project);
        projectRepo.save(project);

        return projectRepo.find(project.id) as Project;
      });
  }

  static async restoreProject(p_project: Project): Promise<Project> {
    if (!p_project.id) {
      throw new Error("Le projet doit avoir un ID pour être restauré.");
    }

    return await axios
      .put(`projects/restore/${p_project.id}`)
      .then((p_response: AxiosResponse) => {
        const project = p_response.data.project;
        const projectRepo = useRepo(Project);
        projectRepo.save(project);

        return projectRepo.find(project.id) as Project;
      });
  }

  static async updateProject(p_project: Project) {
    if (p_project.id) {
      // Update existing project
      await axios
        .put("projects/" + p_project.id, { project: p_project.serialize() })
        .then(() => {
          useRepo(Project).where("id", p_project.id).update(p_project);
        });
    } else {
      // Create new project
      this.createProject(p_project);
    }
  }

  static async createProject(p_project: Project): Promise<Project> {
    return await axios
      .post("projects", { project: p_project.serialize() })
      .then((p_response: AxiosResponse) => {
        const project = p_response.data.project;
        const projectRepo = useRepo(Project);
        projectRepo.save(project);

        return projectRepo.find(project.id) as Project;
      });
  }

  // create a demo project and return the reference
  static async createDemoProject(): Promise<string> {
    return await axios
      .get("projects/demo")
      .then((p_response: AxiosResponse) => {
        const projectRef = p_response.data.projectRef;
        return projectRef;
      });
  }

  static getOrderStateToDisplay(
    privilege: Privilege,
    state: OrderState
  ): string {
    if (privilege === Privilege.FREEMIUM) {
      return FreemiumOrderStateString[state];
    }

    if (privilege === Privilege.PREMIUM) {
      return PremiumOrderStateString[state];
    }

    if (privilege === Privilege.IN_OFFICE) {
      return InOfficeOrderStateString[state];
    }

    return OrderStateString[state];
  }

  static getOrderChipColor(
    privilege: Privilege,
    state: OrderState,
    selected: boolean
  ): string {
    let ret = "";
    // get color from privilege and state
    if (privilege === Privilege.FREEMIUM) {
      ret = FreemiumOrderStateColor[state];
    } else if (privilege === Privilege.PREMIUM) {
      ret = PremiumOrderStateColor[state];
    } else if (privilege === Privilege.IN_OFFICE) {
      ret = InOfficeOrderStateColor[state];
    } else {
      ret = OrderStateColor[state];
    }

    // if row is selected, change color to lighter
    if (selected && (ret === BLUE_DARK || ret === BLUE_MEDIUM))
      ret = BLUE_LIGHT;

    return ret;
  }

  static getOrderChipTextColor(
    privilege: Privilege,
    state: OrderState,
    selected: boolean
  ): string {
    const ret = this.getOrderChipColor(privilege, state, selected);
    switch (ret) {
      case BLUE_DARK:
        return BLUE_DARK_TEXT;
      case BLUE_MEDIUM:
        return BLUE_MEDIUM_TEXT;
      case BLUE_LIGHT:
        return BLUE_LIGHT_TEXT;
      case ORANGE_DARK:
        return ORANGE_DARK_TEXT;
      case RED_LIGHT:
        return RED_LIGHT_TEXT;
      case GREEN:
        return GREEN_TEXT;
      case GREEN_LIGHT:
        return GREEN_LIGHT_TEXT;
      case GREY:
      default:
        return GREY_TEXT;
    }
  }

  static async duplicateProject(p_project: Project): Promise<Project> {
    return await axios
      .post("projects/duplicate/" + p_project.id)
      .then((p_response: AxiosResponse) => {
        const project = p_response.data.project;
        const projectRepo = useRepo(Project);
        projectRepo.save(project);

        const archs = p_response.data.archs;
        const archRepo = useRepo(Arch);
        archRepo.save(archs);

        const wires = p_response.data.wires;
        const wireRepo = useRepo(Wire);
        wireRepo.save(wires);

        const orders = p_response.data.orders;
        const orderRepo = useRepo(Order);
        orderRepo.save(orders);

        const billings = p_response.data.billings;
        const billingRepo = useRepo(Billing);
        billingRepo.save(billings);

        return project;
      });
  }

  static async getAssemblyTimeEstimation(
    teethRange: string,
    nbPoints: number,
    algoOptions: string
  ): Promise<number> {
    return await axios
      .get("projects/bendTimeEstimation", {
        params: {
          teethRange: teethRange,
          nbPoints: nbPoints,
          algoOptions: algoOptions,
        },
        headers: { disableLoader: true },
      })
      .then((p_response: AxiosResponse) => {
        let estimation = p_response.data.estimation;

        // parse estimation to int
        try {
          estimation = parseInt(estimation);
          if (estimation < 0 || isNaN(estimation)) {
            estimation = 25000;
          }
        } catch (e) {
          estimation = 25000;
        }
        return estimation;
      });
  }
}
