<template>
  <!-- Comments. -->
  <td>
    <w-menu
      v-if="p_order"
      right
      min-width="340px"
      max-width="340px"
      max-height="600px"
      open-on-hover
      :close-on-content-click="false"
      v-model="m_menu"
    >
      <template v-slot:activator="{ props }">
        <CommentBadgeIcon
          :p_order="p_order"
          v-bind="props"
          :p_privilege="p_privilege"
        />
      </template>
      <div style="background-color: white">
        <WireSummary
          :p_privilege="p_privilege"
          :p_order="p_order"
          :p_project="p_order.project"
          :p_isEditing="false"
          :p_fixedHeight="true"
          @order_updated="$emit('refresh')"
          @open-project="$emit('load')"
          @requestUpdateFromPinia="$emit('requestUpdateFromPinia')"
        />
      </div>
    </w-menu>
  </td>
</template>

<script lang="ts">
import Order from "@/models/Order";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CommentsDialog",
  props: {
    p_privilege: {
      type: Number as () => Privilege,
      required: true,
    },
    p_order: {
      type: Object as () => Order,
      required: true,
    },
  },
  setup(props, context) {
    const m_menu = ref(false);
    return { m_menu };
  },
});
</script>
